import React, { useEffect, useMemo, useState } from 'react'
import {
  TbDotsVertical,
  TbPencil,
  TbPlus,
  TbSearch,
  TbTrash,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { orderBy } from 'firebase/firestore'

import { BoxShadow, Breadcrumb, DialogConfirm, Status } from '@/components'
import { NoContent } from '@/components/no-content'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

const BOX_HEIGHT = 492

enum ENUM_ORDER_BY {
  NAME = 'Name',
  EMAIL = 'E-mail',
}
type ORDER_BY_TYPE = keyof typeof ENUM_ORDER_BY

export const Supplier = () => {
  const navigate = useNavigate()
  const [{ company }, dispatch] = useGlobalState()

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [supplierSelected, setSupplierSelected] = useState<Supplier | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)

  const [supplierList, setSupplierList] = useState<Supplier[] | undefined>(undefined)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [filterBy, setFilterBy] = useState<ORDER_BY_TYPE>('NAME')
  const breadcrumbList: Breadcrumb[] = [
    {
      id: RoutesNames.HOME,
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Suppliers',
      title: 'Suppliers',
      isCurrentPage: true,
    },
  ]
  const [searchSupplier, setSearchSupplier] = useState<string>('')

  const filterSupplierList = useMemo(
    () =>
      supplierList?.filter(supplier => {
        if (filterBy === 'NAME') {
          return supplier.name.toLowerCase().includes(searchSupplier.toLowerCase())
        }
        return supplier.email
          .toLowerCase()
          .includes(searchSupplier.toLowerCase())
      }),
    [supplierList, searchSupplier],
  )

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    serice: Supplier,
  ) => {
    setAnchorEl(event.currentTarget)
    setSupplierSelected(serice)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleDeleteSupplier = async () => {
    if (!supplierSelected) return
    setLoadingDelete(true)
    await ApiService.Supplier.remove({ companyId, supplierId: supplierSelected.id })
      .then(async () => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Part deleted' },
        })
      })
      .catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Part was not deleted', alertSeverity: 'error' },
        })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  const renderTableBody = () => {
    if (loadingList) {
      return (
        <Stack
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          position='absolute'
          height='360px'
        >
          <CircularProgress color='primary' />
        </Stack>
      )
    }

    if (filterSupplierList?.length === 0) {
      return (
        <Stack
          display='flex'
          alignItems='center'
          width='100%'
          position='absolute'
          height='360px'
        >
          <NoContent />
        </Stack>
      )
    }

    return (
      <TableBody>
        {filterSupplierList?.map(supplier => (
          <TableRow hover key={supplier.id}>
            <TableCell>
              <Typography variant='body2'>{supplier.name}</Typography>
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography variant='body2'>{supplier.email}</Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography variant='body2'>{supplier.phoneNumber}</Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Status
                  title={supplier.isActive ? 'Active' : 'Inactive'}
                  type={supplier.isActive ? 'SUCCESS' : 'ERROR'}
                />
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => {
                      navigate(RoutesNames.SUPPLIER_CRUD, {
                        state: { supplierSelected: JSON.stringify(supplier) },
                      })
                    }}
                  >
                    <TbPencil color={grey[900]} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Options'>
                  <IconButton
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={event => {
                      handleMenuAction(event, supplier)
                    }}
                  >
                    <TbDotsVertical color={grey[900]} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  const handleChangeOrderBy = (event: SelectChangeEvent) => {
    setFilterBy(event.target.value as ORDER_BY_TYPE)
    setSearchSupplier('')
  }

  useEffect(() => {
    ApiService.Supplier.getList({
      companyId,
      setSupplierList,
      setLoadingList,
      orderByList: [orderBy('createdAt', 'asc')],
    })
  }, [ApiService.Service.getList])

  return (
    <Box>
      <Breadcrumb pageTitle='List of supplier' menu={breadcrumbList}>
        <Stack display='flex' alignItems='center'>
          <Button
            startIcon={<TbPlus />}
            color='btnSecondary'
            variant='contained'
            size='small'
            onClick={() => {
              navigate(RoutesNames.SUPPLIER_CRUD)
            }}
            disableElevation
          >
            New
          </Button>
        </Stack>
      </Breadcrumb>

      <BoxShadow>
        <TableContainer sx={{ height: BOX_HEIGHT }}>
          <Stack
            display='flex'
            flex={1}
            flexDirection='row'
            paddingY={2}
            pr={1}
            pl={2}
          >
            <Stack display='flex' flexDirection='row' flex={1}>
              <FormControl size='small'>
                <InputLabel id='filterBy'>Filter by</InputLabel>
                <Select
                  labelId='filterBy'
                  value={filterBy}
                  label='Filter by'
                  onChange={handleChangeOrderBy}
                  sx={{ width: 140, mr: 1 }}
                >
                  {Object.entries(ENUM_ORDER_BY).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                size='small'
                label='Search'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <TbSearch color={grey[900]} />
                    </InputAdornment>
                  ),
                }}
                value={searchSupplier}
                onChange={event => {
                  setSearchSupplier(event.target.value)
                }}
                sx={{ width: 240 }}
              ></TextField>
            </Stack>
          </Stack>
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Typography variant='subtitle2'>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>E-mail</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Phone number</Typography>
                </TableCell>
                <TableCell width={100}>
                  <Typography variant='subtitle2'>Status</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
        </TableContainer>
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.SUPPLIER_CRUD, {
              state: { supplierSelected: JSON.stringify(supplierSelected) },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${supplierSelected?.name}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteSupplier()
          },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />
    </Box>
  )
}
