import * as React from 'react';

import { Typography, alpha } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { grey } from '@mui/material/colors';

function stringToColor(string: string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: alpha(stringToColor(`${name} WYZ123`), 0.3),
            color: grey[900]
        },
        children: <Typography variant='subtitle1' sx={{ fontWeight: '400' }}>{name}</Typography>,
    };
}

interface AvatarLetterProps {
    name: string
}
export const AvatarLetter = ({ name }: AvatarLetterProps) => (
    <Avatar {...stringAvatar(name)} />
)