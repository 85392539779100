/* eslint-disable @typescript-eslint/no-unused-vars */
// /* eslint-disable @typescript-eslint/no-empty-interface */
// /* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react'

import moment from 'moment'

import { EnumOrderType } from '@/constants/order'
import { useGlobalState } from '@/context'
import { discontPercent, formatNumberToLibra, truncateByDecimalPlace } from '@/utils/currency'
import { Avatar, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Box } from '@mui/system'

export interface PrintOrderProps {
  client: Client
  service: Service
  order: Order
}

export const PrintOrder = React.forwardRef<HTMLDivElement, PrintOrderProps>(
  (props, ref) => {
    const [{ company }] = useGlobalState()
    const { client, service, order } = props
    const partsSold = useMemo<StockWithCheck[]>(() => JSON.parse(service?.partsSold || '[]'), [service])

    const renderFooter = () => {
      let totalNetAmount: number = 0
      let totalTaxAmount: number = 0

      partsSold.forEach(item => {
        const unitPrice = Number(item.price)
        const howManyQuantity = Number(item.howManyQuantity)
        const discountPrice = Number(order?.discountPrice || 0)
        const taxPercent = Number(company?.taxPercent || 0)

        const netAmount = truncateByDecimalPlace({ value: unitPrice * howManyQuantity })
        const discountPricePercent = discountPrice ? discontPercent({ price: netAmount, percent: discountPrice }) : 0
        const netAmountWithDiscount = truncateByDecimalPlace({ value: netAmount - discountPricePercent })
        const vat = discontPercent({ price: netAmountWithDiscount, percent: taxPercent })
        totalNetAmount = truncateByDecimalPlace({ value: netAmountWithDiscount + totalNetAmount })
        totalTaxAmount = truncateByDecimalPlace({ value: vat + totalTaxAmount })
      });

      const workerHourPrice = Number(company?.workerHourPrice || 0)
      const workHours = Number(order.workHours || 0)
      const discountPriceWorker = Number(order?.discountPrice || 0)
      const taxPercentWorker = Number(company?.taxPercent || 0)
      const netAmountWorker = truncateByDecimalPlace({ value: workerHourPrice * workHours })
      const discountPricePercentWorker = discountPriceWorker ? discontPercent({ price: netAmountWorker, percent: discountPriceWorker }) : 0
      const netAmountWithDiscountWorker = truncateByDecimalPlace({ value: netAmountWorker - discountPricePercentWorker })
      const vatWorker = discontPercent({ price: netAmountWithDiscountWorker, percent: taxPercentWorker })

      totalNetAmount = truncateByDecimalPlace({ value: netAmountWithDiscountWorker + totalNetAmount })
      totalTaxAmount = truncateByDecimalPlace({ value: vatWorker + totalTaxAmount })

      const invoiceTotal = truncateByDecimalPlace({ value: totalNetAmount + totalTaxAmount + Number(order?.carriageNet || 0) })
      return (
        <Stack
          sx={{
            borderTop: `1px solid ${grey[900]}`,
            pt: 3,
            mt: 3,
          }}
        >
          <Stack flexDirection='row' alignItems='flex-start'>
            <Box
              display='flex'
              flexDirection='column'
              flex={1}
              p={2}
              sx={{ border: `1px solid ${grey[900]}`, borderRadius: 1 }}
            >
              <Typography variant='body2' fontWeight={500} display='flex' flexDirection='row' mb={1}>
                Payment Due: <Typography variant='body2' ml={1}>{order?.paymentDue ? moment(order.paymentDue).format('DD/MM/YYYY') : '-- --'} </Typography>
              </Typography>

              <Typography variant='body2' mb={0.5}>
                Payment Details: {company?.name} - {company?.bankName || '-- --'}
              </Typography>
              <Typography variant='body2'  >
                Sort Code: {company?.bankSortCode || '-- --'}, Bank Account: {company?.bankAccount || '-- --'}
              </Typography>
            </Box>
            <Box mx={5} />
            <Box
              display='flex'
              flexDirection='column'
              flex={1}
              sx={{ border: `1px solid ${grey[900]}`, borderRadius: 1, minHeight: 120 }}
            >
              <Box sx={{ borderBottom: `1px solid ${grey[900]}`, display: 'flex' }}>
                <Box display='flex' flex={1} sx={{ height: 30, borderRight: `1px solid #000`, alignItems: 'center', paddingLeft: 2 }}>
                  <Typography variant='body2'>Total Net Amount</Typography>
                </Box>
                <Box display='flex' flex={1} sx={{ height: 30, alignItems: 'center', pl: 2 }}>
                  <Typography variant='body2' fontWeight={500}>{formatNumberToLibra({ value: totalNetAmount, showCurrency: true })}</Typography>
                </Box>
              </Box>

              <Box sx={{ borderBottom: `1px solid ${grey[900]}`, display: 'flex' }}>
                <Box display='flex' flex={1} sx={{ height: 30, borderRight: `1px solid ${grey[900]}`, alignItems: 'center', paddingLeft: 2 }}>
                  <Typography variant='body2'>Carriage Net</Typography>
                </Box>
                <Box display='flex' flex={1} sx={{ height: 30, alignItems: 'center', pl: 2 }}>
                  <Typography variant='body2' fontWeight={500}>{formatNumberToLibra({ value: order?.carriageNet || 0, showCurrency: true })}</Typography>
                </Box>
              </Box>

              <Box sx={{ borderBottom: `1px solid ${grey[900]}`, display: 'flex' }}>
                <Box display='flex' flex={1} sx={{ height: 30, borderRight: `1px solid ${grey[900]}`, alignItems: 'center', paddingLeft: 2 }}>
                  <Typography variant='body2'>Total Tax Amount</Typography>
                </Box>
                <Box display='flex' flex={1} sx={{ height: 30, alignItems: 'center', pl: 2 }}>
                  <Typography variant='body2' fontWeight={500}>{formatNumberToLibra({ value: totalTaxAmount, showCurrency: true })}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex' }}>
                <Box display='flex' flex={1} sx={{ height: 30, borderRight: `1px solid ${grey[900]}`, alignItems: 'center', paddingLeft: 2 }}>
                  <Typography variant='body2'>Invoice Total</Typography>
                </Box>
                <Box display='flex' flex={1} sx={{ height: 30, alignItems: 'center', pl: 2 }}>
                  <Typography variant='body2' fontWeight={500}>{formatNumberToLibra({ value: invoiceTotal, showCurrency: true })}</Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Stack>
      )

    }

    const renderTableRowWorker = () => {
      const workerHourPrice = Number(company?.workerHourPrice || 0)
      const workHours = Number(order.workHours || 0)
      const discountPrice = Number(order.discountPrice)
      const taxPercent = Number(company?.taxPercent || 0)

      const netAmount = truncateByDecimalPlace({ value: workerHourPrice * workHours })
      const discountPricePercent = discountPrice ? discontPercent({ price: netAmount, percent: discountPrice }) : 0
      const netAmountWithDiscount = truncateByDecimalPlace({ value: netAmount - discountPricePercent })
      const vat = discontPercent({ price: netAmountWithDiscount, percent: taxPercent })

      return (
        <TableRow key='worker'>
          <TableCell>
            <Typography variant='caption'>{workHours}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='caption'>Labour rate ph</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='caption'>{formatNumberToLibra({ value: workerHourPrice, showCurrency: true })}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='caption'>{formatNumberToLibra({ value: discountPricePercent, showCurrency: true })}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='caption'>{formatNumberToLibra({ value: netAmountWithDiscount, showCurrency: true })}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='caption'>{taxPercent}%</Typography>
          </TableCell>
          <TableCell>
            <Typography variant='caption'>{formatNumberToLibra({ value: vat, showCurrency: true })}</Typography>
          </TableCell>
        </TableRow>
      )
    }

    return (
      <div ref={ref}>
        <Stack padding={2}>
          <Stack flexDirection='row' alignItems='center'>
            <Avatar
              src={company?.photoURL}
              variant='rounded'
              sx={{
                height: 120,
                width: 120,
              }}
            />
            <Stack display='flex' flex={1} justifyContent='center' pl={2}>
              <Typography variant='h6'>{company?.name}</Typography>
              <Typography variant='body1'>E-mail: {company?.email}</Typography>
              <Typography variant='body1'>
                Phone Number: {company?.phoneNumber}
              </Typography>
              <Typography variant='body1'>
                Address: {company?.address}
              </Typography>
              <Typography variant='body1'>
                Website: {company?.websiteUrl}
              </Typography>
            </Stack>
          </Stack>

          <Stack flexDirection='row' my={3} alignItems='flex-end'>
            <Box
              display='flex'
              flexDirection='column'
              flex={1}
              py={1}
              px={2}
              sx={{ border: `1px solid ${grey[900]}`, borderRadius: 1, minHeight: 124 }}
            >
              <Typography variant='body2'>{client?.fullname}</Typography>
              <Typography variant='caption'>E-mail: {client?.email}</Typography>
              <Typography variant='caption'>
                Phone Number: {client?.phoneNumber}
              </Typography>
              <Typography variant='caption'>
                Address: {client?.address}
              </Typography>
              <Typography variant='caption'>
                Address delivery: {client?.addressDelivery}
              </Typography>
            </Box>
            <Box mx={3} />
            <Box
              display='flex'
              flexDirection='column'
              flex={1}
            >
              <Box width='100%' pl={2.1}>
                <Typography variant='body1' fontWeight={500}>{EnumOrderType[order.type]}</Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                sx={{ border: `1px solid ${grey[900]}`, borderRadius: 1, height: 94 }}
              >
                <Box sx={{ borderBottom: `1px solid ${grey[900]}`, display: 'flex' }}>
                  <Box display='flex' flex={1} sx={{ height: 30, borderRight: `1px solid ${grey[900]}`, alignItems: 'center', paddingLeft: 2 }}>
                    <Typography variant='body2'>{EnumOrderType[order.type]} No</Typography>
                  </Box>
                  <Box display='flex' flex={1} sx={{ height: 30, alignItems: 'center', pl: 2 }}>
                    <Typography variant='body2' fontWeight={500}>
                      {order.type === 'QUOTATION' && order.id.substring(0, 6).toUpperCase()}
                      {order.type === 'SALE' && order.saleNumber}
                      {order.type === 'INVOICE_AND_CREDIT' && order.invoiceNumber}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ borderBottom: `1px solid ${grey[900]}`, display: 'flex' }}>
                  <Box display='flex' flex={1} sx={{ height: 30, borderRight: `1px solid #000`, alignItems: 'center', paddingLeft: 2 }}>
                    <Typography variant='body2'>{EnumOrderType[order.type]} Date</Typography>
                  </Box>
                  <Box display='flex' flex={1} sx={{ height: 30, alignItems: 'center', pl: 2 }}>
                    <Typography variant='body2' fontWeight={500}>
                      {order.type === 'QUOTATION' && moment(order.createdAt).format('DD/MM/YYYY')}
                      {order.type === 'SALE' && moment(order.saleNumberCreatedAt).format('DD/MM/YYYY')}
                      {order.type === 'INVOICE_AND_CREDIT' && moment(order.invoiceNumberCreatedAt).format('DD/MM/YYYY')}
                    </Typography>
                  </Box>
                </Box>

                {order.type === 'INVOICE_AND_CREDIT' && (
                  <Box sx={{ borderBottom: `1px solid ${grey[900]}`, display: 'flex' }}>
                    <Box display='flex' flex={1} sx={{ height: 30, borderRight: `1px solid ${grey[900]}`, alignItems: 'center', paddingLeft: 2 }}>
                      <Typography variant='body2'>{EnumOrderType.SALE} No</Typography>
                    </Box>
                    <Box display='flex' flex={1} sx={{ height: 30, alignItems: 'center', pl: 2 }}>
                      <Typography variant='body2' fontWeight={500}>
                        {order.saleNumber}
                      </Typography>
                    </Box>
                  </Box>
                )}

                <Box sx={{ display: 'flex' }}>
                  <Box display='flex' flex={1} sx={{ height: 30, borderRight: `1px solid ${grey[900]}`, alignItems: 'center', paddingLeft: 2 }}>
                    <Typography variant='body2'>Order No</Typography>
                  </Box>
                  <Box display='flex' flex={1} sx={{ height: 30, alignItems: 'center', pl: 2 }}>
                    <Typography variant='body2' fontWeight={500}>{service.po || '-- --'}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Stack>

          <Box minHeight={540}>
            <Table
              stickyHeader
              size='small'
              sx={{ position: 'relative', border: `1px solid ${grey[100]}`, borderRadius: 1, overflow: 'hidden', }}
            >
              <TableHead>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell width={80}>
                    <Typography variant='body2' fontWeight={500}>Quantity</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2' fontWeight={500}>Description</Typography>
                  </TableCell>
                  <TableCell width={110}>
                    <Typography variant='body2' fontWeight={500}>Unit price</Typography>
                  </TableCell>
                  <TableCell width={100}>
                    <Typography variant='body2' fontWeight={500}>Discont</Typography>
                  </TableCell>
                  <TableCell width={110}>
                    <Typography variant='body2' fontWeight={500}>Net Amt</Typography>
                  </TableCell>
                  <TableCell width={80}>
                    <Typography variant='body2' fontWeight={500}>VAT %</Typography>
                  </TableCell>
                  <TableCell width={80}>
                    <Typography variant='body2' fontWeight={500}>VAT</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {partsSold.map((item, index) => {
                  const unitPrice = Number(item.price)
                  const howManyQuantity = Number(item.howManyQuantity)
                  const discountPrice = Number(order?.discountPrice || 0)
                  const taxPercent = Number(company?.taxPercent || 0)

                  const netAmount = truncateByDecimalPlace({ value: unitPrice * howManyQuantity })
                  const discountPricePercent = discountPrice ? discontPercent({ price: netAmount, percent: discountPrice }) : 0
                  const netAmountWithDiscount = truncateByDecimalPlace({ value: netAmount - discountPricePercent })
                  const vat = discontPercent({ price: netAmountWithDiscount, percent: taxPercent })

                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Typography variant='caption'>{item.howManyQuantity}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='caption'>{item.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='caption'>{formatNumberToLibra({ value: unitPrice, showCurrency: true })}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='caption'>{formatNumberToLibra({ value: discountPricePercent, showCurrency: true })}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='caption'>{formatNumberToLibra({ value: netAmountWithDiscount, showCurrency: true })}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='caption'>{taxPercent}%</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='caption'>{formatNumberToLibra({ value: vat, showCurrency: true })}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
                {renderTableRowWorker()}
              </TableBody>
            </Table>
          </Box>

          {renderFooter()}
        </Stack>
      </div>
    )
  },
)
PrintOrder.displayName = 'PrintPage'
