import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BoxShadow } from '@/components'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import { ApiService } from '@/services'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'


export const Password = () => {
  const [{ user }, dispatch] = useGlobalState()
  const navigate = useNavigate()
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)

  const handleSendEmail = async () => {
    setLoadingUpdate(true)

    await ApiService.Auth.userSendPasswordResetEmail({
      email: user?.email || '',
    })
      .then(() => {
        dispatch({ type: 'SET_SNACKBAR', data: { message: 'E-mail sent' } })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error sending the E-mail', alertSeverity: 'error' }
        })
      }).finally(() => {
        setLoadingUpdate(false)
      })
  }

  return (
    <BoxShadow>
      <Stack padding={3}>
        <Typography variant='subtitle1'>
          By clicking the button below, you will receive an email with instructions to reset your password.
        </Typography>
        <Stack flexDirection='row' justifyContent='flex-end' mt={3} >
          <Button
            color='btnSecondary'
            variant='contained'
            disableElevation
            onClick={() => { navigate(RoutesNames.HOME) }}
          >
            Back
          </Button>
          <Box mr={2} />
          <LoadingButton
            variant='contained'
            color='btnPrimaryFill'
            disableElevation
            onClick={() => { handleSendEmail() }}
            loading={loadingUpdate}
          >
            Send
          </LoadingButton>
        </Stack>
      </Stack>
    </BoxShadow>
  )
}
