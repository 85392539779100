import {
  QueryFieldFilterConstraint,
  QueryOrderByConstraint,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from 'firebase/firestore'

import { FirebaseCollection } from '@/constants/firebase-collection'
import { firestore } from '@/services/firebase'
import { getPathFileCompanyLogomarca } from '@/utils/storage'

import { deleteFile } from '../file'

interface GetListProps {
  setCompanyList: React.Dispatch<React.SetStateAction<Company[] | undefined>>
  setLoadingList: React.Dispatch<React.SetStateAction<boolean>>
  whereList?: QueryFieldFilterConstraint[]
  orderByList?: QueryOrderByConstraint[]
}
export const getList = async ({
  setCompanyList,
  setLoadingList,
  whereList,
  orderByList,
}: GetListProps) => {
  setLoadingList(true)

  const q = query(
    collection(firestore, FirebaseCollection.BASE_COMPANY),
    ...(whereList || []),
    ...(orderByList || []),
  )

  onSnapshot(
    q,
    querySnapshot => {
      const companyList: Company[] = []
      querySnapshot.forEach(doc => {
        const dataItem = { id: doc.id, ...doc.data() } as Company
        companyList.push(dataItem)
      })
      setLoadingList(false)
      setCompanyList(companyList)
    },
    () => {
      console.log('company - getList() - error')
      setLoadingList(false)
    },
  )
}

interface GetOneProps {
  companyId: string
}
export const getOne = async ({
  companyId,
}: GetOneProps): Promise<Company | undefined> => {
  const docRef = doc(firestore, FirebaseCollection.BASE_COMPANY, companyId)
  const docSnap = await getDoc(docRef)

  try {
    if (docSnap.exists()) {
      return {
        id: companyId,
        ...docSnap.data(),
      } as Company
    }
  } catch (error) {
    return undefined
  }

  return undefined
}

export const save = async (values: Partial<Company>) => {
  delete values.id

  return await addDoc(collection(firestore, FirebaseCollection.BASE_COMPANY), {
    ...values,
    createdAt: new Date().getTime(),
    dateCreatedAt: new Date().setHours(0, 0, 0, 0),
    updatedAt: new Date().getTime(),
  }).then(resp => resp.id)
}

interface updateProps {
  companyId: string
  values: Partial<Company>
}
export const update = async ({ companyId, values }: updateProps) => {
  await setDoc(
    doc(firestore, FirebaseCollection.BASE_COMPANY, companyId),
    {
      ...values,
      updatedAt: new Date().getTime(),
    },
    { merge: true },
  )
}

interface RemoveProps {
  companyId: string
}
export const remove = async ({ companyId }: RemoveProps) => {
  await deleteDoc(doc(firestore, FirebaseCollection.BASE_COMPANY, companyId))
}

interface RemovePhotoProps {
  companyId: string
  photoName: string
}
export const removePhoto = async ({
  companyId,
  photoName,
}: RemovePhotoProps) => {
  await deleteFile({
    pathFile: `${getPathFileCompanyLogomarca(companyId)}/${photoName}`,
  })
}
