import * as yup from 'yup'

export const validationSchema = yup.object({
  title: yup.string().required('Field is required'),

  description: yup.string().required('Field is required'),

  status: yup.string().required('Field is required'),

  typeService: yup.string().required('Field is required'),

  clientId: yup.string().required('Field is required'),

  hasImage: yup
    .boolean()
    .required('Choose at least one image')
    .oneOf([true], 'Choose at least one image'),
})
