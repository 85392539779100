import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import * as ContextApi from '@/context'
import reportWebVitals from '@/reportWebVitals'
import { Router } from '@/router'
import GlobalStyle from '@/styles/global'
import { theme } from '@/styles/mui-style'
import { ThemeProvider } from '@mui/material/styles'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ContextApi.GlobalStateProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ContextApi.GlobalStateProvider>
    </ThemeProvider>
  </React.StrictMode>,
)

reportWebVitals()
