import React, { useEffect, useMemo, useState } from 'react'
import { TbChevronRight, TbDotsVertical, TbPencil, TbPlus, TbTrash, TbX } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import { useGlobalState } from '@/context';
import { RoutesNames } from '@/router/routes';
import * as ApiService from '@/services/api'
import { theme } from '@/styles/mui-style';
import {
    Avatar,
    Box,
    CircularProgress,
    Drawer,
    FormHelperText,
    IconButton,
    ListItemButton,
    ListItemIcon,
    MenuItem,
    Stack,
    Menu,
    TextField,
    Typography,
    useMediaQuery,
    Button,
} from '@mui/material'
import { grey, red } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import { AvatarLetter } from '../avatar-letter';

interface DrawerChooseClientProps {
    errorMessage?: string
    clientChoosed?: Client
    clientIdToSelect?: string | undefined
    isDisabled?: boolean
    setClientChoosed: (client: Client | undefined) => void
}

export const DrawerChooseClient = ({ clientChoosed, clientIdToSelect, errorMessage, isDisabled, setClientChoosed }: DrawerChooseClientProps) => {
    const navigate = useNavigate()
    const [{ company }] = useGlobalState()
    const [clientList, setClientList] = useState<Client[] | undefined>(undefined)
    const [loadingList, setLoadingList] = useState<boolean>(false)
    const [searchClient, setSearchClient] = useState<string>('')
    const [showDrawerChooseClient, setShowDrawerChooseClient] = useState<boolean>(false)
    const filterClientList = useMemo(() => clientList?.filter((client) => client.fullname.toLowerCase().includes(searchClient.toLowerCase())), [clientList, searchClient])
    const companyId = useMemo(() => company?.id || '', [])

    const isXs = useMediaQuery(theme.breakpoints.only("xs"));

    const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
    const open = Boolean(anchorEl)

    const handleCloseMenuAction = () => {
        setAnchorEl(undefined)
    }

    const handleMenuAction = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget)
    }

    useEffect(() => {
        if (clientList === undefined) {
            ApiService.Client.getList({
                companyId,
                setClientList,
                setLoadingList
            })
        } else {
            const client = clientList.find((client) => client.id === clientIdToSelect)
            setClientChoosed(client)
        }
    }, [clientList, ApiService.Client.getList])

    return (
        <Box>
            <Stack display='flex' flexDirection='row'
                sx={{
                    height: 54,
                    width: '100%',
                    borderRadius: 1,
                    border: `1px solid ${errorMessage ? red[700] : grey[200]}`,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: 1.5,

                }}>
                {loadingList && (
                    <CircularProgress size={24} />
                )}
                {!loadingList && (

                    <Stack display='flex' flex={1} alignItems='center' flexDirection='row' flexWrap='nowrap'>
                        {
                            clientChoosed
                                ? (
                                    <>
                                        {clientChoosed?.photoURL
                                            ? <Avatar sx={{ height: 40, width: 40 }} src={clientChoosed.photoURL} />
                                            : <AvatarLetter name={(clientChoosed?.fullname || '').substring(0, 1).toUpperCase()} />}

                                        <Typography
                                            variant='body2'
                                            textOverflow='ellipsis'
                                            noWrap
                                            width={150}
                                            flex={1}
                                            // display='flex'
                                            // overflow='hidden'
                                            // minWidth={0}
                                            // whiteSpace='nowrap'
                                            // flexShrink={0}
                                            ml={1}
                                        >
                                            {clientChoosed.fullname}
                                        </Typography>
                                    </>
                                )
                                :
                                (
                                    <Typography variant='body2' flex={1}
                                        color={errorMessage ? red[700] : 'inherit'}
                                    >
                                        Select a client
                                    </Typography>
                                )
                        }
                        {clientChoosed ? (
                            <IconButton sx={{ height: 40, width: 40 }} onClick={handleMenuAction}>
                                <TbDotsVertical color={grey[600]} />
                            </IconButton>
                        )
                            :
                            (
                                <IconButton sx={{ height: 40, width: 40 }} onClick={() => {
                                    setShowDrawerChooseClient(true)
                                }}>
                                    <TbChevronRight color={grey[600]} />
                                </IconButton>
                            )
                        }
                    </Stack>
                )}
            </Stack>


            {errorMessage && (
                <FormHelperText sx={{ pl: 1.8, color: red[700] }}>{errorMessage}</FormHelperText>
            )}

            <Drawer
                open={showDrawerChooseClient}
                anchor='right'
                onClose={() => {
                    setShowDrawerChooseClient(false)
                    setSearchClient('')
                }}
                PaperProps={{
                    sx: { width: isXs ? '80%' : 600 }
                }}
            >
                <Stack display='flex' flex={1} padding={2}>
                    <Stack position='absolute' right='12px' top='12px'>
                        <IconButton onClick={() => {
                            setShowDrawerChooseClient(false)
                            setSearchClient('')
                        }}>
                            <TbX color={grey[900]} />
                        </IconButton>
                    </Stack>
                    <Stack paddingBottom={2} borderBottom={`1px dashed ${grey[300]}`} marginBottom={3}>
                        <Typography variant='h6'>
                            Select a client
                        </Typography>
                    </Stack>

                    <Stack display='flex' flexDirection='row'>
                        <TextField label="Search client" variant="outlined"
                            fullWidth
                            onChange={(event) => { setSearchClient(event.target.value); }}
                        />
                        <Button
                            startIcon={<TbPlus />}
                            color='btnSecondary'
                            variant='contained'
                            size='small'
                            onClick={() => {
                                navigate(RoutesNames.CLIENT_CRUD)
                            }}
                            disableElevation
                            sx={{ width: 160, ml: 2 }}
                        >
                            New client
                        </Button>
                    </Stack>

                    <List sx={{ width: '100%', paddingLeft: 1 }}>
                        {filterClientList?.map((client, index) => (
                            <ListItemButton
                                disableGutters
                                key={client.id}
                                selected={clientChoosed ? clientChoosed.id === client.id : undefined}
                                sx={{ borderRadius: 2 }}
                                onClick={() => {
                                    setClientChoosed(client)
                                    setShowDrawerChooseClient(false)
                                }}
                            // secondaryAction={
                            //   <IconButton onClick={() => {
                            //     setClientChoosed(client)
                            //     setShowDrawerChooseClient(false)
                            //   }}>
                            //     <TbChevronRight color={grey[900]} />
                            //   </IconButton>
                            // }
                            >
                                <ListItemAvatar>
                                    {client?.photoURL
                                        ? <Avatar src={client.photoURL} />
                                        : <AvatarLetter name={(client.fullname || '').substring(0, 1).toUpperCase()} />
                                    }
                                </ListItemAvatar>
                                <ListItemText primary={client.fullname} secondary={client.phoneNumber} />
                            </ListItemButton>
                        ))}
                    </List>
                </Stack>
            </Drawer>

            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenuAction}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    disabled={isDisabled}
                    onClick={() => {
                        handleCloseMenuAction()
                        setShowDrawerChooseClient(true)
                    }}>
                    <ListItemIcon>
                        <TbPencil size={20} color={grey[900]} />
                    </ListItemIcon>
                    <Typography variant='body2'>Edit</Typography>
                </MenuItem>
                <MenuItem
                    disabled={isDisabled}
                    onClick={() => {
                        handleCloseMenuAction()
                        setClientChoosed(undefined)
                    }}>
                    <ListItemIcon>
                        <TbTrash size={20} color={red[500]} />
                    </ListItemIcon>
                    <Typography variant='body2' color={red[500]}>
                        Remove client
                    </Typography>
                </MenuItem>
            </Menu>

        </Box >
    )
}
