import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  deleteUser,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updateProfile,
} from 'firebase/auth'

import { auth } from '@/services/firebase'
import { formaterUserDataToApp } from '@/utils/firebase'

interface SingIpProps {
  email: string
  password: string
}
export const singIn = async ({
  email,
  password,
}: SingIpProps): Promise<User | undefined> =>
  await signInWithEmailAndPassword(auth, email, password).then(userCredential =>
    formaterUserDataToApp(userCredential.user),
  )

interface SingUpProps {
  email: string
  password: string
}
export const singUp = async ({
  email,
  password,
}: SingUpProps): Promise<User | undefined> =>
  await createUserWithEmailAndPassword(auth, email, password).then(
    userCredential => formaterUserDataToApp(userCredential.user),
  )

export const userSignOut = async () => {
  await signOut(auth)
}

interface UpdateUserProfileProps {
  displayName?: string | null
  photoURL?: string | null
}
export const updateUserProfile = async ({
  displayName,
  photoURL,
}: UpdateUserProfileProps) => {
  if (!auth.currentUser) {
    return await Promise.reject(new Error('No user logged'))
  }
  await updateProfile(auth.currentUser, { displayName, photoURL })
}

interface UpdateUserEmailProps {
  email: string
}
export const updateUserEmail = async ({
  email,
}: UpdateUserEmailProps): Promise<void> => {
  if (!auth.currentUser) {
    await Promise.reject(new Error('No user logged'))
    return
  }
  await updateEmail(auth.currentUser, email)
}

interface UserSendEmailVerificationProps {
  languageCode?: string
}
export const userSendEmailVerification = async ({
  languageCode = 'en', // https://github.com/firebase/firebaseui-web/blob/master/LANGUAGES.md
}: UserSendEmailVerificationProps) => {
  if (!auth.currentUser) {
    return await Promise.reject(new Error('No user logged'))
  }
  auth.languageCode = languageCode
  await sendEmailVerification(auth.currentUser)
}

interface UserSendPasswordResetEmailProps {
  email: string
  languageCode?: string
}
export const userSendPasswordResetEmail = async ({
  email,
  languageCode = 'en',
}: UserSendPasswordResetEmailProps) => {
  auth.languageCode = languageCode
  await sendPasswordResetEmail(auth, email)
}

export const userDeleteLogged = async () => {
  if (!auth.currentUser) {
    return await Promise.reject(new Error('No user logged'))
  }
  await deleteUser(auth.currentUser)
}

interface UserReauthenticateProps {
  password: string
}
export const userReauthenticate = async ({
  password,
}: UserReauthenticateProps) => {
  if (!auth.currentUser) {
    return await Promise.reject(new Error('No user logged'))
  }
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email || '',
    password,
  )
  await reauthenticateWithCredential(auth.currentUser, credential)
}

//   if (!auth.currentUser) {
//     if (!auth.currentUser) {
//       await Promise.resolve(null)
//       return
//     }
//   }
//   await deleteUser(auth.currentUser)

// #CADASTRO
// await ApiService.Auth.singUp({
//     email: values.email,
//     password: values.password
//   }).then((resp) => {
//     console.log('resp: ', resp)
//   })

// #LOGIN
// await ApiService.Auth.singIn({
//     email: values.email,
//     password: values.password
//   }).then((resp) => {
//     console.log('resp: ', resp)
//   }).catch(() => {
//     console.log('senha e email errado')
//   })

// #UPDATE PROFILE
// await ApiService.Auth.updateUserProfile({
//     displayName: 'Adam lages',
//     photoURL: 'www...'
//   })

// #ALTERAR EMAIL
// ----- NAO ESTA FUNCIONANDO! TALVEL PRECISE HABILITAR A ALTERACAO DE EMAIL NO CONSOLE
// await ApiService.Auth.updateUserEmail({
//     email: 'adam_lages@hotmail.com',
//   }).then((resp) => {
//     console.log('resp: ', resp)
//   })

// # ENVIAR EMAIL PARA O USUARIO QUE ESTA LOGADO NA PLATAFORMA VALIDAR O SEU EMAIL
// / await ApiService.Auth.userSendEmailVerification()
//       //   .then(() => {
//       //     console.log('----> enviou');
//       //   }).catch(() => {
//       //     console.log('----> error');
//       //   })

// # ENVIA EMAIL PARA O USUARIO LOGADO REDEFINIR A SENHA
// await ApiService.Auth.userSendPasswordResetEmail({
//   email: values.email,
// })
//   .then(() => {
//     console.log('----> enviou');
//   }).catch(() => {
//     console.log('----> error');
//   })

// # APAGAR USUARIO LOGADO
// await ApiService.Auth.userDeleteLogged()
// .then(() => {
//   console.log('----> apagou');
// }).catch(() => {
//   console.log('----> error');
// })

// # DESLOGAR USUARIO
// await ApiService.Auth.userSignOut().then(() => {
//   console.log('----> DESLOGOU:  ');
// }).catch(() => {
//   console.log('----> NAO DELEGOU: ',);
// })
