
import React, { useState } from 'react'
import { TbArrowsMaximize, TbPhotoCog, TbPhotoMinus, TbPhotoPlus, TbPhotoX } from "react-icons/tb"

import { Avatar, Box, CircularProgress, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { grey, red } from '@mui/material/colors'

interface FileUploadMenuItem {
    action: string
    title: string
    icon: React.ReactNode
    onClick: (action: string) => void
}

interface FileUploadProps extends Partial<React.PropsWithChildren> {
    fileToUpload?: File
    fileUrl?: string
    inputFileRef: React.RefObject<HTMLInputElement>
    isMultiple?: boolean
    loading?: boolean
    showTextAllowFile?: boolean
    hideMenu?: boolean
    hideMenuItemDelete?: boolean
    showMenuItemShowImage?: boolean
    isDisableUpload?: boolean
    avatarSize?: number
    addMenuList?: FileUploadMenuItem[]
    setFileToUpload: (file: FileList | undefined) => void
    handleChoosedImage?: () => void
    handleDeletedImage?: () => void
}
export const PhotoUpload = ({
    fileToUpload,
    fileUrl,
    inputFileRef,
    isMultiple,
    loading,
    showTextAllowFile,
    hideMenu,
    hideMenuItemDelete,
    showMenuItemShowImage,
    isDisableUpload,
    avatarSize = 130,
    addMenuList,
    setFileToUpload,
    handleChoosedImage,
    handleDeletedImage,
    children
}: FileUploadProps) => {
    const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
    const open = Boolean(anchorEl)

    const handleCloseMenuImage = () => {
        setAnchorEl(undefined)
    }

    const handleChooseImage = () => {
        inputFileRef.current?.click()
        handleCloseMenuImage()
        if (handleChoosedImage) handleChoosedImage()
    }

    const handleDeleteImage = async () => {
        handleCloseMenuImage()
        if (handleDeletedImage) handleDeletedImage()
    }


    const RenderButtonShowMenu = () => (
        <Stack position='absolute' display={hideMenu ? 'none' : 'block'} right={loading ? -18 : -26}>
            {!loading && (
                <IconButton
                    onClick={(event) => { setAnchorEl(event.currentTarget) }}
                >
                    <TbPhotoCog color={grey[900]} />
                </IconButton>
            )}
            {loading && <CircularProgress disableShrink size={24} />}
        </Stack>
    )

    const RenderImage = () => {
        if (isDisableUpload) {
            return (
                <Stack
                    alignItems='center'
                    justifyContent='center'
                >
                    <TbPhotoX size={32} />
                    <Box mb={1} />
                    <Typography
                        variant='caption'
                        color='text.disabled'
                        textAlign='center'
                    >
                        Image disabled
                    </Typography>
                </Stack>
            )
        }


        if (fileToUpload) {
            return (
                <Stack position='relative' justifyContent='center'>
                    <Avatar
                        sx={{ height: avatarSize, width: avatarSize }}
                        src={URL.createObjectURL(fileToUpload)}
                        alt="me"
                    />
                    {RenderButtonShowMenu()}
                </Stack>
            )
        }

        if (fileUrl) {
            return (
                <Stack position='relative' justifyContent='center'>
                    <Avatar
                        sx={{ height: avatarSize, width: avatarSize }}
                        src={fileUrl}
                        alt="me"
                    />
                    {RenderButtonShowMenu()}
                </Stack>
            )
        }

        return (
            <Stack
                alignItems='center'
                justifyContent='center'
                sx={{
                    backgroundColor: grey[100],
                    borderRadius: '100%',
                    height: avatarSize,
                    width: avatarSize,
                    '&:hover': { cursor: 'pointer' },
                }}
                onClick={() => inputFileRef.current?.click()}
            >
                {children || (
                    <>
                        <TbPhotoPlus size={32} />
                        <Box mb={1} />
                        <Typography
                            variant='caption'
                            color='text.disabled'
                            textAlign='center'
                        >
                            Choose
                        </Typography>
                    </>
                )}



            </Stack>
        )
    }

    return (
        <Stack
            display='flex'
            alignItems='center'
        >
            <Stack
                position='relative'
                alignItems='center'
                justifyContent='center'
                sx={{
                    border: `1px dashed ${grey[200]}`,
                    borderRadius: '100%',
                    height: avatarSize + 14,
                    width: avatarSize + 14,
                    '&:hover': {
                        ...(fileToUpload || fileUrl || isDisableUpload
                            ? {}
                            : { borderColor: grey[500], cursor: 'pointer' }),
                    },
                }}
            >
                {RenderImage()}
            </Stack>
            {showTextAllowFile && (
                <>
                    <Box mb={4} />
                    <Typography
                        variant='caption'
                        color='text.disabled'
                        textAlign='center'
                    >
                        Allowed *.jpeg, *.jpg e *.png
                    </Typography>
                    <Typography
                        variant='caption'
                        color='text.disabled'
                        textAlign='center'
                    >
                        max size of 3Mb
                    </Typography>
                </>
            )}

            <input
                ref={inputFileRef}
                id='upload-photo'
                type='file'
                accept='image/png, image/jpg, image/jpeg'
                hidden
                multiple={isMultiple}
                onChange={(event) => {
                    if (!event.target.files) return
                    setFileToUpload(event.target.files)
                    event.target.value = ''
                }}
            />

            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenuImage}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleChooseImage}>
                    <ListItemIcon>
                        <TbPhotoPlus size={20} color={grey[900]} />
                    </ListItemIcon>
                    <Typography variant='body2'>Choose</Typography>
                </MenuItem>
                {addMenuList?.map((item) => (
                    <MenuItem onClick={() => { item.onClick(item.action); }} key={item.action}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <Typography variant='body2'>{item.title}</Typography>
                    </MenuItem>
                ))}

                {!hideMenuItemDelete && (
                    <MenuItem onClick={() => { handleDeleteImage() }}>
                        <ListItemIcon>
                            <TbPhotoMinus size={20} color={red[500]} />
                        </ListItemIcon>
                        <Typography variant='body2' color={red[500]}>
                            Delete
                        </Typography>
                    </MenuItem>
                )}
                {showMenuItemShowImage && (
                    <MenuItem onClick={() => { handleDeleteImage() }}>
                        <ListItemIcon>
                            <TbArrowsMaximize size={20} color={grey[900]} />
                        </ListItemIcon>
                        <Typography variant='body2'>
                            Show image
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        </Stack>
    )
}