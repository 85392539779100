import React from "react";

import { Player } from "@lottiefiles/react-lottie-player";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import LottieNoContent from "../../assets/lottie/no-content.json";

interface NoContentProps {
  title?: string;
  subtitle?: string;
  size?: number;
}
export const NoContent = ({
  title = "No data",
  subtitle = "All saved records will be listed here",
  size = 250,
}: NoContentProps) => (
  <Stack>
    <Player
      autoplay
      src={LottieNoContent}
      keepLastFrame
      speed={0.8}
      style={{
        height: `${size}px`,
        width: "100%",
        padding: 0
      }}
    />

    <Typography textAlign="center" typography='subtitle2' sx={{ marginBottom: '4px' }}>
      {title}
    </Typography>

    {subtitle && (
      <Typography color={grey[500]} textAlign="center" variant="caption">
        {subtitle}
      </Typography>
    )}
  </Stack>
);
