import {
  QueryFieldFilterConstraint,
  QueryOrderByConstraint,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  writeBatch,
} from 'firebase/firestore'

import { FirebaseCollection } from '@/constants/firebase-collection'
import { firestore } from '@/services/firebase'
import { getPathFileCompanyStock } from '@/utils/storage'

import { deleteFile } from '../file'

interface GetListProps {
  companyId: string
  setStockList: (stockList: Stock[] | undefined) => void
  setLoadingList: React.Dispatch<React.SetStateAction<boolean>>
  whereList?: QueryFieldFilterConstraint[]
  orderByList?: QueryOrderByConstraint[]
}
export const getList = async ({
  companyId,
  setStockList,
  setLoadingList,
  whereList,
  orderByList,
}: GetListProps) => {
  setLoadingList(true)
  const q = query(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_STOCK,
    ),
    ...(whereList || []),
    ...(orderByList || []),
  )

  onSnapshot(
    q,
    querySnapshot => {
      const stockList: Stock[] = []
      querySnapshot.forEach(doc => {
        const dataItem = { id: doc.id, ...doc.data() } as Stock
        stockList.push(dataItem)
      })
      setLoadingList(false)
      setStockList(stockList)
    },
    () => {
      console.log('stock - getList() - error')
      setLoadingList(false)
    },
  )
}

interface GetOneProps {
  companyId: string
  stockId: string
}
export const getOne = async ({
  companyId,
  stockId,
}: GetOneProps): Promise<Stock | undefined> => {
  const docRef = doc(
    firestore,
    FirebaseCollection.BASE_COMPANY,
    companyId,
    FirebaseCollection.BASE_STOCK,
    stockId,
  )
  const docSnap = await getDoc(docRef)

  try {
    if (docSnap.exists()) {
      return {
        id: stockId,
        ...docSnap.data(),
      } as Stock
    }
  } catch (error) {
    return undefined
  }

  return undefined
}

interface SaveProps {
  companyId: string
  values: Partial<Stock>
}
export const save = async ({ companyId, values }: SaveProps) =>
  await addDoc(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_STOCK,
    ),
    {
      ...values,
      createdAt: new Date().getTime(),
      dateCreatedAt: new Date().setHours(0, 0, 0, 0),
      updatedAt: new Date().getTime(),
    },
  ).then(resp => resp.id)

interface UpdateProps {
  companyId: string
  stockId: string
  values: Partial<Stock>
}
export const update = async ({ companyId, stockId, values }: UpdateProps) => {
  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_STOCK,
      stockId,
    ),
    { ...values, updatedAt: new Date().getTime() },
    { merge: true },
  )
}

interface RemoveProps {
  companyId: string
  stockId: string
}
export const remove = async ({ companyId, stockId }: RemoveProps) => {
  await deleteDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_STOCK,
      stockId,
    ),
  )
}

interface RemovePhotoProps {
  companyId: string
  stockId: string
  photoName: string
}
export const removePhoto = async ({
  companyId,
  stockId,
  photoName,
}: RemovePhotoProps) => {
  await deleteFile({
    pathFile: `${getPathFileCompanyStock(companyId, stockId)}/${photoName}`,
  })
}

interface UpdateInBatchProps {
  companyId: string
  listStock: StockWithCheck[]
}
export const updateInBatch = async ({
  companyId,
  listStock,
}: UpdateInBatchProps) => {
  const batch = writeBatch(firestore)

  listStock.forEach(item => {
    const docRef = doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_STOCK,
      item.id,
    )
    batch.update(docRef, {
      quantity: item.quantity - item.howManyQuantity,
    })
  })

  await batch.commit()
}
