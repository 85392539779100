import * as Pages from '@/pages'

export const RoutesNames = {
  LOGIN: '/login',
  NO_ACCESS: '/no-access',
  // NOT_FOUND: '/not-found',

  HOME: '/',
  PROFILE: '/profile',

  USER: '/user',
  USER_CRUD: '/user/crud',

  STOCK: '/stock',
  STOCK_LIST: '/stock/list',
  STOCK_CRUD: '/stock/crud',
  STOCK_REQUEST: '/stock/request',
  STOCK_REQUEST_CRUD: '/stock/request/crud',

  CLIENT: '/client',
  CLIENT_CRUD: '/client/crud',

  COMPANY: '/company',
  COMPANY_CRUD: '/company/crud',

  EMPLOYEE: '/employee',

  REPORT: '/report',
  REPORT_CLIENT: '/report/client',
  REPORT_SERVICE: '/report/service',

  MOBILE_BUCKET: '/mobile/bucket',
  MOBILE_HYDRAULIC: '/mobile/hydraulic',

  SERVICE: '/service',
  SERVICE_CRUD: '/service/crud',
  SERVICE_LIST: '/service/list',
  SERVICE_BUCKET: '/service/bucket',
  SERVICE_HYDRAULIC: '/service/hydraulic',

  ORDER: '/order',
  ORDER_QUOTATION: '/order/quotation',
  ORDER_SALE: '/order/sale',
  ORDER_INVOICE_CREDIT: '/order/invoice-credit',
  ORDER_CRUD: '/order/crud',
  ORDER_CREDIT_NOTE: '/order/credits',
  ORDER_CREDIT_NOTE_CRUD: '/order/credits/crud',

  SUPPLIER: '/supplier',
  SUPPLIER_CRUD: '/supplier/crud',

  CONFIG: '/config',
}

export const routesCommon = [
  { path: RoutesNames.LOGIN, component: Pages.Login },
  { path: RoutesNames.NO_ACCESS, component: Pages.NoAccess },
]

export const routesAdmin = [
  ...routesCommon,
  { path: '/', component: Pages.Home, isPrivate: true },
  { path: RoutesNames.HOME, component: Pages.Home, isPrivate: true },
  { path: RoutesNames.USER, component: Pages.User, isPrivate: true },
  { path: RoutesNames.CLIENT, component: Pages.Client, isPrivate: true },
  { path: RoutesNames.COMPANY, component: Pages.Company, isPrivate: true },
  { path: RoutesNames.PROFILE, component: Pages.Profile, isPrivate: true },

  { path: RoutesNames.STOCK_LIST, component: Pages.Stock, isPrivate: true },
  { path: RoutesNames.STOCK_CRUD, component: Pages.StockCrud, isPrivate: true },
  {
    path: RoutesNames.STOCK_REQUEST,
    component: Pages.StockRequest,
    isPrivate: true,
  },

  {
    path: RoutesNames.MOBILE_BUCKET,
    component: Pages.MobileBucket,
    isPrivate: true,
  },
  {
    path: RoutesNames.MOBILE_HYDRAULIC,
    component: Pages.MobileHydraulic,
    isPrivate: true,
  },
  { path: RoutesNames.SERVICE_LIST, component: Pages.Service, isPrivate: true },
  {
    path: RoutesNames.SERVICE_CRUD,
    component: Pages.ServiceCrud,
    isPrivate: true,
  },
  {
    path: RoutesNames.SERVICE_BUCKET,
    component: Pages.MobileBucket,
    isPrivate: true,
  },
  {
    path: RoutesNames.SERVICE_HYDRAULIC,
    component: Pages.MobileHydraulic,
    isPrivate: true,
  },
  {
    path: RoutesNames.CLIENT_CRUD,
    component: Pages.ClientCrud,
    isPrivate: true,
  },
  {
    path: RoutesNames.COMPANY_CRUD,
    component: Pages.CompanyCrud,
    isPrivate: true,
  },
  {
    path: RoutesNames.CLIENT_CRUD,
    component: Pages.ClientCrud,
    isPrivate: true,
  },
  {
    path: RoutesNames.ORDER_QUOTATION,
    component: Pages.Order,
    isPrivate: true,
  },
  { path: RoutesNames.ORDER_SALE, component: Pages.Order, isPrivate: true },
  {
    path: RoutesNames.ORDER_INVOICE_CREDIT,
    component: Pages.Order,
    isPrivate: true,
  },
  {
    path: RoutesNames.ORDER_CRUD,
    component: Pages.OrderCrud,
    isPrivate: true,
  },
  {
    path: RoutesNames.SUPPLIER,
    component: Pages.Supplier,
    isPrivate: true,
  },
  {
    path: RoutesNames.SUPPLIER_CRUD,
    component: Pages.SupplierCrud,
    isPrivate: true,
  },
]
