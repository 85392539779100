import React from 'react'

import { Stack, Typography, } from '@mui/material'
import { blue, green, red, amber, yellow } from '@mui/material/colors'
import { Variant } from '@mui/material/styles/createTypography'

type StatusType = 'SUCCESS' | 'ERROR' | 'INFO' | 'WARNING'

interface Colors {
    backgroundColor: string
    textColor: string
}

interface StatusProps {
    type: StatusType
    title: string
    titleVariant?: Variant
    isTitleBold?: boolean
    onClick?: () => void
}

export const Status = ({ type, title, titleVariant = 'body2', isTitleBold, onClick }: StatusProps) => {

    const colors: Record<StatusType, Colors> = {
        'SUCCESS': {
            backgroundColor: green[50],
            textColor: green[900]
        },
        'ERROR': {
            backgroundColor: red[50],
            textColor: red[900]
        },
        'INFO': {
            backgroundColor: amber[50],
            textColor: yellow[900]
        },
        'WARNING': {
            backgroundColor: blue[50],
            textColor: blue[900]
        },
    }

    return (
        <Stack
            onClick={onClick}
            sx={{
                backgroundColor: colors[type].backgroundColor,
                height: 26,
                borderRadius: 1,
                paddingX: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography
                noWrap
                variant={titleVariant}
                color={colors[type].textColor}
                sx={{
                    ...(isTitleBold ? { fontWeight: 'bold' } : {})
                }}>
                {title}
            </Typography>
        </Stack >
    )
}

