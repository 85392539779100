import React, { useEffect, useState } from 'react'
import { TbDotsVertical, TbPencil, TbPlus, TbTrash } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { orderBy } from 'firebase/firestore'

import {
  AvatarLetter,
  BoxShadow,
  Breadcrumb,
  DialogConfirm,
  Status,
} from '@/components'
import { NoContent } from '@/components/no-content'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

const BOX_HEIGHT = 420

export const Company = () => {
  const navigate = useNavigate()
  const [, dispatch] = useGlobalState()

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [companySelected, setCompanySelected] = useState<Company | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)

  const [companyList, setCompanyList] = useState<Company[] | undefined>(
    undefined,
  )
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Company',
      title: 'Company',
      isCurrentPage: true,
    },
  ]

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    company: Company,
  ) => {
    setAnchorEl(event.currentTarget)
    setCompanySelected(company)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleDeleteCompany = async () => {
    if (!companySelected) return
    setLoadingDelete(true)
    await ApiService.Company.remove({ companyId: companySelected.id })
      .then(async () => {
        if (companySelected.photoURL && companySelected.photoName) {
          await ApiService.Company.removePhoto({
            companyId: companySelected.id,
            photoName: companySelected.photoName,
          })
        }
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Company deleted' },
        })
      })
      .catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Company was not deleted' },
        })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  const renderTableBody = () => {
    if (loadingList) {
      return (
        <Stack
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          position='absolute'
          height='360px'
        >
          <CircularProgress color='primary' />
        </Stack>
      )
    }

    if (companyList?.length === 0) {
      return (
        <Stack
          display='flex'
          alignItems='center'
          width='100%'
          position='absolute'
          height='360px'
        >
          <NoContent />
        </Stack>
      )
    }

    return (
      <TableBody>
        {companyList?.map(company => (
          <TableRow hover key={company.id}>
            <TableCell>
              <Stack flexDirection='row'>
                {company.photoURL ? (
                  <Avatar src={company.photoURL} />
                ) : (
                  <AvatarLetter
                    name={company.name.substring(0, 1).toUpperCase()}
                  />
                )}
                <Box marginRight={2} />
                <Stack justifyContent='center'>
                  <Typography variant='body2'>{company.name}</Typography>
                  <Typography variant='caption' color={grey[500]}>
                    {company.email}
                  </Typography>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell>
              <Typography variant='body2'>{company.address}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='body2'>{company.phoneNumber}</Typography>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Status
                  title={company.isActive ? 'Active' : 'Inactive'}
                  type={company.isActive ? 'SUCCESS' : 'ERROR'}
                />
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => {
                      navigate(RoutesNames.COMPANY_CRUD, {
                        state: { companySelected: JSON.stringify(company) },
                      })
                    }}
                  >
                    <TbPencil color={grey[900]} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Options'>
                  <IconButton
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={event => {
                      handleMenuAction(event, company)
                    }}
                  >
                    <TbDotsVertical color={grey[900]} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  useEffect(() => {
    if (companyList === undefined) {
      ApiService.Company.getList({
        setCompanyList,
        setLoadingList,
        orderByList: [orderBy('createdAt', 'asc')],
      })
    }
  }, [companyList, ApiService.Company.getList])

  return (
    <Box>
      <Breadcrumb pageTitle='List of companies' menu={breadcrumbList}>
        <Stack display='flex' alignItems='center'>
          <Button
            startIcon={<TbPlus />}
            color='btnSecondary'
            variant='contained'
            size='small'
            onClick={() => {
              navigate(RoutesNames.COMPANY_CRUD)
            }}
            disableElevation
          >
            New
          </Button>
        </Stack>
      </Breadcrumb>

      <BoxShadow>
        <TableContainer sx={{ height: BOX_HEIGHT }}>
          {/* <Stack
          padding={2}
          sx={{ width: { xs: '100%', md: '50%', lg: '50%', xl: '50%' } }}
        >
          <TextField
            size='small'
            label='Pesquisar por nome'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <TbSearch color={grey[900]} />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Stack> */}
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Typography variant='subtitle2'>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Address</Typography>
                </TableCell>
                <TableCell width={160}>
                  <Typography variant='subtitle2'>Phone number</Typography>
                </TableCell>
                <TableCell width={100}>
                  <Typography variant='subtitle2'>Status</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
        </TableContainer>
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.COMPANY_CRUD, {
              state: { companySelected: JSON.stringify(companySelected) },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          disabled
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${companySelected?.name}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteCompany()
          },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />
    </Box>
  )
}
