import React from "react";

import { useField } from "formik";

import { TextField, TextFieldProps } from "@mui/material";

type FormikTextFieldProps = {
  name: string;
  fullWidth?: boolean;
} & TextFieldProps;

export const FormikTextField = ({
  name,
  fullWidth = true,
  ...props
}: FormikTextFieldProps) => {
  const [
    field,
    meta,
    // helpers
  ] = useField(name);

  return (
    <TextField
      {...field}
      fullWidth={fullWidth}
      id={field.name}
      name={field.name}
      helperText={meta.touched ? meta.error : ""}
      error={meta.touched && Boolean(meta.error)}
      value={field.value || ""}
      onChange={field.onChange}
      inputProps={{
        autocomplete: "new-password",
        // form: {
        //   autocomplete: "off",
        // },
      }}
      {...props}
    />
  );
};
