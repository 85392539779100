// GERENCIAMENTO DE ROTAS
// https://www.youtube.com/watch?v=oUZjO00NkhY

import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

import { useGlobalState } from '@/context'

import { RoutesNames } from './routes'

const PrivateRoute = () => {
  const [{ user }] = useGlobalState()
  // const location = useLocation()

  const renderRoute = () => {
    if (!user) {
      return <Navigate to='/login'
        // state={{ from: location }} //REDIRECIONA PARA O ULTIMO ROTA QUANDO ESTAVA LOGADO
        replace />
    }

    return <Outlet />
  }

  return renderRoute()
}

interface AllowedRouteProps {
  allowedRoles?: Roles[]
}
const AllowedRoute = ({ allowedRoles }: AllowedRouteProps) => {
  const [{ user }] = useGlobalState()
  // const location = useLocation()

  const renderRoute = () => {
    if (!allowedRoles) return <Outlet />

    const hasAnyRole = user?.userConfig?.roles.find(role => allowedRoles?.includes(role))

    if (!hasAnyRole) {
      return (
        <Navigate
          to={RoutesNames.NO_ACCESS}
          // state={{ from: location }}
          replace
        />
      )
    }

    return <Outlet />
  }

  return renderRoute()
}

const LoggedRoute = () => {
  const [{ user }] = useGlobalState()

  const renderRoute = () => {
    if (user) {
      if (user?.userConfig?.roles.includes('MECHANIC_HYDRAULIC')) {
        return <Navigate to={RoutesNames.MOBILE_HYDRAULIC} replace />
      } else if (user?.userConfig?.roles.includes('MECHANIC_BUCKET')) {
        return <Navigate to={RoutesNames.MOBILE_BUCKET} replace />
      } else {
        return <Navigate to={RoutesNames.HOME} replace />
      }
    }
    return <Outlet />
  }

  return renderRoute()
}

export { PrivateRoute, AllowedRoute, LoggedRoute }
