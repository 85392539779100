// EXEMPLO
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat

interface formatNumberToLibraProps {
  value: number
  showCurrency?: boolean
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}
export const formatNumberToLibra = ({
  value,
  showCurrency,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
}: formatNumberToLibraProps) =>
  new Intl.NumberFormat('en-GB', {
    ...(showCurrency ? { style: 'currency' } : {}),
    currency: 'GBP',
    minimumFractionDigits,
    maximumFractionDigits,
    // minimumSignificantDigits: 2,
    // maximumSignificantDigits: 2,
  }).format(value)

interface truncateByDecimalPlaceProps {
  value: number
  numDecimalPlaces?: number
}
export const truncateByDecimalPlace = ({
  value,
  numDecimalPlaces = 2,
}: truncateByDecimalPlaceProps) =>
  Math.trunc(value * Math.pow(10, numDecimalPlaces)) /
  Math.pow(10, numDecimalPlaces)

interface totalPriceWithDiscontPercentProps {
  price: number
  percent: number
}
export const totalPriceWithDiscontPercent = ({
  price,
  percent,
}: totalPriceWithDiscontPercentProps) => {
  const discount = Number(percent) / 100
  return truncateByDecimalPlace({
    value: Number(price) - Number(price) * discount,
  })
}

interface discontPercentProps {
  price: number
  percent: number
}
export const discontPercent = ({ price, percent }: discontPercentProps) => {
  const discount = Number(percent) / 100
  return truncateByDecimalPlace({ value: Number(price) * discount })
}
