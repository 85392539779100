import { useState } from 'react'

import { FirestoreError } from 'firebase/firestore'
import {
  StorageError,
  UploadMetadata,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'

import { useGlobalState } from '@/context'
import { storage } from '@/services/firebase'
import { setNameToFile } from '@/utils/file'

interface StartUploadProps {
  file: File | undefined
  filePath: string
  fileName?: string
  metadata?: UploadMetadata
  onSuccess?: (urlFile: string) => void | Promise<void>
  onError?: () => void
}

export const useStorage = () => {
  const [, dispatch] = useGlobalState()
  const [urlFile, setUrlFile] = useState<string>('')
  const [progress, setProgress] = useState<number>(0)
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
  const [successUpload, setSuccessUpload] = useState<boolean>(false)

  const [error, setError] = useState<StorageError | FirestoreError | undefined>(
    undefined,
  )

  const startUpload = ({
    file,
    filePath,
    fileName,
    metadata,
    onSuccess,
    onError,
  }: StartUploadProps) => {
    setUrlFile('')
    setProgress(0)
    setLoadingUpload(false)
    setError(undefined)
    setSuccessUpload(false)

    if (!file) return

    const storageRef = ref(
      storage,
      `${filePath}/${setNameToFile(file, fileName)}`,
    )
    const uploadTask = uploadBytesResumable(storageRef, file, {
      contentType: file.type,
      ...metadata,
    })

    setLoadingUpload(true)

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(progress)
      },
      error => {
        setError(error)
        setLoadingUpload(false)
        if (onError) onError()
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Erro ao salvar o arquivo', alertSeverity: 'error' },
        })
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          setUrlFile(downloadURL)
          setSuccessUpload(true)
          setLoadingUpload(false)
          if (onSuccess) onSuccess(downloadURL)
        })
      },
    )
  }

  return {
    urlFile,
    progress,
    loadingUpload,
    successUpload,
    error,
    startUpload,
  }
}
