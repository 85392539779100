import { DocumentData, QuerySnapshot } from 'firebase/firestore'

export const firebaseDataFormaterToArray = <T>(
  data: QuerySnapshot<DocumentData> | undefined,
): T[] => {
  if (!data || data.docs.length === 0) return []

  const array: T[] = []

  data.docs.forEach(docSnapshot => {
    array.push({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    } as T)
  })

  return array
}

export const formaterUserDataToApp = (data: UserFirebase) => {
  const photoURL = data.photoURL
  let userConfig = {} as UserConfig

  if (photoURL) {
    userConfig = { ...JSON.parse(photoURL) }
  } else {
    userConfig = {
      companyId: '',
      companyName: '',
      photoURL: '',
      photoName: '',
      roles: ['ROOT'],
    }
  }

  const temp: User = {
    ...data,
    userConfig,
  }

  return temp
}
