import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Form, FormikProvider, useFormik } from 'formik'

import { BoxShadow, Breadcrumb, FormikTextField, DialogConfirm, FormikSwitch, FormikTextNumericFormat, PhotoUpload } from '@/components'
import { useGlobalState } from '@/context';
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Grid,
  Stack,
} from '@mui/material'

import { validationSchema } from './validationSchema';

const BOX_HEIGHT = 472

const INITIAL_VALUES: Omit<Stock, 'id' | 'createdAt' | 'updatedAt' | 'dateCreatedAt'> = {
  title: '',
  description: '',
  price: 0,
  quantity: 0,
  isActive: true,
}

export const StockCrud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ company }, dispatch] = useGlobalState()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const locationStateStockSelected = location.state?.stockSelected ? JSON.parse(location.state?.stockSelected) : undefined
  const [stockSelected] = useState<Stock | undefined>(locationStateStockSelected)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'stock',
      title: 'Stock',
      href: RoutesNames.STOCK_LIST,
    },
    {
      id: 'stock-crud',
      title: stockSelected ? 'Updating' : 'New',
      isCurrentPage: true,
    },
  ]
  const resetForm = () => {
    formik.resetForm()
    setInitialValues(INITIAL_VALUES)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoadingSave(true)

      if (!stockSelected) {
        await ApiService.Stock.save({
          companyId,
          values
        })

        resetForm()
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Save successfull' }
        })
        setLoadingSave(false)
        return
      }

      await ApiService.Stock.update({
        companyId,
        stockId: stockSelected?.id,
        values
      }).then(() => {
        resetForm()
        navigate(RoutesNames.STOCK_LIST)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Save successfull' }
        })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on update', alertSeverity: 'error' }
        })
      }).finally(() => {
        setLoadingSave(false)
      })

    },
  })

  const handleDeleteStock = async () => {
    if (!stockSelected) return
    setLoadingDelete(true)
    await ApiService.Stock.remove({ companyId, stockId: stockSelected.id })
      .then(async () => {
        resetForm()
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Part deleted' }
        })
        navigate(RoutesNames.STOCK_LIST)
      }).catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Part was not deleted' }
        })
      }).finally(() => {
        setLoadingDelete(false)
      })
  }

  useEffect(() => {
    if (!stockSelected) return
    setInitialValues(stockSelected)
  }, [stockSelected,])

  return (
    <Box>
      <Breadcrumb
        pageTitle={
          stockSelected ? 'Update of data' : 'Registration'
        }
        menu={breadcrumbList}
      />

      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>

                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  alignItems='center'
                  justifyContent='center'
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    alignItems='center'
                    justifyContent='center'
                    paddingTop='20px'
                  >
                    <PhotoUpload
                      isDisableUpload
                      inputFileRef={inputFileRef}
                      fileToUpload={fileToUpload}
                      fileUrl={formik.values.photoURL}
                      setFileToUpload={(files) => {
                        if (!files) return
                        setFileToUpload(files[0])
                      }}
                    />
                  </Stack>

                  <FormikSwitch size='small' name='isActive' label='Part active' />

                  {stockSelected && (
                    <>
                      <Box mb={2} />
                      <LoadingButton
                        color='btnError'
                        variant='contained'
                        disableElevation
                        onClick={() => { setIsOpenConfirmDelete(true) }}
                      >
                        Delete
                      </LoadingButton>
                    </>
                  )}
                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack minHeight={BOX_HEIGHT} padding={3}>

                  <Stack display='flex' flex={1}>
                    <Box>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={8} sm={12}>
                          <FormikTextField name='title' fullWidth label='Name' />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikTextNumericFormat name='price' label='Price' />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikTextField name='quantity' label='Quantity' type='number' />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextField name='description' fullWidth label='Description' rows={5} multiline />
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>

                  <Stack flexDirection='row' justifyContent='flex-end' >
                    <Button
                      color='btnSecondary'
                      variant='contained'
                      disableElevation
                      onClick={() => { navigate(RoutesNames.STOCK_LIST) }}
                    >
                      Back
                    </Button>
                    <Box mr={2} />
                    <LoadingButton
                      variant='contained'
                      color='btnPrimaryFill'
                      disableElevation
                      type='submit'
                      loading={loadingSave}
                    >
                      {stockSelected ? 'Save update' : 'Save part'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${stockSelected?.title}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => { handleDeleteStock() },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => { setIsOpenConfirmDelete(false) },
        }}
      />

    </Box >
  )
}
