/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { TbChevronDown, TbCircleCheck, TbPercentage } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom'

import { where } from 'firebase/firestore';
import { Form, FormikProvider, useFormik } from 'formik'
import moment from 'moment'

import { BoxShadow, Breadcrumb, FormikTextField, DialogConfirm, FormikSwitch, FormikTextNumericFormat, FormikSelectField, DrawerChooseClient } from '@/components'
import { FormikSelectFieldMenu } from '@/components/formik-select-field';
import { EnumOrderStatus } from '@/constants/order';
import { useGlobalState } from '@/context';
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { formatNumberToLibra, totalPriceWithDiscontPercent } from '@/utils/currency';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { green, grey } from '@mui/material/colors';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { validationSchema } from './validationSchema';

const BOX_HEIGHT = 492

const INITIAL_VALUES: Partial<Order> = {
  invoiceNumber: undefined,
  saleNumber: undefined,
  paymentDue: null,
  status: 'OPEN',
  clientId: '',
  isActive: true,
  workHours: 0,
  totalPrice: 0,
  paymentRef: undefined,
  carriageNet: null,
  discountPrice: null,
}

const INITIAL_VALUES_GENERATE_CREDIT: Partial<Order> = {
  observation: undefined,
}

const statusList: FormikSelectFieldMenu[] = Object.entries(EnumOrderStatus).map(([key, value]) => ({
  value: key,
  title: value,
}))

export const OrderCrud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ company }, dispatch] = useGlobalState()
  const [initialValues, setInitialValues] = useState<Partial<Order>>(INITIAL_VALUES)
  const locationStateOrderSelected = location.state?.orderSelected ? JSON.parse(location.state?.orderSelected) : undefined
  const [orderSelected] = useState<Order | undefined>(locationStateOrderSelected)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'order',
      title: 'Order',
      href: RoutesNames.ORDER_QUOTATION,
    },
    {
      id: 'order-crud',
      title: orderSelected ? 'Updating' : 'New',
      isCurrentPage: true,
    },
  ]
  const [clientChoosed, setClientChoosed] = useState<Client | undefined>(
    undefined,
  )
  const [partsSold, setpartsSold] = useState<StockWithCheck[] | undefined>(undefined)
  const [clientCreditList, setClientCreditList] = useState<ClientCredit[] | undefined>(undefined)
  const [loadingClientCreditList, setLoadingClientCreditList] = useState<boolean>(false)
  const [showDialogGenerateCredit, setShowDialogGenerateCredit] = useState<boolean>(false)
  const [loadingSaveClientCredit, setLoadingSaveClientCredit] = useState<boolean>(false)
  const [discountApplied, setDiscountApplied] = useState<boolean>(false)

  const resetForm = () => {
    formik.resetForm()
    formikGeneretaCredit.resetForm()
    setInitialValues(INITIAL_VALUES)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoadingSave(true)

      const data = {
        ...values,
        ...(values.paymentRef ? { paymentRefCreatedAt: moment().valueOf() } : { paymentRefCreatedAt: null }),
        ...(discountApplied ? { discountPrice: values.discountPrice } : {})
      }

      await ApiService.Order.update({
        companyId,
        orderId: orderSelected?.id || '',
        values: data
      }).then(async () => {
        const showMsg = () => {
          resetForm()
          navigate(location.state?.from)
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Save successfull' }
          })
        }

        if (Number(orderSelected?.totalPrice) !== Number(values.totalPrice)) {
          await ApiService.Service.update({
            companyId,
            serviceId: orderSelected?.serviceId || '',
            values: {
              totalPrice: Number(values.totalPrice),
              workHours: values.workHours || 0
            }
          }).then(() => {
            showMsg()
          }).catch(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Error updating the total price in the service table', alertSeverity: 'error' }
            })
          })
        } else {
          showMsg()
        }
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on update', alertSeverity: 'error' }
        })
      }).finally(() => {
        setLoadingSave(false)
      })

    },
  })

  const formikGeneretaCredit = useFormik({
    initialValues: INITIAL_VALUES_GENERATE_CREDIT,
    onSubmit: async (values) => {
      if (!orderSelected) return
      setLoadingSaveClientCredit(true)

      await ApiService.Order.update({
        companyId,
        orderId: orderSelected?.id || '',
        values: { status: 'RETURNED', paymentDue: null }
      }).then(async () => {

        const clientCreditIdIncrement = await ApiService.ClientCredits.getLastIdIncrement({
          companyId
        })

        let idIncrement = 1
        if (clientCreditIdIncrement) {
          idIncrement = Number(clientCreditIdIncrement.idIncrement) + 1
        }

        await ApiService.ClientCredits.save({
          companyId,
          values: {
            idIncrement,
            clientId: orderSelected.clientId,
            clientName: orderSelected.clientName,
            orderId: orderSelected.id,
            serviceId: orderSelected.serviceId,
            amount: orderSelected.totalPrice,
            observation: values.observation,
            isActive: true,
            isUsed: false,
          }
        }).then(() => {
          navigate(location.state?.from)
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Successfully canceled' }
          })
        }).catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error on update client credit', alertSeverity: 'error' }
          })
        }).finally(() => {
          setLoadingSaveClientCredit(false)
        })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on update', alertSeverity: 'error' }
        })
      })

    },
  })

  const handleDeleteOrder = async () => {
    if (!orderSelected) return
    setLoadingDelete(true)
    // await ApiService.Order.remove({ companyId, orderId: orderSelected.id })
    //   .then(async () => {
    //     resetForm()
    //     setIsOpenConfirmDelete(false)
    //     dispatch({
    //       type: 'SET_SNACKBAR',
    //       data: { message: 'Part deleted' }
    //     })
    //     navigate(RoutesNames.ORDER)
    //   }).catch(() => {
    //     setIsOpenConfirmDelete(false)
    //     dispatch({
    //       type: 'SET_SNACKBAR',
    //       data: { message: 'Part was not deleted' }
    //     })
    //   }).finally(() => {
    //     setLoadingDelete(false)
    //   })
  }

  const getClientCredits = async (clientId: string) => {
    await ApiService.ClientCredits.getList({
      companyId,
      setClientCreditList,
      setLoadingList: setLoadingClientCreditList,
      whereList: [
        where('clientId', '==', clientId),
        where('isActive', '==', true)
      ]
    })
  }

  const RenderClientCredit = () => {
    if (loadingClientCreditList) {
      return <h1>...</h1>
    }

    if (!loadingClientCreditList && clientCreditList && clientCreditList.length > 0) {
      const amountTotal = clientCreditList.map((item) => item.amount).reduce((acc, val) => parseFloat(acc.toString()) + parseFloat(val.toString()))

      return clientCreditList.map((item, index) => (
        <Stack key={item.id}>
          <Typography variant='caption' fontWeight={300} display='flex' flexDirection='row' >
            {index + 1} - <Typography variant='caption' ml={0.4} fontWeight='bold'>
              {formatNumberToLibra({ value: item.amount, showCurrency: true })} - {moment(item.createdAt).format('DD/MM/YYYY, h:mm A')}
            </Typography>
          </Typography>
          {item.observation && <Typography variant='caption'>{item.observation}</Typography>}
          {(index + 1) < clientCreditList.length ? (
            <Box my={1}><Divider /></Box>
          ) : (
            <Typography variant='caption' display='flex' flexDirection='row' mt={2} fontWeight='bold'>
              Total credits: <Typography variant='caption' ml={1} fontWeight='bold'>{formatNumberToLibra({
                value: amountTotal, showCurrency: true
              })}
              </Typography>
            </Typography>
          )}
        </Stack>
      ))
    }

    if (!loadingClientCreditList && clientCreditList && clientCreditList.length === 0) {
      return <Typography variant='subtitle2' textAlign='center'>There is no credits</Typography>
    }
  }

  const getServiceData = async () => {
    if (!orderSelected?.serviceId) return
    const response = await ApiService.Service.getOne({ companyId, serviceId: orderSelected.serviceId })
    setpartsSold(JSON.parse(response?.partsSold || '[]'))
  }

  // const handleApplyDiscount = () => {
  //   const workHours = formik.values?.workHours
  //   if (!formik.values.discountPrice || !orderSelected?.originalTotalPrice || (!workHours || Number(workHours || 0) < 0 || !partsSold)) return


  //   let totalPriceParts = 0
  //   partsSold.forEach(item => {
  //     const totalPerItem = Number(item.price) * Number(item.howManyQuantity)
  //     const sum = Number(totalPerItem) + Number(totalPriceParts)
  //     totalPriceParts = sum
  //   })

  //   const hourPrice = Number(company?.workerHourPrice || 0) * Number(workHours)
  //   const total = Number(totalPriceParts) + Number(hourPrice)



  //   formik.setFieldValue('totalPrice', totalPriceWithDiscontPercent({ price: total, percent: Number(formik.values.discountPrice) }))
  //   setDiscountApplied(true)
  // }

  useEffect(() => {
    if (!formik.values.discountPrice) {
      formik.setFieldValue('totalPrice', orderSelected?.totalPrice)
      setDiscountApplied(false)
    }
  }, [formik.values.discountPrice])

  useEffect(() => {
    if (!orderSelected) return
    setInitialValues(orderSelected)
    getServiceData()
    getClientCredits(orderSelected.clientId)
  }, [orderSelected])

  useEffect(() => {
    const workHours = Number(formik.values?.workHours || 0)
    const discountPrice = Number(formik.values.discountPrice || 0)
    const workerHourPrice = Number(company?.workerHourPrice || 0)

    if (workHours <= 0 || !partsSold) return

    let totalPriceParts: number = 0
    partsSold.forEach(item => {
      const totalPerItem = Number(item.price) * Number(item.howManyQuantity)
      const sum = Number(totalPerItem) + Number(totalPriceParts)
      totalPriceParts = sum
    })

    const hourPrice = workerHourPrice * workHours
    const total = totalPriceParts + hourPrice

    if (discountPrice || discountPrice > 0) {
      formik.setFieldValue('totalPrice', totalPriceWithDiscontPercent({ price: total, percent: discountPrice }))
    } else {
      formik.setFieldValue('totalPrice', total)
    }
  }, [formik.values.workHours, formik.values.discountPrice, partsSold,])

  return (
    <Box>
      <Breadcrumb
        pageTitle={orderSelected ? 'Update of data' : 'Registration'}
        menu={breadcrumbList}
      />

      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>
                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    mb={2}
                  >
                    <Accordion>
                      <AccordionSummary expandIcon={<TbChevronDown />} >
                        <Typography variant='body2'>Credits ({(clientCreditList || []).length})</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 1 }}>
                        {RenderClientCredit()}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<TbChevronDown />}>
                        <Typography variant='body2'>Parts choosed to sold ({(partsSold || []).length})</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 1 }}>
                        <List>
                          {partsSold?.map((stock, index) => (
                            <>
                              <ListItem key={stock.id} disablePadding>

                                <ListItemText
                                  // primary={stock.title}
                                  secondary={
                                    <Stack pt={0.5}>

                                      <Typography
                                        variant='caption'
                                        color={grey[900]}
                                        fontWeight='bold'
                                      >
                                        {stock.title}
                                      </Typography>

                                      <Typography variant='caption'>
                                        Quantity:{' '}
                                        <Typography
                                          variant='caption'
                                          color={grey[900]}
                                          fontWeight='bold'
                                        >
                                          {stock.howManyQuantity || 0}
                                        </Typography>
                                      </Typography>
                                      <Typography variant='caption'>
                                        Price:{' '}
                                        <Typography
                                          variant='caption'
                                          color={grey[900]}
                                          fontWeight='bold'
                                        >
                                          {formatNumberToLibra({
                                            value: stock.price,
                                            showCurrency: true,
                                          })}
                                        </Typography>
                                      </Typography>
                                    </Stack>
                                  }
                                />
                              </ListItem>
                              {(index + 1) < partsSold.length && <Divider component="li" />}
                            </>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                  <Stack
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <FormikSwitch size='small' name='isActive' label='Order active' />
                    <Stack
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      justifyContent='space-between'
                      mt={2}
                    >
                      <LoadingButton
                        color='btnError'
                        variant='contained'
                        disableElevation
                        disabled={orderSelected?.type !== 'QUOTATION'}
                        onClick={() => { setIsOpenConfirmDelete(true) }}
                      >
                        Delete
                      </LoadingButton>
                      <LoadingButton
                        variant='contained'
                        color='btnSuccess'
                        disableElevation
                        loading={loadingSaveClientCredit}
                        disabled={!(orderSelected?.type === 'INVOICE_AND_CREDIT' && orderSelected?.status === 'ACCEPTED')}
                        onClick={() => {
                          setShowDialogGenerateCredit(true)
                        }}
                      >
                        Generate credit
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack padding={3} minHeight={BOX_HEIGHT}>

                  <Stack display='flex' flex={1}>
                    <Grid container spacing={2} rowSpacing={3}>
                      <Grid item xs={6}>
                        <FormikTextField name='invoiceNumber' fullWidth label='Invoice number' disabled />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikTextField name='saleNumber' fullWidth label='Sale number' disabled />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikSelectField
                          name='status'
                          fullWidth
                          label='Status'
                          menuList={statusList}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <DrawerChooseClient
                          isDisabled={true}
                          clientChoosed={clientChoosed}
                          clientIdToSelect={orderSelected?.clientId}
                          setClientChoosed={client => {
                            setClientChoosed(client)
                            if (client) {
                              formik.setFieldValue('clientId', client.id)
                              return
                            }
                            formik.setFieldValue('clientId', '')
                          }}
                          errorMessage={
                            formik.touched.clientId && formik.errors.clientId
                              ? formik.errors.clientId
                              : undefined
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DemoContainer components={['DatePicker']}>
                            <FormControl fullWidth>
                              <DatePicker
                                slotProps={{
                                  textField: {
                                    name: 'paymentDue',
                                    error: formik.touched.paymentDue && Boolean(formik.errors.paymentDue),
                                    helperText: (formik.touched.paymentDue && Boolean(formik.errors.paymentDue)) ? formik.errors.paymentDue?.toString() || '' : ''
                                  },
                                  field: {
                                    clearable: true,
                                    onClear: () => { formik.setFieldValue('paymentDue', null) },
                                  },
                                }}
                                format='DD/MM/YYYY'
                                label="Payment due (today + 30d)"
                                minDate={moment()}
                                name='paymentDue'
                                value={formik.values.paymentDue ? moment(formik.values.paymentDue) : null}
                                onChange={(date) => {
                                  if (!date) {
                                    formik.setFieldValue('paymentDue', null, false)
                                    formik.setFieldTouched('paymentDue', true)
                                  } else {
                                    formik.setFieldValue('paymentDue', date.valueOf())
                                  }
                                }}
                              />
                            </FormControl>
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    <Typography variant='subtitle2' mt={3} mb={2} ml={1}>
                      Payment
                    </Typography>

                    <Grid container spacing={2} rowSpacing={3}>
                      <Grid item xs={4} >
                        <FormikTextField
                          name='workHours'
                          fullWidth label={`Work hours (original: ${orderSelected?.originalWorkHours || 0})`}
                          type='number'
                        />
                      </Grid>

                      <Grid item xs={4} >
                        <FormikTextField name='discountPrice' label='Discount %'

                          type='number'
                          value={formik.values.discountPrice}
                          onChange={(e) => {
                            let value = Number(e.target.value);
                            if (value > 100) value = 100;
                            if (value < 0) value = 0;
                            console.log('values: ', value);
                            formik.setFieldValue('discountPrice', value || null)
                          }}
                        // InputProps={{
                        //   startAdornment: <TbPercentage size={32} />,
                        //   endAdornment: (
                        //     <Tooltip title="Apply dicount" sx={{ mr: -1.5 }}>
                        //       <IconButton aria-label="delete" onClick={handleApplyDiscount}>
                        //         <TbCircleCheck color={green[500]} />
                        //       </IconButton>
                        //     </Tooltip>
                        //   ),
                        // }}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <FormikTextNumericFormat
                          fullWidth
                          name='totalPrice'
                          disabled
                          label={`Total price (original: ${formatNumberToLibra({ value: orderSelected?.originalTotalPrice || 0, showCurrency: true })})`}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikTextField name='paymentRef' fullWidth label='Payment ref' />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikTextNumericFormat name='carriageNet' fullWidth label='Carriage net' />
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack flexDirection='row' justifyContent='flex-end' mt={3} >
                    <Button
                      color='btnSecondary'
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        navigate(location.state?.from)
                      }}
                    >
                      Back
                    </Button>
                    <Box mr={2} />
                    <LoadingButton
                      variant='contained'
                      color='btnPrimaryFill'
                      disableElevation
                      type='submit'
                      loading={loadingSave}
                      disabled={orderSelected?.status === 'RETURNED'}
                    >
                      Save order
                    </LoadingButton>
                  </Stack>
                </Stack>
              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => { handleDeleteOrder() },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => { setIsOpenConfirmDelete(false) },
        }}
      />

      <DialogConfirm
        title='Cancel order'
        description='Do you really want to cancel the service order and generate a credit?'
        maxWidth='sm'
        open={!!showDialogGenerateCredit}
        onCloseDialog={() => { setShowDialogGenerateCredit(false) }}
        btnOkProps={{
          loading: loadingSaveClientCredit,
          onClick: () => {
            formikGeneretaCredit.submitForm()
          },
        }}
        btnNoProps={{
          onClick: () => {
            setShowDialogGenerateCredit(false)
          },
        }}
      >
        <Box mt={2}>
          <FormikProvider value={formikGeneretaCredit}>
            <Form>
              <FormikTextField name='observation' fullWidth label='Reason for cancellation' rows={2} multiline />
            </Form >
          </FormikProvider >
        </Box>
      </DialogConfirm>

    </Box >
  )
}
