export enum EnumServiceStatus {
  TO_DO = 'To Do',
  IN_PROGRESS = 'In Progress',
  AWAITING_APPROVAL = 'Awaiting Approval',
  APPROVED = 'Approved',
  START_WORK = 'Start Work',
  WORK_FINISH = 'Work Finish',
  DELIVERED = 'Delivered',
}

export enum EnumServiceType {
  HYDRAULIC = 'Hydraulics',
  BUCKET = 'Bucket',
}
