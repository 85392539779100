import React from "react";

import { useField } from "formik";


import { SxProps, Theme, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch, { SwitchProps } from '@mui/material/Switch';

type FormikCheckboxProps = {
  name: string;
  label?: string;
  sx?: SxProps<Theme>;
} & SwitchProps;
export const FormikSwitch = ({
  name,
  label,
  sx,
  ...props
}: FormikCheckboxProps) => {
  const [
    field,
    meta,
    // helpers
  ] = useField(name);

  return (
    <>
      <FormControlLabel
        key={name}
        control={
          <Switch {...field} {...props} name={name} checked={field.value} />
        }
        label={<Typography variant="body2" color="textSecondary">{label}</Typography>}
        sx={sx}
      />

      {meta.touched && Boolean(meta.error) && (
        <FormHelperText sx={{ color: red[700] }}>{meta.error}</FormHelperText>
      )}
    </>
  );
};
