import React, {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
} from 'react'

import { onAuthStateChanged } from "firebase/auth";

import { SnackbarProps } from '@/components/snackbar'
import { ApiService } from '@/services'
import { auth } from '@/services/firebase'
import { formaterUserDataToApp } from '@/utils/firebase';
import { delay } from '@/utils/tricks';

interface RootState {
  user: User | undefined
  isLoadingApp: boolean
  snackbar: SnackbarProps | undefined
  isOpenDrawerSidebar: boolean
  company: Company | undefined
}

type Actions =
  | { type: 'SET_USER'; data: User | undefined }
  | { type: 'SET_IS_LOADING_APP'; data: boolean }
  | { type: 'SET_SNACKBAR'; data: SnackbarProps | undefined }
  | { type: 'SET_LOGOUT' }
  | { type: 'SET_IS_OPEN_DRAWER_SIDEBAR'; data: boolean }
  | { type: 'SET_COMPANY'; data: Company | undefined }

const initialState: RootState = {
  user: undefined,
  isLoadingApp: true,
  snackbar: undefined,
  isOpenDrawerSidebar: false,
  company: undefined,
}

const reducer = (state: RootState, action: Actions) => {
  switch (action.type) {
    case 'SET_USER': {
      return { ...state, user: action.data }
    }
    case 'SET_IS_LOADING_APP': {
      return { ...state, isLoadingApp: action.data }
    }
    case 'SET_SNACKBAR': {
      return { ...state, snackbar: action.data }
    }
    case 'SET_LOGOUT': {
      const userSignOut = async () => { await ApiService.Auth.userSignOut(); }
      userSignOut()
      localStorage.clear()
      return { ...state, user: undefined }
    }
    case 'SET_IS_OPEN_DRAWER_SIDEBAR': {
      return { ...state, isOpenDrawerSidebar: action.data }
    }
    case 'SET_COMPANY': {
      return { ...state, company: action.data }
    }

    default:
      return state
  }
}

type GlobalStateContextData = [RootState, Dispatch<Actions>]

const GlobalStateContext = createContext<GlobalStateContextData | undefined>(
  undefined,
)

interface GlobalStateProviderProps extends React.PropsWithChildren { }

export const GlobalStateProvider = ({ children }: GlobalStateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const checkUser = async () => {
      await delay(500)

      const getUserCompany = async (user: User) => {

        if (user.userConfig?.roles.includes('ROOT')) {
          dispatch({ type: 'SET_USER', data: user })
          dispatch({ type: "SET_IS_LOADING_APP", data: false });
          return
        }

        const company = await ApiService.Company.getOne({ companyId: user.userConfig?.companyId || '' })
        const companyData = { ...company, id: user.userConfig?.companyId || '' } as Company

        dispatch({ type: 'SET_COMPANY', data: companyData })
        dispatch({ type: "SET_IS_LOADING_APP", data: false });
        dispatch({ type: 'SET_USER', data: user })
      }

      onAuthStateChanged(auth, (user) => {
        if (user) {
          const dataUserConfig = { ...formaterUserDataToApp(user) }
          getUserCompany(dataUserConfig)
        } else {
          dispatch({
            type: "SET_IS_LOADING_APP",
            data: false,
          });
        }
      })
    }

    checkUser()
  }, [])

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext)

  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider')
  }

  return context
}
