import React, { useEffect, useMemo, useState } from 'react'
import {
  TbDotsVertical,
  TbFileTypeXls,
  TbPencil,
  TbPlus,
  TbSearch,
  TbTrash,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { saveAs } from 'file-saver';
import { orderBy, where } from 'firebase/firestore'
import { Form, FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import * as XLSX from 'sheetjs-style';

import { BoxShadow, Breadcrumb, DialogConfirm } from '@/components'
import { NoContent } from '@/components/no-content'
import { EnumStockRequestStatus } from '@/constants/stock-request'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { discontPercent, formatNumberToLibra, truncateByDecimalPlace } from '@/utils/currency'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { green, grey, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { StockRequestStatus } from './status'
import { validationSchemaSummary } from './validationSchema'

const BOX_HEIGHT = 492

enum ENUM_ORDER_BY {
  KEY = 'Key',
  TITLE = 'Title',
  PART_NAME = 'Part name',
  SUPPLIER = 'Supplier name',
  STATUS = 'Status',
}
type ORDER_BY_TYPE = keyof typeof ENUM_ORDER_BY

interface InitialValueSummary {
  startDate: null | number
  endDate: null | number
}
const INITIAL_VALUES_SUMARY: InitialValueSummary = {
  // startDate: 1718074800000,
  // endDate: 1718161200000,
  startDate: null,
  endDate: null,
}

export const StockRequest = () => {
  const navigate = useNavigate()
  const [{ company }, dispatch] = useGlobalState()

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [stockRequestSelected, setStockRequestSelected] = useState<StockRequest | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)

  const [stockRequestList, setStockRequestList] = useState<StockRequest[] | undefined>(undefined)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [filterBy, setFilterBy] = useState<ORDER_BY_TYPE>('TITLE')
  const breadcrumbList: Breadcrumb[] = [
    {
      id: RoutesNames.HOME,
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'StockRequest',
      title: 'Purchase order',
      isCurrentPage: true,
    },
  ]
  const [searchStockRequest, setSearchStockRequest] = useState<string>('')
  const [searchStatusStockRequest, setSearchStatusStockRequest] = useState<StockRequestStatus | undefined>()
  const [isOpenPrintSummary, setIsOpenPrintSummary] = useState<boolean>(false)
  const [loadingPrintSummary, setLoadingPrintSummary] = useState<boolean>(false)
  const [stockRequestListPrintSummary, setStockRequestListPrintSummary] = useState<StockRequest[] | undefined>(undefined)

  const formikFilterSummary = useFormik({
    initialValues: INITIAL_VALUES_SUMARY,
    enableReinitialize: true,
    validationSchema: validationSchemaSummary,
    onSubmit: async (values) => {
      setLoadingPrintSummary(true)

      ApiService.StockRequest.getList({
        companyId,
        setStockRequestList: (list) => {
          if ((list || []).length === 0) {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'There is no data for the selected filter', alertSeverity: 'warning' },
            })
          }
          setStockRequestListPrintSummary(list)
        },
        setLoadingList: setLoadingPrintSummary,
        whereList: [where('dateCreatedAt', '>=', values.startDate), where('dateCreatedAt', '<=', values.endDate)],
        orderByList: [orderBy('dateCreatedAt', 'asc')],
      })
    },
  })

  const filterStockList = useMemo(
    () =>
      stockRequestList?.filter(stockRequest => {
        if (filterBy === 'KEY') {
          return stockRequest.id.substring(0, 6).toUpperCase().includes(searchStockRequest.toUpperCase())
        }
        if (filterBy === 'TITLE') {
          return stockRequest.title.toLowerCase().includes(searchStockRequest.toLowerCase())
        }
        if (filterBy === 'PART_NAME') {
          return stockRequest.stockTitle.toLowerCase().includes(searchStockRequest.toLowerCase())
        }
        if (filterBy === 'SUPPLIER') {
          return stockRequest.supplierName.toLowerCase().includes(searchStockRequest.toLowerCase())
        }
        if (filterBy === 'STATUS') {
          if (!searchStatusStockRequest) return stockRequest
          return stockRequest.status === searchStatusStockRequest
        }

        return stockRequest
      }
      ),
    [stockRequestList, searchStockRequest, searchStatusStockRequest],
  )

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    serice: StockRequest,
  ) => {
    setAnchorEl(event.currentTarget)
    setStockRequestSelected(serice)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleDeleteStock = async () => {
    if (!stockRequestSelected) return
    setLoadingDelete(true)
    await ApiService.StockRequest.remove({ companyId, stockRequestId: stockRequestSelected.id })
      .then(async () => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Request deleted' },
        })
      })
      .catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Request was not deleted', alertSeverity: 'error' },
        })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  const renderTableBody = () => {
    if (loadingList) {
      return (
        <Stack
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          position='absolute'
          height='360px'
        >
          <CircularProgress color='primary' />
        </Stack>
      )
    }

    if (filterStockList?.length === 0) {
      return (
        <Stack
          display='flex'
          alignItems='center'
          width='100%'
          position='absolute'
          height='360px'
        >
          <NoContent />
        </Stack>
      )
    }

    return (
      <TableBody>
        {filterStockList?.map(stock => {
          let backgroundColor = ''
          const isAfter = moment(stock.paymentDue).isAfter(moment())

          if (stock.isPaidOut) {
            backgroundColor = green[50]
          } else if (stock.paymentDue && !isAfter) {
            backgroundColor = red[50]
          } else {
            backgroundColor = '#FFF'
          }

          return (
            <TableRow hover key={stock.id} sx={{ backgroundColor }}>
              <TableCell>
                <Typography variant='body2'>{stock.id.substring(0, 6).toUpperCase()}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>{stock.title}</Typography>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2'>{stock.supplierName}</Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2'>{stock.stockTitle}</Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2'>{stock.quantity}</Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack flexDirection='column'>
                  <Typography variant='body2'>{
                    formatNumberToLibra({
                      value: stock.price,
                      showCurrency: true,
                    })}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>
                  <StockRequestStatus status={stock.status} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>
                  {stock?.paymentCreatedAt ? moment(stock.paymentCreatedAt).format('DD/MM/YYYY') : '-- --'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>
                  {stock.createdAt ? moment(stock.dateCreatedAt).format('DD/MM/YYYY') : '-- --'}
                </Typography>
              </TableCell>
              <TableCell>
                <Stack flexDirection='row'>
                  <Tooltip title='Edit'>
                    <IconButton
                      onClick={() => {
                        navigate(RoutesNames.STOCK_REQUEST_CRUD, {
                          state: { stockRequestSelected: JSON.stringify(stock) },
                        })
                      }}
                    >
                      <TbPencil color={grey[900]} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Options'>
                    <IconButton
                      id='basic-button'
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={event => {
                        handleMenuAction(event, stock)
                      }}
                    >
                      <TbDotsVertical color={grey[900]} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  const handleChangeOrderBy = (event: SelectChangeEvent) => {
    setFilterBy(event.target.value as ORDER_BY_TYPE)
    setSearchStockRequest('')
    setSearchStatusStockRequest(undefined)
  }

  const handleChangeSearchStatusStockRequest = (event: SelectChangeEvent) => {
    setSearchStatusStockRequest(event.target.value as StockRequestStatus)
    setSearchStockRequest('')
  }

  useEffect(() => {
    ApiService.StockRequest.getList({
      companyId,
      setStockRequestList,
      setLoadingList,
      orderByList: [orderBy('createdAt', 'asc')],
    })
  }, [ApiService.Service.getList])

  useEffect(() => {
    if (!stockRequestListPrintSummary || (stockRequestListPrintSummary || []).length === 0) return

    const oderToPrint: any[] = []
    const taxPercent = Number(company?.taxPercent || 0)

    let paymentTotalAmt = 0
    let paymentTotalVat = 0
    let paymentTotal = 0

    let noPaymentTotalAmt = 0
    let noPaymentTotalVat = 0
    let noPaymentTotal = 0

    let hasPayment = false

    stockRequestListPrintSummary.forEach((item, index) => {
      const totalPrice = Number(item.price)
      const vat = discontPercent({ price: totalPrice, percent: taxPercent })

      const data = {
        Key: (index + 1).toString(),
        Date: moment(item.dateCreatedAt).format('DD/MM/YYYY'),
        Type: 'Product invoque (From SAP)',
        "Supplier invoice Number": item?.supplierInvoiceNumber?.toString() || '',
        "Cliente Name": item.supplierName,
        Amount: formatNumberToLibra({ value: totalPrice, showCurrency: true }),
        VAT: formatNumberToLibra({ value: vat, showCurrency: true }),
        Total: formatNumberToLibra({ value: totalPrice - vat, showCurrency: true }),
        "Payment Recived": item.isPaidOut ? 'Yes' : 'No'
      }
      if (item.isPaidOut) {
        hasPayment = true
        paymentTotalAmt = truncateByDecimalPlace({ value: paymentTotalAmt + totalPrice })
        paymentTotalVat += vat
        paymentTotal = truncateByDecimalPlace({ value: paymentTotal + (totalPrice - vat) })
      } else {
        noPaymentTotalAmt = truncateByDecimalPlace({ value: noPaymentTotalAmt + totalPrice })
        noPaymentTotalVat += vat
        noPaymentTotal = truncateByDecimalPlace({ value: noPaymentTotal + (totalPrice - vat) })
      }
      oderToPrint.push(data)
    });

    const ws = XLSX.utils.aoa_to_sheet([
      [`${company?.name || ''} - Purchase Order Summary`, '', '', '', '', '', '', '', '', '', '', 'Total paid'],
      [moment().format('dddd, MMMM Do YYYY, h:mm:ss a')],
      [""],
      [""],
      ["", '', '', '', '', '', '', '', '', '', '', 'Total unpaid'],
    ]);

    const mergeA1 = { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } };
    const mergeA2 = { s: { r: 1, c: 0 }, e: { r: 1, c: 8 } };
    const mergeA3 = { s: { r: 2, c: 0 }, e: { r: 2, c: 8 } };
    const mergeL1 = { s: { r: 0, c: 11 }, e: { r: 0, c: 13 } };
    const mergeL5 = { s: { r: 4, c: 11 }, e: { r: 4, c: 13 } };

    if (!ws['!merges']) ws['!merges'] = [];
    ws['!merges'].push(mergeA1);
    ws['!merges'].push(mergeA2);
    ws['!merges'].push(mergeA3);
    ws['!merges'].push(mergeL1);
    ws['!merges'].push(mergeL5);

    XLSX.utils.sheet_add_json(ws, [{
      "Total Amt": formatNumberToLibra({ value: paymentTotalAmt, showCurrency: true }),
      "Total VAT": formatNumberToLibra({ value: paymentTotalVat, showCurrency: true }),
      "Total": formatNumberToLibra({ value: paymentTotal, showCurrency: true })
    },
    ], { origin: "L2" });

    XLSX.utils.sheet_add_json(ws, [
      {
        "Total Amt": formatNumberToLibra({ value: noPaymentTotalAmt, showCurrency: true }),
        "Total VAT": formatNumberToLibra({ value: noPaymentTotalVat, showCurrency: true }),
        "Total": formatNumberToLibra({ value: noPaymentTotal, showCurrency: true })
      },
    ], { origin: "L6" });

    XLSX.utils.sheet_add_json(ws, oderToPrint, { origin: "A4" });

    const styleHeader = {
      fill: { bgColor: { rgb: '00000000' } },
      font: { sz: 13, color: { rgb: "FFFFFFFF" } },
    };
    const styleHeaderTotal = {
      fill: { bgColor: { rgb: '00000000' } },
      font: { sz: 13, color: { rgb: "FFFFFFFF" } },
    };
    const styleHeaderTotalBorder = {
      border: {
        right: {
          style: "thin",
          color: "000000"
        },
        left: {
          style: "thin",
          color: "000000"
        },
        top: {
          style: "thin",
          color: "000000"
        },
        bottom: {
          style: "thin",
          color: "000000"
        }
      }
    };

    ws.A1.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 16, bold: true, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.A2.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 13, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.A3.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 13, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };

    if (hasPayment) {
      ws.A4.s = styleHeader;
      ws.B4.s = styleHeader;
      ws.C4.s = styleHeader;
      ws.D4.s = styleHeader;
      ws.E4.s = styleHeader;
      ws.F4.s = styleHeader;
      ws.G4.s = styleHeader;
      ws.H4.s = styleHeader;
      ws.I4.s = styleHeader;
    }

    ws.L1.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 16, bold: true, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.L2.s = styleHeaderTotal;
    ws.L3.s = styleHeaderTotalBorder;
    ws.M2.s = styleHeaderTotal;
    ws.M3.s = styleHeaderTotalBorder;
    ws.N2.s = styleHeaderTotal;
    ws.N3.s = styleHeaderTotalBorder;

    ws.L5.s = { fill: { bgColor: { rgb: '00000000' } }, font: { sz: 16, bold: true, color: { rgb: "FFFFFFFF" } }, alignment: { horizontal: 'center' } };
    ws.L6.s = styleHeaderTotal;
    ws.L7.s = styleHeaderTotalBorder;
    ws.M6.s = styleHeaderTotal;
    ws.M7.s = styleHeaderTotalBorder;
    ws.N6.s = styleHeaderTotal;
    ws.N7.s = styleHeaderTotalBorder;

    const wscols = [
      { wch: 6 },
      { wch: 14 },
      { wch: 26 },
      { wch: 25 },
      { wch: 30 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 16 },

      { wch: 2 },
      { wch: 2 },

      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    ws['!cols'] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `purchase_order_summary_${moment().valueOf()}.xlsx`);

    setIsOpenPrintSummary(false)
    setLoadingPrintSummary(false)
    formikFilterSummary.resetForm()

  }, [stockRequestListPrintSummary])

  return (
    <Box>
      <Breadcrumb pageTitle='Stock' menu={breadcrumbList}>
        <Stack display='flex' alignItems='center'>
          <Button
            startIcon={<TbPlus />}
            color='btnSecondary'
            variant='contained'
            size='small'
            onClick={() => {
              navigate(RoutesNames.STOCK_REQUEST_CRUD)
            }}
            disableElevation
          >
            New
          </Button>
        </Stack>
      </Breadcrumb>

      <BoxShadow>
        <TableContainer sx={{ height: BOX_HEIGHT }}>
          <Stack
            display='flex'
            flex={1}
            flexDirection='row'
            paddingY={2}
            pr={1}
            pl={2}
          >
            <Stack display='flex' flexDirection='row' flex={1}>
              <Stack display='flex' flex={1} flexDirection='row'>
                <FormControl size='small'>
                  <InputLabel id='filterBy'>Filter by</InputLabel>
                  <Select
                    labelId='filterBy'
                    value={filterBy}
                    label='Filter by'
                    onChange={handleChangeOrderBy}
                    sx={{ width: 160, mr: 1 }}
                  >
                    {Object.entries(ENUM_ORDER_BY).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {filterBy !== 'STATUS'
                  ?
                  (
                    <TextField
                      size='small'
                      label='Search'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <TbSearch color={grey[900]} />
                          </InputAdornment>
                        ),
                      }}
                      value={searchStockRequest}
                      onChange={event => {
                        setSearchStockRequest(event.target.value)
                      }}
                      sx={{ width: 240 }}
                    ></TextField>
                  )
                  : (
                    <FormControl size='small'>
                      <InputLabel id='filterBy'>Status</InputLabel>
                      <Select
                        labelId='filterBy'
                        value={searchStatusStockRequest}
                        label='Filter by'
                        onChange={handleChangeSearchStatusStockRequest}
                        sx={{ width: 200, mr: 1 }}
                      >
                        <MenuItem value=''>
                          <em>-- Select --</em>
                        </MenuItem>
                        {Object.entries(EnumStockRequestStatus).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )
                }
              </Stack>
              <Stack>
                <Tooltip title='Print invoice summary '>
                  <IconButton onClick={() => { setIsOpenPrintSummary(true) }}>
                    <TbFileTypeXls color={grey[900]} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>

          </Stack>
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell width={80}>
                  <Typography variant='subtitle2'>Key</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Title</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Supplier</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>Part</Typography>
                </TableCell>
                <TableCell width={100}>
                  <Typography variant='subtitle2'>Quantity</Typography>
                </TableCell>
                <TableCell width={100}>
                  <Typography variant='subtitle2'>Price</Typography>
                </TableCell>
                <TableCell width={130}>
                  <Typography variant='subtitle2'>Status</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Payment dt</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Created at</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
        </TableContainer>
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.STOCK_REQUEST_CRUD, {
              state: { stockRequestSelected: JSON.stringify(stockRequestSelected) },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
          disabled={stockRequestSelected?.isPaidOut}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <DialogConfirm
        title='Filter'
        description={`Choose the data you want to filter`}
        open={isOpenPrintSummary}
        onCloseDialog={() => {
          setIsOpenPrintSummary(false)
          formikFilterSummary.resetForm()
        }}
        btnOkProps={{
          onClick: () => { formikFilterSummary.submitForm() },
          loading: loadingPrintSummary,
        }}
        btnOkTitle='Filter'
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            formikFilterSummary.resetForm()
            setIsOpenPrintSummary(false)
          },
        }}
      >
        <FormikProvider value={formikFilterSummary}>
          <Form>
            <Grid container spacing={2} rowSpacing={3} mt={0.5}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={['DatePicker']}>
                    <FormControl fullWidth>
                      <DatePicker
                        slotProps={{
                          textField: {
                            name: 'startDate',
                            error: formikFilterSummary.touched.startDate && Boolean(formikFilterSummary.errors.startDate),
                            helperText: (formikFilterSummary.touched.startDate && Boolean(formikFilterSummary.errors.startDate)) ? formikFilterSummary.errors.startDate?.toString() || '' : ''
                          },
                          field: {
                            clearable: true,
                            onClear: () => { formikFilterSummary.setFieldValue('startDate', null) },
                          },
                        }}
                        format='DD/MM/YYYY'
                        label="Start date"
                        name='startDate'
                        value={formikFilterSummary.values.startDate ? moment(formikFilterSummary.values.startDate) : null}
                        onChange={(date) => {
                          if (!date) {
                            formikFilterSummary.setFieldValue('startDate', null, false)
                            formikFilterSummary.setFieldTouched('startDate', true)
                          } else {
                            formikFilterSummary.setFieldValue('startDate', date.valueOf())
                            formikFilterSummary.setFieldValue('endDate', null)
                          }
                        }}
                      />
                    </FormControl>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={['DatePicker']}>
                    <FormControl fullWidth>
                      <DatePicker
                        slotProps={{
                          textField: {
                            name: 'endDate',
                            error: formikFilterSummary.touched.endDate && Boolean(formikFilterSummary.errors.endDate),
                            helperText: (formikFilterSummary.touched.endDate && Boolean(formikFilterSummary.errors.endDate)) ? formikFilterSummary.errors.endDate?.toString() || '' : ''
                          },
                          field: {
                            clearable: true,
                            onClear: () => { formikFilterSummary.setFieldValue('endDate', null) },
                          },
                        }}
                        format='DD/MM/YYYY'
                        label="End date"
                        name='endDate'
                        value={formikFilterSummary.values.endDate ? moment(formikFilterSummary.values.endDate) : null}
                        onChange={(date) => {
                          if (!date) {
                            formikFilterSummary.setFieldValue('endDate', null, false)
                            formikFilterSummary.setFieldTouched('endDate', true)
                          } else {
                            formikFilterSummary.setFieldValue('endDate', date.valueOf())
                          }
                        }}
                        minDate={formikFilterSummary?.values.startDate ? moment(formikFilterSummary?.values.startDate) : undefined}
                      />
                    </FormControl>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogConfirm>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${stockRequestSelected?.title}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteStock()
          },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />
    </Box>
  )
}
