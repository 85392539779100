/* eslint-disable @typescript-eslint/no-unused-vars */
// ADICIONAR NOVAS CORES
// https://mui.com/material-ui/customization/palette/#adding-new-colors

import {
  green,
  blueGrey,
  red,
  blue,
  amber,
  indigo,
  deepOrange,
  orange,
  brown,
  teal,
  grey,
  yellow,
  cyan,
} from '@mui/material/colors'
// import { ListItemButtonClasses } from '@mui/material/ListItemButton'
import { createTheme } from '@mui/material/styles'

const BORDER_RADIUS = 8
const INPUT_FONT_SIZE = 14
const PRIMARY_COLOR = grey
const SECONDARY_COLOR = cyan

declare module '@mui/material/styles' {
  interface Palette {
    btnPrimary: Palette['primary']
    btnPrimaryFill: Palette['primary']
    btnSecondary: Palette['primary']
    btnSecondaryFill: Palette['primary']
    btnSuccess: Palette['primary']
    btnError: Palette['primary']
    btnInfo: Palette['primary']
    btnWarning: Palette['primary']
  }

  interface PaletteOptions {
    btnPrimary?: PaletteOptions['primary']
    btnPrimaryFill?: PaletteOptions['primary']
    btnSecondary?: PaletteOptions['primary']
    btnSecondaryFill?: PaletteOptions['primary']
    btnSuccess?: PaletteOptions['primary']
    btnError?: PaletteOptions['primary']
    btnInfo?: PaletteOptions['primary']
    btnWarning?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    btnPrimary: true
    btnPrimaryFill: true
    btnSecondary: true
    btnSecondaryFill: true
    btnSuccess: true
    btnError: true
    btnInfo: true
    btnWarning: true
  }
}

let theme = createTheme({
  shape: {
    borderRadius: BORDER_RADIUS,
  },

  palette: {
    primary: {
      main: PRIMARY_COLOR[900],
    },
    secondary: {
      main: SECONDARY_COLOR[500],
    },
  },
  typography: {
    fontFamily: 'Sora',
    fontWeightLight: 100,
    fontWeightRegular: 300,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },

  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 15,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },

    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: INPUT_FONT_SIZE,
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: INPUT_FONT_SIZE,
        },
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${grey[200]}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${PRIMARY_COLOR[200]}`,
          },
        },
      },
    },

    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       // backgroundColor: '#f7f7f8',
    //       '& .MuiInputBase-input': {
    //         fontSize: '10px', // this is the default mui body2 font-size
    //         lineHeight: '40px', // and any other styles you want...
    //       },

    //       '& .MuiOutlinedInput-notchedOutline': {
    //         // borderWidth: 0,
    //         border: '1px solid #e8ecee',
    //       },
    //       '& .MuiOutlinedInput-input': {
    //         // backgroundColor: '#f7f7f8',
    //         borderRadius,
    //       },
    //       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //         border: '1px solid #DEDFE4',
    //       },
    //     },
    //   },
    // },
    // MuiList: {
    //   styleOverrides: {
    //     root: {
    //       // '&& .Mui-selected, && .Mui-selected:hover': {
    //       //   backgroundColor: green[50],
    //       //   '&, & .MuiListItemIcon-root': {
    //       //     color: green[500],
    //       //   },
    //       // },
    //       // '& .MuiListItemButton-root:hover': {
    //       //   backgroundColor: grey[100],
    //       //   '&, & .MuiListItemIcon-root': {
    //       //     color: 'yellow',
    //       //   },
    //       // },
    //       '& .MuiListItemButton-root': {
    //         BORDER_RADIUS,
    //       },
    //     },
    //   },
    // },

    MuiTable: {
      styleOverrides: {
        root: {
          zIndex: 0,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
          height: 54,
          '& .MuiTableCell-head': {
            height: 54,
            backgroundColor: grey[100],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomStyle: 'dashed',
        },
      },
    },
  },
})

theme = createTheme(theme, {
  palette: {
    btnPrimary: theme.palette.augmentColor({
      color: {
        main: blueGrey[50],
        light: blueGrey[50],
        dark: blueGrey[100],
        contrastText: blueGrey[900],
      },
      name: 'btnPrimary',
    }),
    btnPrimaryFill: theme.palette.augmentColor({
      color: {
        main: PRIMARY_COLOR[900],
        light: PRIMARY_COLOR[900],
        dark: PRIMARY_COLOR[800],
      },
      name: 'btnPrimary',
    }),
    btnSecondary: theme.palette.augmentColor({
      color: {
        main: SECONDARY_COLOR[50],
        light: SECONDARY_COLOR[50],
        dark: SECONDARY_COLOR[100],
        contrastText: SECONDARY_COLOR[900],
      },
      name: 'btnSecondary',
    }),
    btnSecondaryFill: theme.palette.augmentColor({
      color: {
        main: SECONDARY_COLOR[500],
        light: SECONDARY_COLOR[500],
        dark: SECONDARY_COLOR[400],
      },
      name: 'btnSecondaryFill',
    }),
    btnSuccess: theme.palette.augmentColor({
      color: {
        main: green[50],
        light: green[50],
        dark: green[100],
        contrastText: green[700],
      },
      name: 'btnSuccess',
    }),
    btnError: theme.palette.augmentColor({
      color: {
        main: red[50],
        light: red[50],
        dark: red[100],
        contrastText: red[900],
      },
      name: 'btnError',
    }),
    btnInfo: theme.palette.augmentColor({
      color: {
        main: amber[50],
        light: amber[50],
        dark: amber[100],
        contrastText: yellow[900],
      },
      name: 'btnInfo',
    }),
    btnWarning: theme.palette.augmentColor({
      color: {
        main: blue[50],
        light: blue[50],
        dark: blue[100],
        contrastText: blue[700],
      },
      name: 'btnWarning',
    }),
  },
})

export { theme }
