import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TbCoins, TbEdit } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom'

import { where } from 'firebase/firestore';
import { Form, FormikProvider, useFormik } from 'formik'
import moment from 'moment'

import { BoxShadow, Breadcrumb, FormikTextField, FormikSwitch, DialogConfirm, PhotoUpload } from '@/components'
import { useGlobalState } from '@/context';
import { useStorage } from '@/hooks/useStorage';
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { formatNumberToLibra } from '@/utils/currency';
import { setNameToFile } from '@/utils/file';
import { getPathFileCompanyClient } from '@/utils/storage';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material'

import { validationSchema } from './validationSchema';

const BOX_HEIGHT = 472
const PHOTO_NAME = 'profile'

const INITIAL_VALUES: Omit<Client, 'id' | 'createdAt' | 'updatedAt' | 'dateCreatedAt'> = {
  fullname: '',
  email: '',
  emailSecondary: '',
  phoneNumber: '',
  phoneNumberSecondary: '',
  address: '',
  addressDelivery: '',
  photoURL: '',
  photoName: '',
  isActive: true,
}

export const ClientCrud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ company }, dispatch] = useGlobalState()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)

  const [currentTab, setCurrentTab] = useState<number>(0)

  const locationStateClientSelected = location.state?.clientSelected ? JSON.parse(location.state?.clientSelected) : undefined
  const [clientSelected, setClientSelected] = useState<Client | undefined>(locationStateClientSelected)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const [creditSelected, setCreditSelected] = useState<ClientCredit | undefined>(undefined)
  const { startUpload } = useStorage()

  const [removePhotoStorage, setRemovePhotoStorage] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [loadingDeleteCredit, setLoadingDeleteCredit] = useState<boolean>(false)
  const [loadingClientCreditList, setLoadingClientCreditList] = useState<boolean>(false)
  const [clientCreditList, setClientCreditList] = useState<ClientCredit[]>([])
  const companyId = useMemo(() => company?.id || '', [])
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Client',
      title: 'Clients',
      href: RoutesNames.CLIENT,
    },
    {
      id: 'cleint-crud',
      title: clientSelected ? 'Updating' : 'New',
      isCurrentPage: true,
    },
  ]

  const resetForm = () => {
    formik.resetForm()
    setFileToUpload(undefined)
    setClientSelected(undefined)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoadingSave(true)

      if (!clientSelected) {
        const clientId = await ApiService.Client.save({
          companyId,
          values
        })

        if (fileToUpload) {
          startUpload({
            file: fileToUpload,
            filePath: getPathFileCompanyClient(companyId, clientId),
            fileName: PHOTO_NAME,
            onSuccess(photoURL) {
              ApiService.Client.update({
                companyId,
                clientId,
                values: { photoURL, photoName: setNameToFile(fileToUpload, PHOTO_NAME) }
              })
              dispatch({
                type: 'SET_SNACKBAR',
                data: { message: 'Save successfull' }
              })
              resetForm()
              setLoadingSave(false)
            },
            onError() {
              dispatch({
                type: 'SET_SNACKBAR',
                data: { message: 'Error saving image', alertSeverity: 'error' }
              })
              setLoadingSave(false)
            }
          })
        } else {
          resetForm()
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Save successfull' }
          })
          setLoadingSave(false)
        }
        return
      }

      if (removePhotoStorage && !fileToUpload) {
        await ApiService.File.deleteFile({
          pathFile: `${getPathFileCompanyClient(companyId, clientSelected.id)}/${clientSelected.photoName}`,
        })
      }

      const updateClient = async (photoURL: string) => {
        await ApiService.Client.update({
          companyId,
          clientId: clientSelected.id,
          values: { ...values, photoURL, photoName: fileToUpload ? setNameToFile(fileToUpload, PHOTO_NAME) : '' }
        }).then(() => {
          resetForm()
          navigate(RoutesNames.CLIENT)
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Save successfull' }
          })
        }).catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error on update', alertSeverity: 'error' }
          })
        }).finally(() => {
          setLoadingSave(false)
        })
      }

      if (fileToUpload) {
        startUpload({
          file: fileToUpload,
          filePath: getPathFileCompanyClient(companyId, clientSelected.id),
          fileName: PHOTO_NAME,
          async onSuccess(photoURL) {
            await updateClient(photoURL)
          },
          onError() {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Error saving image', alertSeverity: 'error' }
            })
          }
        })
      } else {
        await updateClient('')
      }
    },
  })

  const handleDeleteClient = async () => {
    if (!clientSelected) return
    setLoadingDelete(true)
    await ApiService.Client.remove({ companyId, clientId: clientSelected.id })
      .then(async () => {
        if (clientSelected.photoURL && clientSelected.photoName) {
          await ApiService.Client.removePhoto({
            companyId,
            clientId: clientSelected.id,
            photoName: clientSelected.photoName,
          })
        }
        resetForm()
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Client deleted' }
        })
        navigate(RoutesNames.CLIENT)
      }).catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Client was not deleted' }
        })
      }).finally(() => {
        setLoadingDelete(false)
      })
  }

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const RenderCredits = () => {
    if (loadingClientCreditList) {
      return (
        <Stack display='flex' width='100%' height={200} justifyContent='center' alignItems='center'>
          <CircularProgress color='inherit' />
        </Stack>
      )
    }

    if (clientSelected && clientCreditList.length === 0) {
      return (
        <Stack display='flex'>
          <Typography variant='subtitle2' textAlign='center'>There is no credits</Typography>
        </Stack>
      )
    }

    if (!clientSelected && clientCreditList.length === 0) return null

    return (
      <List>
        {clientCreditList.map((item, index) => (
          <>
            <ListItem disablePadding>
              <Stack display='flex' flexDirection='row' flex={1} >
                <Stack flexDirection='column' display='flex' flex={1} pr={2}>
                  <Typography variant="body2" mb={0.5}>
                    Date:   {moment(item.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                  </Typography>

                  <Typography variant="body2" color="textSecondary" mb={0.5}>
                    Observation: {item.observation}
                  </Typography>

                  <Stack display='flex' flexDirection='row'>
                    <Typography variant="subtitle2" >
                      Amount: {formatNumberToLibra({ value: item.amount, showCurrency: true })}
                    </Typography>
                    {/* <Typography variant="caption" mb={0.5} marginLeft={4}>ORDER ID: {item.orderId}</Typography> */}
                  </Stack>
                </Stack>
                <Stack display='flex' flexDirection='row'  >
                  <FormControlLabel
                    control={<Switch size='small' edge="end" checked={item.isUsed} />}
                    labelPlacement="start"
                    label={
                      <Typography variant="subtitle2">
                        {item.isUsed ? 'Credit used' : 'Credit not used'}
                      </Typography>
                    }
                  />
                </Stack>
              </Stack>

            </ListItem >
            {(index + 1) < clientCreditList.length && <Divider sx={{ my: 1.5 }} />}
          </>
        ))}
      </List>
    )
  }

  const handleDeleteCredit = async () => {
    if (!clientSelected) return
    setLoadingDeleteCredit(true)

    await ApiService.ClientCredits.remove({ companyId, clientCreditId: creditSelected?.id || '' })
      .then(async () => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Credit deleted' }
        })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Credit was not deleted' }
        })
      }).finally(() => {
        setCreditSelected(undefined)
        setLoadingDeleteCredit(false)
      })
  }

  useEffect(() => {
    if (!clientSelected) return
    setInitialValues(clientSelected)
    ApiService.ClientCredits.getList({
      companyId,
      setClientCreditList: creditList => {
        if (!creditList || creditList.length === 0) return
        setClientCreditList(creditList.sort((a, b) => b.createdAt - a.createdAt))
      },
      setLoadingList: setLoadingClientCreditList,
      whereList: [
        where('clientId', '==', clientSelected.id),
      ]
    })
  }, [clientSelected])

  return (
    <Box>
      <Breadcrumb
        pageTitle={
          clientSelected ? 'Update of data' : 'Registration'
        }
        menu={breadcrumbList}
      />

      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>
                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  alignItems='center'
                  justifyContent='center'
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    alignItems='center'
                    justifyContent='center'
                    paddingTop='20px'
                  >
                    <PhotoUpload
                      isDisableUpload
                      inputFileRef={inputFileRef}
                      fileToUpload={fileToUpload}
                      fileUrl={formik.values.photoURL}
                      setFileToUpload={(files) => {
                        if (!files) return
                        setFileToUpload(files[0])
                      }}
                      handleDeletedImage={() => {
                        setFileToUpload(undefined)

                        if (clientSelected?.photoURL) {
                          setRemovePhotoStorage(true)
                          formik.setFieldValue('photoURL', '')
                          formik.setFieldValue('photoName', '')
                        }
                      }}
                    />
                  </Stack>

                  <FormikSwitch size='small' name='isActive' label='Enable client' />

                  {clientSelected && (
                    <>
                      <Box mb={2} />
                      <LoadingButton
                        color='btnError'
                        variant='contained'
                        disableElevation
                        onClick={() => { setIsOpenConfirmDelete(true) }}
                      >
                        Delete
                      </LoadingButton>
                    </>
                  )}
                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack minHeight={BOX_HEIGHT} padding={3} paddingTop={1}>

                  <Stack display='flex' flex={1}>
                    <Tabs
                      value={currentTab}
                      onChange={handleChangeTabs}
                      selectionFollowsFocus
                      style={{ maxHeight: 48 }}
                      variant='scrollable'
                    >
                      <Tab
                        icon={<TbEdit size={24} />}
                        iconPosition='start'
                        label={<Typography variant='body2'>Data</Typography>}
                        sx={{ minHeight: 48 }}
                      />
                      <Tab
                        icon={<TbCoins size={24} />}
                        iconPosition='start'
                        label={<Typography variant='body2'>Credits notes</Typography>}
                        sx={{ minHeight: 48 }}
                      />
                    </Tabs>

                    <Box mb={3} />

                    {currentTab === 0 && (
                      <Stack display='flex' flex={1}>
                        <Box>
                          <Grid container spacing={2} rowSpacing={3}>
                            <Grid item xs={12}>
                              <FormikTextField name='fullname' fullWidth label='Full name' />
                            </Grid>

                            <Grid item xs={6}>
                              <FormikTextField name='email' fullWidth label='E-mail' />
                            </Grid>

                            <Grid item xs={6}>
                              <FormikTextField name='emailSecondary' fullWidth label='E-mail secondary' />
                            </Grid>

                            <Grid item xs={6}>
                              <FormikTextField name='phoneNumber' fullWidth label='Phone number' />
                            </Grid>

                            <Grid item xs={6}>
                              <FormikTextField name='phoneNumberSecondary' fullWidth label='Phone number secondary' />
                            </Grid>

                            <Grid item xs={6}>
                              <FormikTextField name='address' fullWidth label='Address' />
                            </Grid>

                            <Grid item xs={6}>
                              <FormikTextField name='addressDelivery' fullWidth label='Address delivery' />
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    )}
                    {currentTab === 1 && RenderCredits()}
                  </Stack>

                  <Stack flexDirection='row' justifyContent='flex-end' >
                    <Button
                      color='btnSecondary'
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        navigate(RoutesNames.CLIENT)
                      }}
                    >
                      Back
                    </Button>
                    {currentTab === 0 && (
                      <>
                        <Box mr={2} />
                        <LoadingButton
                          variant='contained'
                          color='btnPrimaryFill'
                          disableElevation
                          type='submit'
                          loading={loadingSave}
                        >
                          {clientSelected ? 'Save update' : 'Save client'}
                        </LoadingButton>
                      </>
                    )}
                  </Stack>
                </Stack>

              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${clientSelected?.fullname}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => { handleDeleteClient() },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => { setIsOpenConfirmDelete(false) },
        }}
      />

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${formatNumberToLibra({ value: creditSelected?.amount || 0, showCurrency: true })}" ?`}
        open={!!creditSelected}
        onCloseDialog={() => {
          setCreditSelected(undefined)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteCredit()
          },
          loading: loadingDeleteCredit,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setCreditSelected(undefined)
          },
        }}
      />

    </Box>
  )
}
