import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
`

interface ContainerHeaderProp {
  isSmallHeader?: boolean
}
export const ContainerHeader = styled.div<ContainerHeaderProp>`
  width: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  backdrop-filter: blur(3px);
  height: ${({ isSmallHeader }) => (isSmallHeader ? '60px' : '80px')};
  z-index: 1;
`
