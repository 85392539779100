import { doc, setDoc } from 'firebase/firestore'

import { FirebaseCollection } from '@/constants/firebase-collection'
import { firestore } from '@/services/firebase'

interface AssignWorkerToDoToInProgressProps {
  companyId: string
  serviceId: string
  values: Pick<Service, 'status' | 'assignServiceWorkerToDo'>
}
export const assignWorkerToDoToInProgress = async ({
  companyId,
  serviceId,
  values,
}: AssignWorkerToDoToInProgressProps) => {
  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_SERVICE,
      serviceId,
    ),
    {
      ...values,
      assignServiceWorkerToDoCreatedAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    },
    { merge: true },
  )
}

interface ChangeStatusToInProgressProps {
  companyId: string
  serviceId: string
  values: Pick<Service, 'status' | 'workHours' | 'totalPrice' | 'orderId'>
}
export const changeStatusInProgressToAwaitingApproval = async ({
  companyId,
  serviceId,
  values,
}: ChangeStatusToInProgressProps) => {
  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_SERVICE,
      serviceId,
    ),
    {
      ...values,
      assignServiceWorkerInProgressCreatedAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    },
    { merge: true },
  )
}

interface AssignWorkerApprovedToStartWork {
  companyId: string
  serviceId: string
  values: Pick<Service, 'status' | 'assignServiceWorkerStartWork'>
}
export const assignWorkerApprovedToStartWork = async ({
  companyId,
  serviceId,
  values,
}: AssignWorkerApprovedToStartWork) => {
  const dateTime = new Date().getTime()

  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_SERVICE,
      serviceId,
    ),
    {
      ...values,
      assignServiceWorkerStartWorkCreatedAt: dateTime,
      updatedAt: dateTime,
    },
    { merge: true },
  )
}

interface AssignWorkerStartWorkToWorkFinishProps {
  companyId: string
  serviceId: string
  values: Pick<Service, 'status'>
}
export const assignWorkerStartWorkToWorkFinish = async ({
  companyId,
  serviceId,
  values,
}: AssignWorkerStartWorkToWorkFinishProps) => {
  const dateTime = new Date().getTime()

  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_SERVICE,
      serviceId,
    ),
    {
      ...values,
      assignServiceWorkerWorkFinishCreatedAt: dateTime,
      updatedAt: dateTime,
    },
    { merge: true },
  )
}

interface AssignWorkerWorkFinishToDeliveredProps {
  companyId: string
  serviceId: string
  values: Pick<Service, 'status' | 'assignServiceWorkerDelivered'>
}
export const assignWorkerWorkFinishToDelivered = async ({
  companyId,
  serviceId,
  values,
}: AssignWorkerWorkFinishToDeliveredProps) => {
  const dateTime = new Date().getTime()

  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_SERVICE,
      serviceId,
    ),
    {
      ...values,
      assignServiceWorkerDeliveredCreatedAt: dateTime,
      updatedAt: dateTime,
    },
    { merge: true },
  )
}
