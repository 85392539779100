
import React, { useMemo } from 'react'
import { IconType } from 'react-icons/lib';
import { TbAward, TbCircleCheck, TbCircleX, TbSettings2, TbTargetArrow, } from 'react-icons/tb';

import { BoxShadow } from '@/components';
import { Grid, Stack, Typography } from '@mui/material'
import { cyan, deepOrange, grey, lime, } from '@mui/material/colors';

interface CardProps {
    title?: string
    value: number
    countActive: number
    countInactive: number
    color: Record<number, string>
    icon: IconType
}
export const CardItem = ({ title, value, countActive, countInactive, color, icon: Icon }: CardProps) => {

    const getCount = (valueCount: number) => {
        if (valueCount.toString().length === 1 && valueCount) {
            return `0${valueCount}`
        }
        return valueCount
    }

    return (
        <BoxShadow>
            <Stack
                borderRadius={2}
                display='flex'
                height={164}
                padding={2}
                paddingRight={0}
                flexDirection='row'
                position='relative'
                sx={{ overflow: 'hidden' }}
            >
                <Stack flexDirection='column' justifyContent='space-between'>
                    {title && (
                        <Typography variant='body2'>
                            {title}
                        </Typography>
                    )}
                    <Typography variant='h6'  >
                        {getCount(value)}
                    </Typography>

                    <Stack display='flex' flexDirection='column'>
                        <Stack display='flex' flexDirection='row'>
                            <TbCircleCheck color={color[500]} />
                            <Typography variant='caption' ml={1} flexDirection='row' display='flex'>
                                Active:<Typography variant='caption' fontWeight='bold' ml={0.5}>{getCount(countActive)}</Typography>
                            </Typography>
                        </Stack>
                        <Stack display='flex' flexDirection='row'>
                            <TbCircleX color={color[500]} />
                            <Typography variant='caption' ml={1} flexDirection='row' display='flex'>
                                Inactive:<Typography variant='caption' fontWeight='bold' ml={0.5}>{getCount(countInactive)}</Typography>
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack position='absolute' top={-13} right={-16} >
                    <Icon
                        size={190}
                        color={grey[50]}
                    />
                </Stack>
            </Stack>
        </BoxShadow>
    )
}
interface CardsProps {
    serviceList: Service[]
    clientList: Client[]
}
export const Cards = ({ serviceList, clientList }: CardsProps) => {
    const serviceListActive = useMemo(() => serviceList?.filter((service) => service.isActive), [serviceList])
    const serviceListInactive = useMemo(() => serviceList?.filter((service) => !service.isActive), [serviceList])
    const clientListActive = useMemo(() => clientList?.filter((service) => service.isActive), [clientList])
    const clientListInactive = useMemo(() => clientList?.filter((service) => !service.isActive), [clientList])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <CardItem
                    title='Total of services'
                    value={serviceList.length}
                    countActive={serviceListActive.length}
                    countInactive={serviceListInactive.length}
                    icon={TbAward}
                    color={deepOrange}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <CardItem
                    title='Total of clients'
                    value={clientList.length}
                    countActive={clientListActive.length}
                    countInactive={clientListInactive.length}
                    icon={TbTargetArrow}
                    color={cyan}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <CardItem
                    title='Stock of parts'
                    value={0}
                    countActive={0}
                    countInactive={0}
                    icon={TbSettings2}
                    color={lime}
                />
            </Grid>
        </Grid>
    )
}
