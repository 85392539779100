import React from 'react'

import { EnumStockRequestStatus } from '@/constants/stock-request'
import { Stack, Typography, } from '@mui/material'
import { green, amber, blue } from '@mui/material/colors'
import { Variant } from '@mui/material/styles/createTypography'

interface StatusProps {
    status: StockRequestStatus
    titleVariant?: Variant
    isTitleBold?: boolean
}

export const StatusServiceFlowColors: Record<StockRequestStatus, Record<string, Record<number, string>>> = {
    'DELIVERED_FULL': {
        color: green,
    },
    'DELIVERED_INCOMPLETE': {
        color: amber,
    },
    'REQUEST': {
        color: blue,
    },
}

export const StockRequestStatus = ({ status, titleVariant = 'body2', isTitleBold }: StatusProps) => (
    <Stack
        sx={{
            backgroundColor: StatusServiceFlowColors[status].color[100],
            height: 26,
            borderRadius: 1,
            paddingX: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Typography
            noWrap
            variant={titleVariant}
            color={StatusServiceFlowColors[status].color[900]}
            sx={{
                ...(isTitleBold ? { fontWeight: 'bold' } : {})
            }}>
            {EnumStockRequestStatus[status]}
        </Typography>
    </Stack >
)

