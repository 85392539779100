import * as React from 'react'

import { useGlobalState } from '@/context'
import BackdropUI from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export const Backdrop = () => {
  const [{ isLoadingApp }] = useGlobalState()

  return (
    <div>
      <BackdropUI
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoadingApp}
      >
        <CircularProgress color='inherit' />
      </BackdropUI>
    </div>
  )
}
