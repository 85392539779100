import React from 'react'

import { Box, SxProps } from '@mui/material'

interface BoxShadowProps extends React.PropsWithChildren {
    height?: number
    sx?: SxProps
}
export const BoxShadow = ({
    children, ...sx }: BoxShadowProps) => (
    <Box
        sx={{
            boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;',
            borderRadius: 2,
            backgroundColor: 'white',
            overflow: 'hidden',
            ...sx
        }}
    >
        {children}
    </Box>
)
