import * as React from 'react';

import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogConfirmProps extends DialogProps {
    title: string
    description?: string

    btnOkTitle?: string
    btnOkProps?: LoadingButtonProps

    btnNoTitle?: string
    btnNoProps?: LoadingButtonProps

    onCloseDialog: () => void

    children?: React.ReactNode
}
export const DialogConfirm = ({
    title,
    description,

    btnOkTitle = 'Confirm',
    btnOkProps,

    btnNoTitle = 'Cancel',
    btnNoProps,

    maxWidth = 'xs',

    open,

    onCloseDialog,

    children,
}: DialogConfirmProps) => (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCloseDialog}
        maxWidth={maxWidth}
        fullWidth
        sx={{ backdropFilter: "blur(8px)" }}
        PaperProps={{ sx: { borderRadius: 2 } }}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{description}</DialogContentText>
            {children}
        </DialogContent>

        <DialogActions sx={{ padding: 2 }}>
            <LoadingButton disableElevation {...btnNoProps}>
                {btnNoTitle}
            </LoadingButton>
            <Box mr={0} />
            <LoadingButton variant='contained' disableElevation {...btnOkProps}>
                {btnOkTitle}
            </LoadingButton>
        </DialogActions>
    </Dialog>
)