import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { orderBy } from 'firebase/firestore';
import { Form, FormikProvider, useFormik } from 'formik'

import { BoxShadow, Breadcrumb, FormikTextField, PhotoUpload, FormikSelectField } from '@/components'
import { FormikSelectFieldMenu } from '@/components/formik-select-field';
import { EnumRoles } from '@/constants/roles';
import { useGlobalState } from '@/context';
import { useStorage } from '@/hooks/useStorage';
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { setNameToFile } from '@/utils/file';
import { getPathFileUserProfile } from '@/utils/storage';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/material'

import { validationSchema } from './validationSchema';

const BOX_HEIGHT = 472
const PHOTO_NAME = 'user_profile'

interface InitialValues {
  displayName: string
  email: string
  companyId: string
  companyName: string
  roles: string
  photoURL: string
  photoName: string
  password: string
  passwordConfirm: string
}

const INITIAL_VALUES: InitialValues = {
  displayName: '',
  companyId: '',
  companyName: '',
  roles: 'MANAGER',
  email: '',
  password: '',
  passwordConfirm: '',
  photoURL: '',
  photoName: '',
}

const ROLES_LIST: FormikSelectFieldMenu[] = [
  {
    value: 'MANAGER',
    title: EnumRoles.MANAGER
  },
  {
    value: 'MECHANIC_HYDRAULIC',
    title: EnumRoles.MECHANIC_HYDRAULIC
  },
  {
    value: 'MECHANIC_BUCKET',
    title: EnumRoles.MECHANIC_BUCKET
  }
]

export const UserCrud = () => {
  const navigate = useNavigate()
  const [, dispatch] = useGlobalState()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const [initialValues] = useState(INITIAL_VALUES)
  const { startUpload } = useStorage()
  const [companyList, setCompanyList] = useState<Company[] | undefined>(undefined)
  const [companyListField, setCompanyListField] = useState<FormikSelectFieldMenu[]>([])
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const BreadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Company',
      title: 'User',
      href: RoutesNames.USER,
    },
    {
      id: 'company-crud',
      title: 'New',
      isCurrentPage: true,
    },
  ]

  // const resetForm = () => {
  //   formik.resetForm()
  //   setFileToUpload(undefined)
  // }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoadingSave(true)

      await ApiService.Auth.singUp({
        email: values.email,
        password: values.password
      }).then(async (user) => {
        const userId = user?.uid || ''

        const updateUserConfig = async (photoURL: string) => {
          const userConfig = {
            photoURL,
            photoName: fileToUpload ? setNameToFile(fileToUpload, PHOTO_NAME) : '',
            companyId: values.companyId,
            companyName: values.companyName,
            roles: [values.roles as Roles],
          } as UserConfig

          await ApiService.Auth.updateUserProfile({
            photoURL: JSON.stringify(userConfig)
          }).then(() => {
            dispatch({ type: 'SET_SNACKBAR', data: { message: 'Save successfull' } })
          }).catch(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Error saving user config', alertSeverity: 'error' }
            })
          }).finally(() => {
            dispatch({ type: 'SET_LOGOUT' })
            // setLoadingSave(false)
            // setFileToUpload(undefined)
            // resetForm()
          })
        }

        if (fileToUpload) {
          startUpload({
            file: fileToUpload,
            filePath: getPathFileUserProfile(userId),
            fileName: PHOTO_NAME,
            async onSuccess(photoURL) {
              await updateUserConfig(photoURL)
            },
            onError() {
              dispatch({
                type: 'SET_SNACKBAR',
                data: { message: 'Error saving image', alertSeverity: 'error' }
              })
              setLoadingSave(false)
            }
          })
          return
        }

        await updateUserConfig('')
      }).catch((error) => {
        console.log('error: ', error);
        setLoadingSave(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error saving user', alertSeverity: 'error' }
        })
      })
    },
  })

  useEffect(() => {
    if (companyList === undefined) {
      ApiService.Company.getList({
        setCompanyList,
        setLoadingList,
        orderByList: [orderBy('name', 'asc')]
      })
    }
  }, [companyList, ApiService.Company.getList])

  useEffect(() => {
    if (companyList === undefined) return
    const companies: FormikSelectFieldMenu[] = []

    companyList?.forEach((company) => {
      companies.push({ value: company.id, title: company.name })
      setCompanyListField(companies)
    })
  }, [companyList])

  useEffect(() => {
    if (!formik.values.companyId) return
    const company = companyListField.find((item) => item.value === formik.values.companyId)
    formik.setFieldValue('companyName', company?.title)
  }, [formik.values.companyId])

  return (
    <Box>
      <Breadcrumb
        pageTitle='Registration'
        menu={BreadcrumbList}
      />

      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>

                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  alignItems='center'
                  justifyContent='center'
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    alignItems='center'
                    justifyContent='center'
                    paddingTop='20px'
                  >
                    <PhotoUpload
                      inputFileRef={inputFileRef}
                      fileToUpload={fileToUpload}
                      fileUrl={formik.values.photoURL}
                      showTextAllowFile
                      setFileToUpload={(files) => {
                        if (!files) return
                        setFileToUpload(files[0])
                      }}
                      handleDeletedImage={() => {
                        setFileToUpload(undefined)

                        // if (companySelected?.photoURL) {
                        //   setRemovePhotoStorage(true)
                        //   formik.setFieldValue('photoURL', '')
                        //   formik.setFieldValue('photoName', '')
                        // }
                      }}
                    />
                  </Stack>

                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack minHeight={BOX_HEIGHT} padding={3}>

                  <Stack display='flex' flex={1}>
                    <Grid container spacing={2} rowSpacing={3}>
                      <Grid item xs={12}>
                        <FormikTextField name='displayName' fullWidth label='DisplayName' />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikSelectField
                          name='companyId'
                          fullWidth
                          label='Companies'
                          menuList={companyListField}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikSelectField name='roles' fullWidth label='Roles permitions'
                          menuList={ROLES_LIST}
                        />
                      </Grid>
                    </Grid>

                    <Typography variant='subtitle2' mt={3} mb={2} ml={1}>
                      Authentication
                    </Typography>
                    <Grid container spacing={2} rowSpacing={3}>
                      <Grid item xs={12}>
                        <FormikTextField name='email' fullWidth label='E-mail' />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikTextField name='password' fullWidth label='Password' type='password' />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikTextField name='passwordConfirm' fullWidth label='Password Confirm' type='password' />
                      </Grid>
                    </Grid>

                  </Stack>

                  <Stack flexDirection='row' justifyContent='flex-end' >
                    <Button
                      color='btnSecondary'
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        navigate(RoutesNames.USER)
                      }}
                    >
                      Back
                    </Button>
                    <Box mr={2} />
                    <LoadingButton
                      variant='contained'
                      color='btnPrimaryFill'
                      disableElevation
                      type='submit'
                      loading={loadingSave || loadingList}
                    >
                      Save user
                    </LoadingButton>
                  </Stack>
                </Stack>
              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

    </Box>
  )
}
