import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import { useField } from "formik";

import { TextField, TextFieldProps } from "@mui/material";

type FormikTextFieldProps = {
  name: string;
  fullWidth?: boolean;
} & TextFieldProps;


interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
        prefix="£: "
        decimalScale={2}
        // decimalSeparator=","
        thousandSeparator=","
        fixedDecimalScale
      />
    );
  },
);

export const FormikTextNumericFormat = ({
  name,
  fullWidth = true,
  ...props
}: FormikTextFieldProps) => {
  const [
    field,
    meta,
    // helpers
  ] = useField(name);

  return (

    <TextField
      {...field}
      fullWidth={fullWidth}
      id={field.name}
      name={field.name}
      helperText={meta.touched ? meta.error : ""}
      error={meta.touched && Boolean(meta.error)}
      value={field.value || ""}
      onChange={field.onChange}
      {...props}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
      }}
    />
  );
};
