import styled, { css } from 'styled-components'

import { theme } from '@/styles/mui-style'
import { alpha } from '@mui/material'
import ButtonBase from '@mui/material/ButtonBase'
import { grey } from '@mui/material/colors'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  border-right: 1px dashed #eee;
  padding-left: 16px;
  flex-direction: column;
`

export const Scroll = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    opacity: 0;
    width: 16px;
    background: transparent;
  }
  ::-webkit-scrollbar:hover {
    opacity: 1;
    width: 16px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: ${grey[200]};
    border: 3px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
`

export const ContainerList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const ContainerUserLogged = styled.div`
  display: flex;
  width: 100%;
  padding: 18px 20px;
  background-color: ${grey[50]};
  border-radius: 16px;
`

export const ContainerMenu = styled.div`
  > button {
    margin-bottom: 4px;
  }
  > button:last-child {
    margin-bottom: 0;
  }
`

interface MenuProps {
  isSelected: boolean
}
export const Menu = styled(ButtonBase)<MenuProps>`
  display: flex !important;
  width: 100% !important;
  padding: 12px 10px 12px 16px !important;
  flex-direction: row !important;
  border-radius: 16px !important;

  > div {
    display: flex;
    flex: 1;
    align-items: center;

    svg {
      ${({ isSelected }) =>
        isSelected && `color: ${theme.palette.secondary.main};`}
    }
    h6 {
      ${({ isSelected }) =>
        isSelected &&
        `font-weight: 400; color: ${theme.palette.secondary.main};`}
    }
  }
  svg {
    ${({ isSelected }) =>
      isSelected && `color: ${theme.palette.secondary.main};`}
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${alpha(
            theme.palette.secondary.main,
            0.1,
          )} !important;
          > span {
            color: ${theme.palette.secondary.main};
          }
        `
      : css`
          &:hover {
            background-color: ${grey[50]} !important;
          }
        `}
`

interface SubmenuProps {
  isSelected: boolean
}
export const Submenu = styled(ButtonBase)<SubmenuProps>`
  display: flex !important;
  width: 100% !important;
  padding: 8px 16px !important;
  flex-direction: row !important;
  border-radius: 16px !important;

  > div {
    display: flex;
    flex: 1;
    align-items: center;

    svg {
      ${({ isSelected }) =>
        isSelected && `color: ${theme.palette.secondary.main};`}
      font-size: 16px;
      fill: 24px;
    }
    h6 {
      ${({ isSelected }) =>
        isSelected &&
        `font-weight: 400; color: ${theme.palette.secondary.main};`}
    }
  }

  ${({ isSelected }) =>
    isSelected
      ? css``
      : css`
          &:hover {
            background-color: ${grey[50]} !important;
          }
        `}
`
