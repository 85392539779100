import * as yup from 'yup'

export const validationSchema = yup.object({
  title: yup.string().required('Field is required'),

  description: yup.string().required('Field is required'),

  price: yup
    .number()
    .required('Field is required')
    .moreThan(0, 'Must be greater than 0'),

  quantity: yup
    .number()
    .required('Field is required')
    .moreThan(0, 'Must be greater than 0'),
})
