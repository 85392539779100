import axios, { HeadersDefaults } from 'axios'

import {
  BASE_URL_DEV,
  BASE_URL_PROD,
  BASE_URL_TEST,
} from '@/constants/endpoint'
// import { ID_LOCAL_STORAGE_ACCESS_TOKEN } from '@/constants/local-storage'

export interface AxiosCommonHeaderProperties extends HeadersDefaults {
  Authorization: string
}

let baseURL = ''
if (process.env.REACT_APP_NODE_ENV === 'development') {
  baseURL = BASE_URL_DEV
} else if (process.env.REACT_APP_NODE_ENV === 'test') {
  baseURL = BASE_URL_TEST
} else {
  baseURL = BASE_URL_PROD
}

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true
export const api = axios.create({ baseURL })

api.interceptors.request.use(
  async config =>
    // const accessToken = sessionStorage.getItem(ID_LOCAL_STORAGE_ACCESS_TOKEN)

    // config.withCredentials = true
    // config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : ''
    config,
  async error => await Promise.reject(error),
)

axios.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.replace('/')
    }

    return await Promise.reject(error)
  },
)

export default api
