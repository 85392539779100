import React from 'react'

import { useField } from 'formik'

import { red } from '@mui/material/colors'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectProps } from '@mui/material/Select'

export interface FormikSelectFieldMenu {
  value: string | number
  title: string
}

type FormikSelectFieldProps = {
  name: string
  fullWidth?: boolean
  label?: string
  menuList: FormikSelectFieldMenu[]
} & Partial<SelectProps>

export const FormikSelectField = ({
  name,
  fullWidth = true,
  label = 'Select',
  menuList,
  variant = 'outlined',
  ...props
}: FormikSelectFieldProps) => {
  const [
    field,
    meta,
  ] = useField(name)

  return (
    <FormControl
      fullWidth={fullWidth}
      error={meta.touched && Boolean(meta.error)}
    >
      <InputLabel id='select-type'>{label}</InputLabel>
      <Select
        {...field}
        id={field.name}
        name={name}
        labelId={field.name}
        label={label}
        error={meta.touched && Boolean(meta.error)}
        value={field.value}
        onChange={field.onChange}
        variant={variant}
        {...props}
      >
        <MenuItem value=''>
          <em>-- Select --</em>
        </MenuItem>
        {menuList.map(item => (
          <MenuItem key={item.title} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && Boolean(meta.error) && (
        <FormHelperText sx={{ color: red[700] }}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  )
}
