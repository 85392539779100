import React, { useEffect, useMemo, useState } from 'react'
import {
  TbCheckbox,
  TbChevronRight,
  TbCircleMinus,
  TbCirclePlus,
  TbDotsVertical,
  TbPencil,
  TbX,
} from 'react-icons/tb'

import { useGlobalState } from '@/context'
import * as ApiService from '@/services/api'
import { theme } from '@/styles/mui-style'
import { formatNumberToLibra } from '@/utils/currency'
import {
  Box,
  CircularProgress,
  Drawer,
  FormHelperText,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  Menu,
  TextField,
  Typography,
  useMediaQuery,
  ListItem,
  Checkbox,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import List from '@mui/material/List'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'

interface DrawerChooseStockProps {
  stockWithCheckListChoosed?: StockWithCheck[]
  errorMessage?: string
  stockChoosed?: Stock
  stockIdToSelect?: string | undefined
  setStockWithCheckListChoosed: (stockWithCheckList: StockWithCheck[]) => void
  reset: () => void
}

export const DrawerChooseStock = ({
  stockWithCheckListChoosed,
  setStockWithCheckListChoosed,
  errorMessage,
  reset,
}: DrawerChooseStockProps) => {
  const [{ company }] = useGlobalState()
  const [stockList, setStockList] = useState<StockWithCheck[] | undefined>(
    undefined,
  )
  const [stockListOriginal, setStockListOriginal] = useState<StockWithCheck[] | undefined>(
    undefined,
  )
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [searchStock, setSearchStock] = useState<string>('')
  const [showDrawerChooseStock, setShowDrawerChooseStock] =
    useState<boolean>(false)
  const filterStockList = useMemo(
    () =>
      stockList?.filter(stock =>
        stock.title.toLowerCase().includes(searchStock.toLowerCase()),
      ),
    [stockList, searchStock],
  )
  const companyId = useMemo(() => company?.id || '', [])
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const open = Boolean(anchorEl)

  const hasAnyChoosed = useMemo(
    () => (stockWithCheckListChoosed || []).length > 0,
    [stockWithCheckListChoosed],
  )

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleMenuAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCheckUncheck = (stockItem: Stock) => {
    const response = stockList?.map(item => {
      if (stockItem.id === item.id) {
        return { ...item, isChecked: !item.isChecked }
      }
      return item
    })
    setStockList(response)
    const stockChoosedTemp = response?.filter(item => item.isChecked)
    setStockWithCheckListChoosed(stockChoosedTemp || [])
  }

  const handleHowManyQuantity = (stockItem: Stock, quantity: number) => {
    const response = stockList?.map(item => {
      if (stockItem.id === item.id) {
        return { ...item, howManyQuantity: quantity }
      }
      return item
    })

    setStockList(response)
    const stockChoosedTemp = response?.filter(item => item.isChecked)
    setStockWithCheckListChoosed(stockChoosedTemp || [])
  }

  const handleReset = () => {
    setStockWithCheckListChoosed([])
    setSearchStock('')
    reset()
    setStockList(stockListOriginal)
  }

  useEffect(() => {
    ApiService.Stock.getList({
      companyId,
      setStockList: stockListApi => {
        if (!stockListApi || stockListApi?.length === 0) return
        const response = stockListApi?.map(item => {
          const newData = {
            id: item.id,
            title: item.title,
            price: item.price,
            quantity: item.quantity,
            isChecked: false,
            isActive: item.isActive,
            howManyQuantity: 1,
          } as StockWithCheck
          return newData
        })
        const listSorted = response.sort((a, b) =>
          a.title.localeCompare(b.title),
        )
        setStockList(listSorted)
        setStockListOriginal(listSorted)
      },
      setLoadingList,
    })
  }, [])

  return (
    <Box>
      <Stack
        display='flex'
        flexDirection='row'
        sx={{
          height: 54,
          width: '100%',
          borderRadius: 1,
          border: `1px solid ${errorMessage ? red[700] : grey[200]}`,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: 1.5,
        }}
      >
        {loadingList && <CircularProgress size={24} />}
        {!loadingList && (
          <Stack
            display='flex'
            flex={1}
            alignItems='center'
            flexDirection='row'
            flexWrap='nowrap'
          >
            {hasAnyChoosed ? (
              <>
                {/* <AvatarLetter name={(stockChoosed?.title || '').substring(0, 1).toUpperCase()} /> */}
                <Typography
                  variant='body2'
                  textOverflow='ellipsis'
                  noWrap
                  width={150}
                  flex={1}
                  // display='flex'
                  // overflow='hidden'
                  // minWidth={0}
                  // whiteSpace='nowrap'
                  // flexShrink={0}
                  ml={1}
                >
                  {stockWithCheckListChoosed?.map(item => item.title).join(', ')}
                </Typography>
              </>
            ) : (
              <Typography
                variant='body2'
                flex={1}
                color={errorMessage ? red[700] : 'inherit'}
              >
                Select parts
              </Typography>
            )}
            {hasAnyChoosed ? (
              <IconButton
                sx={{ height: 40, width: 40 }}
                onClick={handleMenuAction}
              >
                <TbDotsVertical color={grey[600]} />
              </IconButton>
            ) : (
              <IconButton
                sx={{ height: 40, width: 40 }}
                onClick={() => {
                  setShowDrawerChooseStock(true)
                }}
              >
                <TbChevronRight color={grey[600]} />
              </IconButton>
            )}
          </Stack>
        )}
      </Stack>

      {errorMessage && (
        <FormHelperText sx={{ pl: 1.8, color: red[700] }}>
          {errorMessage}
        </FormHelperText>
      )}

      <Drawer
        open={showDrawerChooseStock}
        anchor='right'
        onClose={() => {
          setShowDrawerChooseStock(false)
          setSearchStock('')
        }}
        PaperProps={{ sx: { width: isXs ? '90%' : '50%' } }}
      >
        <Stack display='flex' flex={1} padding={2} position='relative'>
          <Stack position='absolute' right='12px' top='12px'>
            <IconButton
              onClick={() => {
                setShowDrawerChooseStock(false)
                setSearchStock('')
              }}
            >
              <TbX color={grey[900]} />
            </IconButton>
          </Stack>
          <Stack
            paddingBottom={2}
            borderBottom={`1px dashed ${grey[300]}`}
            marginBottom={3}
          >
            <Typography variant='h6'>Select part</Typography>
          </Stack>
          <TextField
            label='Search part'
            variant='outlined'
            onChange={event => {
              setSearchStock(event.target.value)
            }}
          />
          <List>
            {filterStockList?.map((stock, index) => {
              const noStock = !stock.quantity

              return (
                <ListItem
                  disabled={!stock.isActive || !stock.quantity}
                  key={stock.id}
                  secondaryAction={
                    <Stack
                      flexDirection='row'
                      display='flex'
                      alignItems='center'
                    >
                      <IconButton
                        onClick={() => {
                          if (stock.howManyQuantity === 1) return
                          handleHowManyQuantity(
                            stock,
                            stock.howManyQuantity - 1,
                          )
                        }}
                      >
                        <TbCircleMinus color={grey[900]} />
                      </IconButton>
                      <Typography
                        variant='caption'
                        fontWeight='bold'
                        marginX={1}
                      >
                        {stock.howManyQuantity}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          if (stock.howManyQuantity === stock.quantity) return
                          handleHowManyQuantity(
                            stock,
                            stock.howManyQuantity + 1,
                          )
                        }}
                      >
                        <TbCirclePlus color={grey[900]} />
                      </IconButton>
                    </Stack>
                  }
                  disablePadding
                >
                  <ListItem disableGutters sx={{ borderRadius: 2 }}>
                    <ListItemAvatar>
                      <Checkbox
                        edge='end'
                        onChange={
                          noStock
                            ? undefined
                            : () => {
                              handleCheckUncheck(stock)
                            }
                        }
                        checked={stock.isChecked}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={stock.title}
                      secondary={
                        <Stack pt={0.5}>
                          <Typography variant='caption'>
                            Quantity:{' '}
                            <Typography
                              variant='caption'
                              color={grey[900]}
                              fontWeight='bold'
                            >
                              {stock.quantity || 0}
                            </Typography>
                          </Typography>
                          <Typography variant='caption'>
                            Price:{' '}
                            <Typography
                              variant='caption'
                              color={grey[900]}
                              fontWeight='bold'
                            >
                              {formatNumberToLibra({
                                value: stock.price,
                                showCurrency: true,
                              })}
                            </Typography>
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
                </ListItem>
              )
            })}
          </List>
        </Stack>
      </Drawer>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setShowDrawerChooseStock(true)
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            handleReset()
          }}
        >
          <ListItemIcon>
            <TbCheckbox size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Uncheck all
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
