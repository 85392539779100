export enum EnumOrderStatus {
  OPEN = 'Open',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  RETURNED = 'Returned',
}

export enum EnumOrderType {
  QUOTATION = 'Quotation',
  SALE = 'Sale',
  INVOICE_AND_CREDIT = 'Invoice',
}
