import React from 'react'

import { Stack, Typography, } from '@mui/material'
import { green, amber, deepPurple, lightBlue, blueGrey, orange, indigo } from '@mui/material/colors'
import { Variant } from '@mui/material/styles/createTypography'

interface StatusProps {
    serviceStatusType: ServiceStatusType
    title: string
    titleVariant?: Variant
    isTitleBold?: boolean
    onClick?: () => void
}

export const StatusServiceFlowColors: Record<ServiceStatusType, Record<string, Record<number, string>>> = {
    'TO_DO': {
        color: lightBlue,
    },
    'IN_PROGRESS': {
        color: amber,
    },
    'AWAITING_APPROVAL': {
        color: deepPurple,
    },
    'APPROVED': {
        color: green,
    },
    'DELIVERED': {
        color: orange,
    },
    'START_WORK': {
        color: blueGrey,
    },
    'WORK_FINISH': {
        color: indigo,
    },
}

export const StatusServiceFlow = ({ serviceStatusType, title, titleVariant = 'body2', isTitleBold, onClick }: StatusProps) => (
    <Stack
        onClick={onClick}
        sx={{
            backgroundColor: StatusServiceFlowColors[serviceStatusType].color[50],
            height: 26,
            borderRadius: 1,
            paddingX: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Typography
            variant={titleVariant}
            color={StatusServiceFlowColors[serviceStatusType].color[900]}
            noWrap
            sx={{
                ...(isTitleBold ? { fontWeight: 'bold' } : {})
            }}>
            {title}
        </Typography>
    </Stack >
)

