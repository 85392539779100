export const getPathFileCompany = (companyId: string): string =>
  `company_${companyId}`

export const getPathFileCompanyLogomarca = (companyId: string): string =>
  `company_${companyId}/logomarca`

// CLIENT
export const getPathFileCompanyClient = (
  companyId: string,
  clientId: string,
): string => `company_${companyId}/client/client_${clientId}`

// SERVICE
export const getPathFileCompanyService = (
  companyId: string,
  serviceId: string,
): string => `company_${companyId}/service/service_${serviceId}`

// STOCK
export const getPathFileCompanyStock = (
  companyId: string,
  stockId: string,
): string => `company_${companyId}/stock/stock_${stockId}`

// PROFILE
export const getPathFileUserProfile = (userId: string): string =>
  `user_${userId}/profile/`

// SUPPLIER
export const getPathFileCompanySupplier = (
  companyId: string,
  supplierId: string,
): string => `company_${companyId}/supplier/supplier_${supplierId}`
