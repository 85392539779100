/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  TbArrowDown,
  TbArrowUp,
  TbDotsVertical,
  TbPencil,
  TbPlus,
  TbPrinter,
  TbSearch,
  TbTrash,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import { orderBy, where } from 'firebase/firestore'
import moment from 'moment'

import {
  BoxShadow,
  Breadcrumb,
  DialogConfirm,
  StatusServiceFlow,
  // Status,
} from '@/components'
import { NoContent } from '@/components/no-content'
import { EnumServiceStatus, EnumServiceType } from '@/constants/service'
import { useGlobalState } from '@/context'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { deleteFile, getFiles } from '@/services/api/file'
import { formatNumberToLibra } from '@/utils/currency'
import { getPathFileCompanyService } from '@/utils/storage'
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { amber, cyan, grey, red, teal } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

import { PrintService } from './print-service'

const BOX_HEIGHT = 492

enum ENUM_ORDER_BY {
  NAME = 'Name',
  CLIENT = 'Client',
  KEY_UNIQUE = 'Key unique',
  PO = 'PO',
}
type ORDER_BY_TYPE = keyof typeof ENUM_ORDER_BY

interface DataPrintService {
  service: Service
  client: Client
}

interface RenderTableBodyProps {
  filterServiceList?: Service[]
  loadingList: boolean
  handleMenuAction: (event: React.MouseEvent<HTMLButtonElement>, serice: Service) => void
}

const RenderTableBody = ({ filterServiceList, loadingList, handleMenuAction }: RenderTableBodyProps) => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState<number>(-1);

  if (loadingList) {
    return (
      <Stack
        display='flex'
        justifyContent='center'
        alignItems='center'
        width='100%'
        position='absolute'
        height='360px'
      >
        <CircularProgress color='primary' />
      </Stack>
    )
  }

  if (filterServiceList?.length === 0) {
    return (
      <Stack
        display='flex'
        alignItems='center'
        width='100%'
        position='absolute'
        height='360px'
      >
        <NoContent />
      </Stack>
    )
  }

  return (
    <TableBody>
      {filterServiceList?.map((service, index) => (
        <>
          <TableRow hover={open !== index} key={service.id} sx={{ backgroundColor: open === index ? amber[50] : '' }}>
            <TableCell>
              <Typography variant='subtitle2'>{service.id.substring(0, 6).toUpperCase()}</Typography>
              <Stack ml={1.5}>
                <Tooltip title='More details'>
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (open === index) {
                        setOpen(-1)
                      } else {
                        setOpen(index)
                      }
                    }}
                    sx={{ width: 24, height: 24 }}
                  >
                    {open === index ? <TbArrowUp color={grey[900]} /> : <TbArrowDown color={grey[900]} />}
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
            <TableCell>
              <Typography variant='body2'
                textOverflow='ellipsis'
                noWrap
                width={120}>{service.title}</Typography>
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography
                  variant='body2'
                  textOverflow='ellipsis'
                  noWrap
                  width={120}
                >
                  {service.clientFullname}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Typography variant='caption' minWidth={80}>Created:</Typography>
                <Typography variant='caption' fontWeight='bold'>
                  {moment(service.createdAt).format('DD/MM/YYYY, hh:mm')}
                </Typography>
              </Stack>
              {service.assignServiceWorkerToDoCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>To Do:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerToDoCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerInProgressCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>In Progress:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerInProgressCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerAwaitingApprovalCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>Awaitng:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerAwaitingApprovalCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerApprovedCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>Approved:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerApprovedCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerStartWorkCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>Start work:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerStartWorkCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>
              )}
              {service.assignServiceWorkerWorkFinishCreatedAt && (
                <Stack flexDirection='row'>
                  <Typography variant='caption' minWidth={80}>Work finish:</Typography>
                  <Typography variant='caption' fontWeight='bold'>
                    {moment(service.assignServiceWorkerWorkFinishCreatedAt).format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Stack>

              )}
            </TableCell>
            <TableCell>
              <Stack flexDirection='column'>
                <Typography variant='body2'>
                  <Stack flexDirection='row'>
                    <StatusServiceFlow
                      title={EnumServiceStatus[service.status]}
                      serviceStatusType={service.status}
                    />
                  </Stack>
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => {
                      navigate(RoutesNames.SERVICE_CRUD, {
                        state: { serviceSelected: JSON.stringify(service) },
                      })
                    }}
                  >
                    <TbPencil color={grey[900]} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Options'>
                  <IconButton
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={event => {
                      handleMenuAction(event, service)
                    }}
                  >
                    <TbDotsVertical color={grey[900]} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>

          <TableRow sx={{ 'td, th': { border: 0 }, backgroundColor: open === index ? amber[50] : '' }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open === index} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, mb: 3 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Details of service: {service.title}
                  </Typography>

                  <BoxShadow>
                    <TableContainer>
                      <Table stickyHeader sx={{ position: 'relative' }} size='small'>
                        <TableHead>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell width={120}>
                              <Typography variant='subtitle2'>PO</Typography>
                            </TableCell>
                            <TableCell width={120}>
                              <Typography variant='subtitle2'>Price/Hour</Typography>
                            </TableCell>
                            <TableCell width={80}>
                              <Typography variant='subtitle2'>Type</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='subtitle2'>Observations</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography variant='body2'>{service.po || '-- --'}</Typography>
                            </TableCell>
                            <TableCell>
                              <Stack flexDirection='column'>
                                <Typography variant='body2'>
                                  {service.totalPrice ? formatNumberToLibra({
                                    value: service.totalPrice, showCurrency: true
                                  }) : '-- --'}
                                </Typography>
                                <Typography variant='caption' color={grey[500]} fontWeight={500}>
                                  {service.workHours ? `Work hour: ${service.workHours}` : '-- --'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack flexDirection='column'>
                                <Typography variant='body2'>
                                  {EnumServiceType[service.typeService]}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack flexDirection='column'>
                                <Typography variant='caption'>{service.observations || '-- --'}</Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BoxShadow>

                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ),
      )}
    </TableBody>
  )
}


export const Service = () => {
  const navigate = useNavigate()
  const printServiceRef = useRef<HTMLDivElement>(null)
  const [{ company }, dispatch] = useGlobalState()
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [serviceSelected, setServiceSelected] = useState<Service | undefined>(
    undefined,
  )
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const [serviceList, setServiceList] = useState<Service[] | undefined>(
    undefined,
  )
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [printService, setPrintService] = useState<
    DataPrintService | undefined
  >(undefined)
  const companyId = useMemo(() => company?.id || '', [])
  const [filterBy, setFilterBy] = useState<ORDER_BY_TYPE>('NAME')
  const [filterStatus, setFilterStatus] = useState<ServiceStatusType | ''>('')
  const [filterEmpyt, setFilterEmpyt] = useState<boolean>(false)
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Services',
      title: 'Services',
      isCurrentPage: true,
    },
  ]
  const [searchService, setSearchService] = useState<string>('')

  const filterServiceList = useMemo(
    () =>
      serviceList?.filter(service => {
        if (filterBy === 'NAME') {
          return service.title
            .toLowerCase()
            .includes(searchService.toLowerCase())
        }

        if (filterBy === 'CLIENT') {
          return service.clientFullname
            .toLowerCase()
            .includes(searchService.toLowerCase())
        }

        if (filterBy === 'PO') {
          if (filterEmpyt) {
            return !service?.po
          }
          return (service?.po || '')
            .toLowerCase()
            .includes(searchService.toLowerCase())
        }

        return service.id.substring(0, 6).toUpperCase()
          .toLowerCase()
          .includes(searchService.toLowerCase())
      }),
    [serviceList, searchService, filterEmpyt],
  )

  const hasFilter = useMemo(() => {
    if (!!filterStatus || !!searchService || filterEmpyt) return true
    return false
  }, [filterStatus, searchService, filterEmpyt])

  const handleShowPrint = useReactToPrint({
    content: () => printServiceRef.current,
    documentTitle: `Print-Service-${new Date().getTime()}`,
    onBeforePrint: () => {
      console.log('before printing...')
    },
    onAfterPrint: () => {
      setPrintService(undefined)
    },
    removeAfterPrint: true,
  })

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    serice: Service,
  ) => {
    setAnchorEl(event.currentTarget)
    setServiceSelected(serice)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleDeleteService = async () => {
    if (!serviceSelected) return
    setLoadingDelete(true)

    await ApiService.Service.remove({
      companyId,
      serviceId: serviceSelected.id,
    })
      .then(async () => {
        await getFiles({
          pathFile: getPathFileCompanyService(companyId, serviceSelected.id),
        }).then(async listFile => {
          if (listFile.length === 0) return

          const promises: any[] = []
          listFile.forEach(file => {
            promises.push(deleteFile({ pathFile: file.fullPath }))
          })
          await Promise.all(promises).catch(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: {
                message: 'Error deleting images',
                alertSeverity: 'error',
              },
            })
          })
        })

        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Service deleted' },
        })
      })
      .catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Service was not deleted' },
        })
      })
      .finally(() => {
        setLoadingDelete(false)
        setIsOpenConfirmDelete(false)
      })
  }

  const handlePrint = async () => {
    const client = await ApiService.Client.getOne({
      companyId,
      clientId: serviceSelected?.clientId || '',
    })

    if (!serviceSelected || !client) return

    setPrintService({
      service: serviceSelected,
      client,
    })
  }

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setFilterStatus(event.target.value as ServiceStatusType)
  }

  const handleChangeOrderBy = (event: SelectChangeEvent) => {
    setFilterBy(event.target.value as ORDER_BY_TYPE)
    setSearchService('')
  }

  const handleChangeFilterEmpyt = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilterEmpyt(event.target.checked)
  }

  useEffect(() => {
    ApiService.Service.getList({
      companyId,
      setServiceList: list => {
        if (filterStatus) {
          setServiceList((list || []).sort((a, b) => b.createdAt - a.createdAt))
          return
        }
        setServiceList(list)
      },
      setLoadingList,
      whereList: !filterStatus ? [] : [where('status', '==', filterStatus)],
      orderByList: filterStatus ? [] : [orderBy('createdAt', 'desc')],
    })
  }, [filterStatus])

  useEffect(() => {
    if (!printService) return
    handleShowPrint(null, () => printServiceRef.current)
  }, [printService])

  return (
    <Box>
      <Breadcrumb pageTitle='List of services' menu={breadcrumbList}>
        <Stack display='flex' alignItems='center'>
          <Button
            startIcon={<TbPlus />}
            color='btnSecondary'
            variant='contained'
            size='small'
            onClick={() => {
              navigate(RoutesNames.SERVICE_CRUD)
            }}
            disableElevation
          >
            New
          </Button>
        </Stack>
      </Breadcrumb>

      <BoxShadow>
        <TableContainer sx={{ height: BOX_HEIGHT }}>
          <Stack
            display='flex'
            flex={1}
            flexDirection='row'
            justifyContent='center'
            paddingY={2}
            pr={1}
            pl={2}
          >
            <Stack display='flex' flexDirection='row' flex={1}>
              <FormControl size='small'>
                <InputLabel id='filterStatusFlow'>Status Flow</InputLabel>
                <Select
                  labelId='filterStatusFlow'
                  value={filterStatus}
                  label='Status Flow'
                  onChange={handleChangeStatus}
                  sx={{ width: 200, mr: 1 }}
                >
                  <MenuItem value=''>
                    <em>-- Select --</em>
                  </MenuItem>
                  {Object.entries(EnumServiceStatus).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl size='small'>
                <InputLabel id='filterBy'>Filter by</InputLabel>
                <Select
                  labelId='filterBy'
                  value={filterBy}
                  label='Filter by'
                  onChange={handleChangeOrderBy}
                  sx={{ width: 100, mr: 0.5 }}
                >
                  {Object.entries(ENUM_ORDER_BY).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                size='small'
                label='Search'
                value={searchService}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <TbSearch color={grey[900]} />
                    </InputAdornment>
                  ),
                }}
                onChange={event => {
                  setSearchService(event.target.value)
                }}
                sx={{ width: 200, marginRight: 2 }}
                disabled={filterEmpyt}
              />
              {filterBy === 'PO' && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={filterEmpyt}
                      size='small'
                      onChange={handleChangeFilterEmpyt}
                    />
                  }
                  label={<Typography variant='body2'>Is empyt</Typography>}
                />
              )}
            </Stack>
            <Stack display='flex' justifyContent='center'>
              {hasFilter && (
                <Button
                  size='small'
                  variant='outlined'
                  disableElevation
                  onClick={() => {
                    setSearchService('')
                    setFilterStatus('')
                    setFilterBy('NAME')
                    setFilterEmpyt(false)
                  }}
                >
                  Clean
                </Button>
              )}
            </Stack>
          </Stack>
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell width={30}>
                  <Typography variant='subtitle2'>Key</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Name</Typography>
                </TableCell>
                <TableCell width={120}>
                  <Typography variant='subtitle2'>Client name</Typography>
                </TableCell>
                <TableCell width={240}>
                  <Typography variant='subtitle2'>Status date</Typography>
                </TableCell>
                <TableCell width={150}>
                  <Typography variant='subtitle2'>Flow</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            <RenderTableBody
              filterServiceList={filterServiceList}
              loadingList={loadingList}
              handleMenuAction={handleMenuAction}
            />
          </Table>
        </TableContainer>
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.SERVICE_CRUD, {
              state: { serviceSelected: JSON.stringify(serviceSelected) },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            handlePrint()
          }}
        >
          <ListItemIcon>
            <TbPrinter size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Print</Typography>
        </MenuItem>
        <MenuItem
          disabled={serviceSelected?.status !== 'TO_DO'}
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${serviceSelected?.title}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => {
            handleDeleteService()
          },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => {
            setIsOpenConfirmDelete(false)
          },
        }}
      />

      {printService && (
        <div style={{ visibility: 'hidden', height: 0 }}>
          <PrintService
            ref={printServiceRef}
            service={printService.service}
            client={printService.client}
          />
        </div>
      )}
    </Box>
  )
}
