import React from 'react'

import Typography from '@mui/material/Typography'

import * as S from './style'

export const NoAccess = () => (
  <S.Wrapper>

    <Typography variant='h6'>
      You do not have permission to view this page.
    </Typography>
  </S.Wrapper>
)
