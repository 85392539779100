
import React, { useEffect, useMemo, useState } from 'react'

import { Breadcrumb } from '@/components';
import { useGlobalState } from '@/context';
import { ApiService } from '@/services';
import { Box, CircularProgress, Grid, Stack } from '@mui/material'


import { Cards } from './card';
import { Pies } from './pie';

export const Home = () => {
  const [{ user, company }] = useGlobalState()
  const [serviceList, setServiceList] = useState<Service[] | undefined>(undefined)
  const [loadingServiceList, setLoadingServiceList] = useState<boolean>(false)
  const [clientList, setClientList] = useState<Client[] | undefined>(undefined)
  const [loadingClientList, setLoadingClientList] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])

  useEffect(() => {
    if (user?.userConfig?.roles.includes('ROOT')) return

    if (serviceList === undefined) {
      ApiService.Service.getList({
        companyId,
        setServiceList,
        setLoadingList: setLoadingServiceList
      })
    }
  }, [serviceList, ApiService.Service.getList])

  useEffect(() => {
    if (user?.userConfig?.roles.includes('ROOT')) return

    if (clientList === undefined) {
      ApiService.Client.getList({
        companyId,
        setClientList,
        setLoadingList: setLoadingClientList
      })
    }
  }, [clientList, ApiService.Client.getList])

  return (
    <Box>
      <Breadcrumb pageTitle='Hello, welcome' />

      {!user?.userConfig?.roles.includes('ROOT') && (
        <>
          {(loadingServiceList && loadingClientList) && (
            <Stack display='flex' flex={1} justifyContent='center' alignItems='center' height='50vh'>
              <CircularProgress size={32} />
            </Stack>
          )}
          {!(loadingServiceList && loadingClientList) && (
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Cards serviceList={serviceList || []} clientList={clientList || []} />
              </Grid>
              <Grid item xs={12}>
                <Pies serviceList={serviceList || []} />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Box>
  )
}
