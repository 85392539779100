import React from 'react'

import moment from 'moment'

import { EnumServiceStatus, EnumServiceType } from '@/constants/service'
import { useGlobalState } from '@/context'
import { formatNumberToLibra } from '@/utils/currency'
import { Avatar, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'

export interface PrintServiceProps {
  service: Service
  client: Client
}

interface StockSold extends Stock {
  howManyQuantity: number
}

export const PrintService = React.forwardRef<HTMLDivElement, PrintServiceProps>(
  (props, ref) => {
    const [{ company }] = useGlobalState()
    const { service, client } = props

    const listPartsSold = () => {
      if (!service.partsSold) return []

      return JSON.parse(service.partsSold) as StockSold[]
    }

    return (
      <div ref={ref}>
        <Stack padding={2}>
          <Stack flexDirection='row' alignItems='center'>
            <Avatar
              src={company?.photoURL}
              variant='rounded'
              sx={{
                height: 120,
                width: 120,
              }}
            />

            <Stack display='flex' flex={1} justifyContent='center' pl={2}>
              <Typography variant='h6'>{company?.name}</Typography>
              <Typography variant='body1'>E-mail: {company?.email}</Typography>
              <Typography variant='body1'>
                Phone Number: {company?.phoneNumber}
              </Typography>
              <Typography variant='body1'>
                Address: {company?.address}
              </Typography>
              <Typography variant='body1'>
                Website: {company?.websiteUrl}
              </Typography>
            </Stack>
          </Stack>

          <Box mb={3} />

          <Typography variant='h6'>Client</Typography>
          <Box mb={1} />
          <Typography variant='body1'>Name: {client.fullname}</Typography>
          <Typography variant='body1'>E-mail: {client.email}</Typography>
          <Typography variant='body1'>
            E-mail secondary: {client.emailSecondary || '-- --'}
          </Typography>
          <Typography variant='body1'>
            Phone number: {client.phoneNumber}
          </Typography>
          <Typography variant='body1'>
            Phone number secondary: {client.phoneNumberSecondary || '-- --'}
          </Typography>
          <Typography variant='body1'>Address: {client.address}</Typography>
          <Typography variant='body1'>
            Address delivery: {client.addressDelivery}
          </Typography>

          <Box mb={3} />

          <Typography variant='h6'>Details of service</Typography>
          <Box mb={1} />
          <Typography variant='body1'>Name: {service.title}</Typography>
          <Typography variant='body1'>
            Description: {service.description}
          </Typography>
          <Typography variant='body1'>
            Type: {EnumServiceType[service.typeService]}
          </Typography>
          <Typography variant='body1'>Key: {service.id.substring(0, 6).toUpperCase()}</Typography>
          <Typography variant='body1'>
            Status: {EnumServiceStatus[service.status]}
          </Typography>
          <Typography variant='body1'>
            Created:{' '}
            {moment(service.createdAt || '').format(
              'dddd, MMMM Do YYYY, h:mm:ss a',
            )}
          </Typography>
          {service.totalPrice && (
            <Typography variant='body1'>
              Price:{' '}
              {formatNumberToLibra({
                value: service.totalPrice,
                showCurrency: true,
              })}
            </Typography>
          )}
          {service.workHours && (
            <Typography variant='body1'>
              Work hours: {service.workHours}
            </Typography>
          )}
          <Box mb={1} />
          {service.assignServiceWorkerToDo && (
            <>
              <Typography variant='body1'>Worker - To Do</Typography>
              <Typography variant='body1'>
                - Assign to: {service.assignServiceWorkerToDo}
              </Typography>
              <Typography variant='body1'>
                - Created:{' '}
                {moment(service.assignServiceWorkerToDoCreatedAt || '').format(
                  'dddd, MMMM Do YYYY, h:mm:ss a',
                )}
              </Typography>
              <Box mb={1} />
            </>
          )}
          {service.assignServiceWorkerInProgressCreatedAt && (
            <>
              <Typography variant='body1'>Worker - In Progress</Typography>
              <Typography variant='body1'>
                - Created:{' '}
                {moment(
                  service.assignServiceWorkerInProgressCreatedAt || '',
                ).format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </Typography>
              <Box mb={1} />
            </>
          )}
          {service.assignServiceWorkerAwaitingApprovalCreatedAt && (
            <>
              <Typography variant='body1'>Worker - AwaitingApproval</Typography>
              <Typography variant='body1'>
                - Created:{' '}
                {moment(
                  service.assignServiceWorkerAwaitingApprovalCreatedAt || '',
                ).format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </Typography>
              <Box mb={1} />
            </>
          )}
          {service.assignServiceWorkerApprovedCreatedAt && (
            <>
              <Typography variant='body1'>Worker - Approved</Typography>
              <Typography variant='body1'>
                - Created:{' '}
                {moment(
                  service.assignServiceWorkerApprovedCreatedAt || '',
                ).format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </Typography>
              <Box mb={1} />
            </>
          )}
          {service.assignServiceWorkerStartWork && (
            <>
              <Typography variant='body1'>Worker - Start Work</Typography>
              <Typography variant='body1'>
                - Assign to: {service.assignServiceWorkerStartWork}
              </Typography>
              <Typography variant='body1'>
                - Created:{' '}
                {moment(
                  service.assignServiceWorkerStartWorkCreatedAt || '',
                ).format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </Typography>
              <Box mb={1} />
            </>
          )}
          {service.assignServiceWorkerWorkFinishCreatedAt && (
            <>
              <Typography variant='body1'>Worker - Work finish</Typography>
              <Typography variant='body1'>
                - Created:{' '}
                {moment(
                  service.assignServiceWorkerWorkFinishCreatedAt || '',
                ).format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </Typography>
              <Box mb={1} />
            </>
          )}
          {service.assignServiceWorkerDelivered && (
            <>
              <Typography variant='body1'>Worker - Delivered</Typography>
              <Typography variant='body1'>
                - Assign to: {service.assignServiceWorkerDelivered}
              </Typography>
              <Typography variant='body1'>
                - Created:{' '}
                {moment(
                  service.assignServiceWorkerDeliveredCreatedAt || '',
                ).format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </Typography>
              <Box mb={1} />
            </>
          )}

          {service.partsSold && (
            <>
              <Box mb={3} />

              <Typography variant='h6'>Parts sold</Typography>

              <Box mb={1} />

              {listPartsSold().map((part, index) => (
                <Stack key={part.id}>
                  <Typography variant='body1'>Name: {part.title}</Typography>
                  <Typography variant='body1'>
                    description: {part.description}
                  </Typography>
                  <Typography variant='body1'>
                    Unitary price:{' '}
                    {formatNumberToLibra({
                      value: part.price,
                      showCurrency: true,
                    })}
                  </Typography>
                  <Typography variant='body1'>
                    quantity: {part.howManyQuantity}
                  </Typography>
                  <Typography variant='body1'>
                    Total:{' '}
                    {formatNumberToLibra({
                      value: part.price * part.howManyQuantity,
                      showCurrency: true,
                    })}
                  </Typography>

                  {index + 1 < listPartsSold.length && (
                    <Box
                      sx={{
                        width: '100%',
                        borderBottom: '1px dashed #000',
                        my: 1,
                      }}
                    />
                  )}
                </Stack>
              ))}
            </>
          )}
        </Stack>
      </div>
    )
  },
)
PrintService.displayName = 'PrintPage'
