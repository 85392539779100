import React from 'react'

import { Stack, Typography, } from '@mui/material'
import { green, amber, deepPurple, red } from '@mui/material/colors'
import { Variant } from '@mui/material/styles/createTypography'

interface StatusProps {
    orderStatus: OrderStatus
    title: string
    titleVariant?: Variant
    isTitleBold?: boolean
    onClick?: () => void
}

export const StatusServiceFlowColors: Record<OrderStatus, Record<string, Record<number, string>>> = {
    'OPEN': {
        color: deepPurple,
    },
    'ACCEPTED': {
        color: green,
    },
    'DECLINED': {
        color: amber,
    },
    'RETURNED': {
        color: red,
    },
}

export const StatusOrder = ({ orderStatus, title, titleVariant = 'body2', isTitleBold, onClick }: StatusProps) => (
    <Stack
        onClick={onClick}
        sx={{
            backgroundColor: StatusServiceFlowColors[orderStatus].color[100],
            height: 26,
            borderRadius: 1,
            paddingX: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Typography
            variant={titleVariant}
            color={StatusServiceFlowColors[orderStatus].color[900]}
            noWrap
            sx={{
                ...(isTitleBold ? { fontWeight: 'bold' } : {})
            }}>
            {title}
        </Typography>
    </Stack >
)

