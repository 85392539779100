import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { Backdrop } from '@/components'
import { useGlobalState } from '@/context'
import { LayoutAuth, LayoutDefault, LayoutMobile } from '@/layout'
import * as Pages from '@/pages'

import { LoggedRoute, PrivateRoute, AllowedRoute } from './manager-route'
import { RoutesNames } from './routes'

export const AllRoutes = () => (
  <Routes>
    {/* <Route errorElement={<Pages.NotFount />}></Route> */}

    {/* AUTH */}
    <Route element={<LayoutAuth />}>
      <Route element={<LoggedRoute />}>
        <Route path={RoutesNames.LOGIN} Component={Pages.Login} />
      </Route>
    </Route>

    <Route element={<PrivateRoute />}>
      {/* MOBILE */}

      <Route element={<LayoutMobile />}>
        <Route path={RoutesNames.MOBILE_BUCKET} Component={Pages.MobileBucket} />
        <Route path={RoutesNames.MOBILE_HYDRAULIC} Component={Pages.MobileHydraulic} />
      </Route>

      {/* PRIVATE ROUTE */}
      <Route path='/' element={<LayoutDefault />}>

        <Route element={<AllowedRoute />}>
          <Route path={RoutesNames.NO_ACCESS} Component={Pages.NoAccess} />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT', 'MANAGER']} />}
        >
          <Route path={RoutesNames.HOME} Component={Pages.Home} />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT']} />}
        >
          <Route path={RoutesNames.USER} Component={Pages.User} />
          <Route path={RoutesNames.USER_CRUD} Component={Pages.UserCrud} />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT', 'MANAGER']} />}
        >
          <Route path={RoutesNames.PROFILE} Component={Pages.Profile} />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT', 'MANAGER']} />}
        >
          <Route path={RoutesNames.CLIENT} Component={Pages.Client} />
          <Route path={RoutesNames.CLIENT_CRUD} Component={Pages.ClientCrud} />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT']} />}
        >
          <Route path={RoutesNames.COMPANY} Component={Pages.Company} />
          <Route
            path={RoutesNames.COMPANY_CRUD}
            Component={Pages.CompanyCrud}
          />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT', 'MANAGER']} />}
        >
          <Route path={RoutesNames.STOCK_LIST} Component={Pages.Stock} />
          <Route path={RoutesNames.STOCK_CRUD} Component={Pages.StockCrud} />
          <Route path={RoutesNames.STOCK_REQUEST} Component={Pages.StockRequest} />
          <Route path={RoutesNames.STOCK_REQUEST_CRUD} Component={Pages.StockRequestCrud} />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT', 'MANAGER']} />}
        >
          <Route path={RoutesNames.SERVICE_LIST} Component={Pages.Service} />
          <Route path={RoutesNames.SERVICE_CRUD} Component={Pages.ServiceCrud} />
          <Route path={RoutesNames.SERVICE_BUCKET} Component={Pages.MobileBucket} />
          <Route path={RoutesNames.SERVICE_HYDRAULIC} Component={Pages.MobileHydraulic} />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT', 'MANAGER']} />}
        >
          <Route path={RoutesNames.ORDER_QUOTATION} Component={Pages.Order} />
          <Route path={RoutesNames.ORDER_SALE} Component={Pages.Order} />
          <Route path={RoutesNames.ORDER_INVOICE_CREDIT} Component={Pages.Order} />
          <Route path={RoutesNames.ORDER_CRUD} Component={Pages.OrderCrud} />
          <Route path={RoutesNames.ORDER_CREDIT_NOTE} Component={Pages.OrderCreditNote} />
          <Route path={RoutesNames.ORDER_CREDIT_NOTE_CRUD} Component={Pages.OrderCreditNoteCrud} />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT', 'MANAGER']} />}
        >
          <Route path={RoutesNames.CONFIG} Component={Pages.CompanyCrud} />
        </Route>

        <Route
          element={<AllowedRoute allowedRoles={['ROOT', 'MANAGER']} />}
        >
          <Route path={RoutesNames.SUPPLIER} Component={Pages.Supplier} />
          <Route path={RoutesNames.SUPPLIER_CRUD} Component={Pages.SupplierCrud} />
        </Route>

      </Route>
    </Route>
  </Routes>
)

export const Router = () => {
  const [{ isLoadingApp }] = useGlobalState()
  return isLoadingApp ? <Backdrop /> : <AllRoutes />
}
