import * as yup from 'yup'

export const validationToDoToInProgress = yup.object({
  status: yup.string().required(),
  assignServiceWorkerToDo: yup.string().required('Field is required'),
})

export const validationInProgressToAwaitingApproval = yup.object({
  status: yup.string().required(),
  partsSold: yup.string().required('Field is required'),
  workHours: yup
    .number()
    .required('Field is required')
    .moreThan(0, 'Must be greater than 0'),
})

export const validationWorkFinishToDelivered = yup.object({
  status: yup.string().required(),
  assignServiceWorkerDelivered: yup.string().required('Field is required'),
  hasImage: yup
    .boolean()
    .required('Choose at least one image')
    .oneOf([true], 'Choose at least one image'),
})

export const validationApprovedToStartWork = yup.object({
  status: yup.string().required(),
  assignServiceWorkerStartWork: yup.string().required('Field is required'),
})

export const validationStartWorkToWorkFinish = yup.object({
  status: yup.string().required(),
})
