export enum FirebaseCollection {
  BASE_STOCK = 'base_stock',
  BASE_STOCK_REQUEST = 'base_stock_request',

  BASE_CLIENT = 'base_client',
  BASE_CLIENT_CREDIT = 'base_client_credit',

  BASE_ORDER = 'base_order',

  BASE_COMPANY = 'base_company',

  BASE_SERVICE = 'base_service',

  BASE_SUPPLIER = 'base_supplier',
}
