import * as yup from 'yup'

export const validationSchema = yup.object({
  clientName: yup.string().required('Field is required'),

  amount: yup
    .number()
    .required('Field is required')
    .moreThan(0, 'Must be greater than 0'),

  observation: yup.string().required('Field is required'),
})
