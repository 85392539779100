import {
  StorageReference,
  deleteObject,
  getDownloadURL,
  getMetadata,
  listAll,
  ref,
} from 'firebase/storage'

import { storage } from '@/services/firebase'

interface DeleteFileProps {
  pathFile: string
}
export const deleteFile = async ({ pathFile }: DeleteFileProps) => {
  const storageRef = ref(storage, pathFile)

  await deleteObject(storageRef)
}

interface GetFileProps {
  pathFile: string
}
export const getFiles = async ({ pathFile }: GetFileProps) => {
  const storageRef = ref(storage, pathFile)
  const promisesArray: any[] = []
  let promisseArrayResult: FileFromStorage[] = []

  async function getFileURL(fileRef: StorageReference) {
    return await getDownloadURL(
      ref(storage, `${pathFile}/${fileRef.name}`),
    ).then(async url => {
      const metaData = await getMetadata(fileRef).then(meta => meta)

      return {
        name: fileRef.name,
        type: metaData.contentType,
        size: metaData.size,
        lastUpdated: metaData.timeCreated,
        fullPath: metaData.fullPath,
        url,
      } as FileFromStorage
    })
  }

  const result = await listAll(storageRef)
  result.items.forEach(file => promisesArray.push(getFileURL(file)))
  promisseArrayResult = await Promise.all<FileFromStorage[]>(
    promisesArray,
  ).then(resp => resp)
  return promisseArrayResult
}

interface FileExistsProps {
  pathFile: string
}
export const fileExists = async ({ pathFile }: FileExistsProps) =>
  await getDownloadURL(ref(storage, pathFile))
    .then(() => true)
    .catch(() => false)
