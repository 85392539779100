import React, { useMemo, useState } from 'react'
import {
  TbLogout,
  TbMenu2,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { COMMOM_MENU } from '@/constants/header'
import { useGlobalState } from '@/context'
import { theme } from '@/styles/mui-style'
import { Box, Stack } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { grey } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'


import { AvatarLetter } from '../avatar-letter'
import * as S from './style'


export const Header = () => {
  const navigate = useNavigate()
  const [{ user }, dispatch] = useGlobalState()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const isRoleMechanic = useMemo(() => {
    let isMechanic = false

    if (user?.userConfig?.roles.includes('MECHANIC_HYDRAULIC') || user?.userConfig?.roles.includes('MECHANIC_BUCKET')) {
      isMechanic = true
    }

    return isMechanic
  }, [user])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = async () => {
    setAnchorEl(null)
  }

  const handleClose = async (redirectToRouteName?: string) => {
    if (redirectToRouteName) {
      navigate(redirectToRouteName)
      return
    }

    handleCloseMenu()

    dispatch({
      type: 'SET_LOGOUT',
    })
  }

  const handleActionDrawerSidebar = (open: boolean) => {
    dispatch({
      type: 'SET_IS_OPEN_DRAWER_SIDEBAR',
      data: open
    })
  }

  return (
    <S.Container>
      <Stack flexDirection='row'>
        {!isRoleMechanic && (
          <Stack display={{ lg: 'none' }}>
            <IconButton onClick={() => {
              handleActionDrawerSidebar(true)
            }}>
              <TbMenu2 size={24} color={theme.palette.primary.main} />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <Box>
        <Stack flexDirection='row'>
          <IconButton
            onClick={handleClick}
            size='small'
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            {user?.userConfig?.photoURL
              ? <Avatar src={user?.userConfig?.photoURL} />
              : <AvatarLetter name={((user?.displayName || user?.email) || '').substring(0, 1).toUpperCase()} />
            }
          </IconButton>
        </Stack>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={() => {
            handleCloseMenu()
          }}
          onClick={() => {
            handleCloseMenu()
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 1px 10px rgba(0,0,0,0.20))',
              mt: 1.5,
              ml: -0.6,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Box pt={1} pl={2} pr={2} mb={1}>
            <Typography color='text.primary' variant='subtitle2'>
              {user?.displayName}
            </Typography>
            <Typography color={grey[500]} variant='caption'>
              {user?.email}
            </Typography>
          </Box>

          {!isRoleMechanic && COMMOM_MENU.map(item => {
            const { icon: Icon } = item

            return (
              <MenuItem
                key={item.id}
                onClick={() => {
                  handleClose(item.redirectToRouteName)
                }}
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon size={24} color={grey[900]} />
                  </ListItemIcon>
                )}
                <Box mr={1} />
                <Typography variant='body2' color='text.secondary'>
                  {item.title}
                </Typography>
              </MenuItem>
            )
          })}

          <Divider />

          <MenuItem
            onClick={() => {
              handleClose()
            }}
          >
            <ListItemIcon>
              <TbLogout size={24} color={grey[900]} />
            </ListItemIcon>
            <Box mr={1} />
            <Typography color='text.secondary' variant='body2'>
              Exit
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </S.Container>
  )
}
