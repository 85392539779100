import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Form, FormikProvider, useFormik } from 'formik'

import { BoxShadow, FormikTextField } from '@/components'
import { useGlobalState } from '@/context';
import { RoutesNames } from '@/router/routes';
import { ApiService } from '@/services';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Stack,
} from '@mui/material'

// import { validationSchema } from './validationSchema';

export const Profile = () => {
  const navigate = useNavigate()
  const [{ user }, dispatch] = useGlobalState()
  const [initialValues] = useState({ displayName: user?.displayName || '' })
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema,
    onSubmit: async (values) => {
      setLoadingUpdate(true)
      const data = {
        ...user!,
        displayName: values.displayName
      } as User

      await ApiService.Auth.updateUserProfile({
        displayName: values.displayName
      }).then(() => {
        dispatch({ type: 'SET_USER', data })
        dispatch({ type: 'SET_SNACKBAR', data: { message: 'Save successfull' } })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on update', alertSeverity: 'error' }
        })
      }).finally(() => {
        setLoadingUpdate(false)
      })
    }
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <BoxShadow>
          <Stack padding={3}>

            <FormikTextField name='displayName' fullWidth label='Display name' />

            <Stack flexDirection='row' justifyContent='flex-end' mt={3} >
              <Button
                color='btnSecondary'
                variant='contained'
                disableElevation
                onClick={() => { navigate(RoutesNames.HOME) }}
              >
                Back
              </Button>
              <Box mr={2} />
              <LoadingButton
                variant='contained'
                color='btnPrimaryFill'
                disableElevation
                type='submit'
                loading={loadingUpdate}
              >
                Save update
              </LoadingButton>
            </Stack>
          </Stack>
        </BoxShadow>
      </Form>
    </FormikProvider>
  )
}
