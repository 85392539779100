
import React from 'react'

import { Alert, AlertColor } from '@mui/material';
import SnackbarComponent, { SnackbarProps as SnackProps } from '@mui/material/Snackbar';

export interface SnackbarProps extends SnackProps {
    alertSeverity?: AlertColor
    onCloseSnackbar?: () => void
}

export const Snackbar = ({
    message,
    autoHideDuration = 3000,
    anchorOrigin = { vertical: 'top', horizontal: 'right' },
    alertSeverity = 'success',
    onCloseSnackbar,
    ...props
}: SnackbarProps) => {

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        if (onCloseSnackbar) onCloseSnackbar()
    };

    return (
        <>
            <SnackbarComponent
                open={!!message}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                {...props}
            >
                <Alert
                    onClose={handleClose}
                    severity={alertSeverity}
                    variant='standard'
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </SnackbarComponent>
        </>
    )
}
