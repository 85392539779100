import { useState } from 'react'

import {
  UploadMetadata,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'

import { storage } from '@/services/firebase'
import { setNameToFile } from '@/utils/file'

interface StartUploadProps {
  files: File[]
  filePath: string
  fileName?: string
  metadata?: UploadMetadata
  onSuccess?: (urls: string[]) => void | Promise<void>
  onError?: () => void
}

export const useStorageFiles = () => {
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
  const [successUpload, setSuccessUpload] = useState<boolean>(false)

  const startUpload = async ({
    files,
    filePath,
    fileName,
    metadata,
    onSuccess,
    onError,
  }: StartUploadProps) => {
    setLoadingUpload(false)
    setSuccessUpload(false)

    if (files.length === 0) return

    setLoadingUpload(true)

    const promises: any[] = []

    files.forEach((file, index) => {
      const storageRef = ref(
        storage,
        `${filePath}/${setNameToFile(
          file,
          fileName ? `${fileName}_${index + 1}` : undefined,
        )}`,
      )
      const uploadTask = uploadBytesResumable(storageRef, file, {
        contentType: file.type,
        ...metadata,
      }).then(async resp => await getDownloadURL(resp.ref).then(url => url))
      promises.push(uploadTask)
    })

    await Promise.all(promises)
      .then(urls => {
        if (onSuccess) onSuccess(urls)
        setSuccessUpload(true)
      })
      .catch(() => {
        if (onError) onError()
      })
      .finally(() => {
        setLoadingUpload(false)
      })
  }

  return {
    loadingUpload,
    successUpload,
    startUpload,
  }
}
