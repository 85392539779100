import React, { useEffect, useRef, useState } from 'react'
import { TbKey, TbUser } from 'react-icons/tb'


import { BoxShadow, Breadcrumb, PhotoUpload, Status } from '@/components'
import { useGlobalState } from '@/context'
import { useStorage } from '@/hooks/useStorage'
import { RoutesNames } from '@/router/routes'
import { ApiService } from '@/services'
import { theme } from '@/styles/mui-style'
import { setNameToFile } from '@/utils/file'
import { getPathFileUserProfile } from '@/utils/storage'
import { Box, Grid, Stack } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'

import { Password } from './password'
import { Profile as ProfileData } from './profile'

const PHOTO_NAME = 'user_profile'

export const Profile = () => {
  const [{ user }, dispatch] = useGlobalState()
  const { startUpload } = useStorage()
  const [currentTab, setCurrentTab] = useState<number>(0)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
  const breadcrumb: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Profile',
      title: 'Profile',
      isCurrentPage: true,
    },
  ]
  const [breadcrumbList, setBreadcrumbList] = useState<Breadcrumb[]>()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleDeleteImage = async () => {
    setLoadingUpdate(true)

    await ApiService.File.deleteFile({
      pathFile: `${getPathFileUserProfile(user?.uid || '')}/${user?.userConfig?.photoName}`
    }).then(async () => {

      const userConfig = {
        ...user?.userConfig,
        photoURL: '',
        photoName: '',
      } as UserConfig
      const data = { ...user!, userConfig }

      await ApiService.Auth.updateUserProfile({
        photoURL: JSON.stringify(userConfig)
      }).then(() => {
        dispatch({ type: 'SET_USER', data })
        dispatch({ type: 'SET_SNACKBAR', data: { message: 'Image deleted' } })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error deleting image', alertSeverity: 'error' }
        })
      }).finally(() => {
        setLoadingUpdate(false)
      })
    }).catch(() => {
      dispatch({
        type: 'SET_SNACKBAR',
        data: { message: 'Error deleting image', alertSeverity: 'error' }
      })
    })
      .finally(() => {
        setLoadingUpdate(false)
      })
  }

  useEffect(() => {
    if (!fileToUpload) return
    setLoadingUpdate(true)

    startUpload({
      file: fileToUpload,
      filePath: getPathFileUserProfile(user?.uid || ''),
      fileName: PHOTO_NAME,
      async onSuccess(photoURL) {
        const userConfig = {
          ...user?.userConfig,
          photoURL,
          photoName: setNameToFile(fileToUpload, PHOTO_NAME)
        } as UserConfig
        const data = { ...user!, userConfig }

        await ApiService.Auth.updateUserProfile({
          photoURL: JSON.stringify(userConfig)
        }).then(() => {
          dispatch({ type: 'SET_USER', data })
          dispatch({ type: 'SET_SNACKBAR', data: { message: 'Image saved' } })
        }).catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error saving image', alertSeverity: 'error' }
          })
        }).finally(() => {
          setLoadingUpdate(false)
          setFileToUpload(undefined)
        })
      },
      onError() {
        setLoadingUpdate(false)
        setFileToUpload(undefined)
      }
    })
  }, [fileToUpload])

  useEffect(() => {
    setBreadcrumbList([
      ...breadcrumb,
      {
        id: 'userLogged',
        title: user?.displayName || '',
        isCurrentPage: true,
      },
    ])
  }, [user])

  return (
    <Box>
      <Breadcrumb
        pageTitle='User info'
        menu={breadcrumbList}
      />
      <Stack component={BoxShadow} sx={{ width: '100%' }}>
        <CardMedia
          sx={{
            height: 200,
            position: 'relative',
            backgroundColor: theme.palette.secondary.main
          }}
        // image={Perfilcapa}
        >
          <Stack
            sx={{
              display: 'flex',
              position: 'absolute',
              bottom: {
                xs: 26,
                sm: -24,
              },
              left: 24,
              flexDirection: 'row'
            }}
          >
            <PhotoUpload
              inputFileRef={inputFileRef}
              fileToUpload={fileToUpload}
              fileUrl={user?.userConfig?.photoURL}
              setFileToUpload={(files) => {
                if (!files) return
                setFileToUpload(files[0])
              }}
              loading={loadingUpdate}
              handleDeletedImage={() => {
                setFileToUpload(undefined)
                handleDeleteImage()
              }}
            />

            <Box mr={3} />
            <Stack>
              <Box mt={2} />
              <Typography color='white'
                sx={{
                  typography: {
                    xs: "subtitle2",
                    sm: "h6",
                  },
                }}
              >
                {((user?.displayName || user?.email) || '').split(user?.displayName ? '###' : '@')[0]}
              </Typography>
              <Box mb={1} />
              <Stack direction='row'>
                <Status type='INFO' title={(user?.userConfig?.roles || []).join(', ')} />
              </Stack>
            </Stack>
          </Stack>
        </CardMedia>
        <CardContent
          sx={{
            height: 48,
            padding: 0,
            paddingRight: 2,
          }}
        >
          <Stack
            sx={{
              alignItems: {
                xs: "center",
                md: "flex-end",
              },
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleChange}
              selectionFollowsFocus
              style={{ maxHeight: 48 }}
              variant='scrollable'
            >
              <Tab
                icon={<TbUser size={24} />}
                iconPosition='start'
                label={<Typography variant='body2'>Profile</Typography>}
                sx={{ minHeight: 48, textTransform: 'capitalize' }}
              />
              <Tab
                icon={<TbKey size={24} />}
                iconPosition='start'
                label={<Typography variant='body2'>Change password</Typography>}
                sx={{ minHeight: 48, textTransform: 'capitalize' }}
              />
            </Tabs>
          </Stack>
        </CardContent>
      </Stack>
      <Box mb={3} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          {currentTab === 0 && <ProfileData />}
          {currentTab === 1 && <Password />}
        </Grid>
      </Grid>
    </Box>
  )
}
