const BASE_URL_DEV = 'http://127.0.0.1:3333'
const BASE_URL_TEST = ''
const BASE_URL_PROD = ''

const ENDPOINTS = {
  HOME: '/home',
  ME: '/me',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  ENABLE_ACCOUNT: '/enable-account',
}

export { ENDPOINTS, BASE_URL_DEV, BASE_URL_TEST, BASE_URL_PROD }
