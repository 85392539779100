import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TbPercentage } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom'

import { Form, FormikProvider, useFormik } from 'formik'

import { BoxShadow, Breadcrumb, FormikTextField, FormikSwitch, DialogConfirm, PhotoUpload, FormikTextNumericFormat } from '@/components'
import { useGlobalState } from '@/context';
import { useStorage } from '@/hooks/useStorage';
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { setNameToFile } from '@/utils/file';
import { getPathFileCompanyLogomarca } from '@/utils/storage';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/material'


import { validationSchema } from './validationSchema';

const BOX_HEIGHT = 472
const PHOTO_NAME = 'logo'

const INITIAL_VALUES: Omit<Company, 'id' | 'createdAt' | 'dateCreatedAt' | 'updatedAt'> = {
  name: '',
  address: '',
  phoneNumber: '',
  email: '',
  isActive: true,
  photoURL: '',
  photoName: '',
  websiteUrl: '',
  workerHourPrice: 0,
  lastSaleNumber: null,
  lastInvoiceNumber: null,
  bankName: null,
  bankSortCode: null,
  bankAccount: null,
  taxPercent: 0
}

export const CompanyCrud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ company }, dispatch] = useGlobalState()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)
  const locationStateCompanySelected = location.state?.companySelected ? JSON.parse(location.state?.companySelected) : undefined
  const [companySelected, setCompanySelected] = useState<Company | undefined>(locationStateCompanySelected)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const { startUpload } = useStorage()

  const [removePhotoStorage, setRemovePhotoStorage] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [breadcrumbList, setBreadcrumbList] = useState<Breadcrumb[]>([])

  const resetForm = () => {
    formik.resetForm()
    setFileToUpload(undefined)
    setCompanySelected(undefined)
  }

  const isConfigRouter = useMemo(() => {
    const { pathname } = location
    let isConfig = false

    if (pathname === RoutesNames.CONFIG) {
      isConfig = true
      setBreadcrumbList([
        {
          id: 'Dashboard',
          title: 'Dashboard',
          href: RoutesNames.HOME,
        },
        {
          id: 'Config_Company',
          title: 'Config company',
          href: RoutesNames.CONFIG,
        },
      ])
    }

    else {
      setBreadcrumbList([
        {
          id: 'Dashboard',
          title: 'Dashboard',
          href: RoutesNames.HOME,
        },
        {
          id: 'Company',
          title: 'Company',
          href: RoutesNames.COMPANY,
        },
        {
          id: 'company-crud',
          title: companySelected ? 'Updating' : 'New',
          isCurrentPage: true,
        },
      ])
    }

    return isConfig
  }, [location.pathname])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoadingSave(true)

      if (!companySelected) {
        const companyId = await ApiService.Company.save(values)

        if (fileToUpload) {
          startUpload({
            file: fileToUpload,
            filePath: getPathFileCompanyLogomarca(companyId),
            fileName: PHOTO_NAME,
            onSuccess(photoURL) {
              ApiService.Company.update({
                companyId,
                values: { photoURL, photoName: setNameToFile(fileToUpload, PHOTO_NAME) }
              })
              dispatch({
                type: 'SET_SNACKBAR',
                data: { message: 'Save successfull' }
              })
              resetForm()
              setLoadingSave(false)
            },
            onError() {
              dispatch({
                type: 'SET_SNACKBAR',
                data: { message: 'Error saving image', alertSeverity: 'error' }
              })
              setLoadingSave(false)
            }
          })
        } else {
          resetForm()
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Save successfull' }
          })
          setLoadingSave(false)
        }
        return
      }

      if (removePhotoStorage && !fileToUpload) {
        await ApiService.File.deleteFile({
          pathFile: `${getPathFileCompanyLogomarca(companySelected.id)}/${companySelected.photoName}`,
        })
      }

      const updateCompany = async (photoURL: string) => {
        await ApiService.Company.update({
          companyId: companySelected.id,
          values: {
            ...values,
            lastInvoiceNumber: values.lastInvoiceNumber || null,
            lastSaleNumber: values.lastSaleNumber || null,
            photoURL: photoURL || values.photoURL,
            photoName: fileToUpload ? setNameToFile(fileToUpload, PHOTO_NAME) : values.photoName
          }
        }).then(() => {
          resetForm()
          if (!isConfigRouter) {
            navigate(RoutesNames.COMPANY)
          } else {
            formik.setFieldValue('photoURL', photoURL || values.photoURL)
            const data = { ...company, ...values, photoURL: photoURL || values.photoURL } as unknown as Company
            dispatch({ type: 'SET_COMPANY', data })
            setInitialValues(data)
            setCompanySelected(data)
          }

          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Save successfull' }
          })
        }).catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error on update', alertSeverity: 'error' }
          })
        }).finally(() => {
          setLoadingSave(false)
        })
      }

      if (fileToUpload) {
        startUpload({
          file: fileToUpload,
          filePath: getPathFileCompanyLogomarca(companySelected.id),
          fileName: PHOTO_NAME,
          async onSuccess(photoURL) {
            await updateCompany(photoURL)
          },
          onError() {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Error saving image', alertSeverity: 'error' }
            })
          }
        })
      } else {
        await updateCompany('')
      }
    },
  })

  const handleDeleteCompany = async () => {
    if (!companySelected) return
    setLoadingDelete(true)
    await ApiService.Company.remove({ companyId: companySelected.id })
      .then(async () => {
        if (companySelected.photoURL && companySelected.photoName) {
          await ApiService.Company.removePhoto({
            companyId: companySelected.id,
            photoName: companySelected.photoName
          })
        }
        resetForm()
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Company deleted' }
        })
        navigate(RoutesNames.COMPANY)
      }).catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Company was not deleted' }
        })
      }).finally(() => {
        setLoadingDelete(false)
      })
  }

  useEffect(() => {
    if (!companySelected) return
    setInitialValues(companySelected)
  }, [companySelected])

  useEffect(() => {
    if (!isConfigRouter || !company) return
    setInitialValues(company)
    setCompanySelected(company)
  }, [isConfigRouter])

  console.log('formik: ', formik);


  return (
    <Box>
      <Breadcrumb
        pageTitle={
          companySelected ? 'Update of data' : 'Registration'
        }
        menu={breadcrumbList}
      />

      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>

                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  alignItems='center'
                  justifyContent='center'
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    alignItems='center'
                    justifyContent='center'
                    paddingTop='20px'
                  >
                    <PhotoUpload
                      inputFileRef={inputFileRef}
                      fileToUpload={fileToUpload}
                      fileUrl={formik.values.photoURL}
                      setFileToUpload={(files) => {
                        if (!files) return
                        setFileToUpload(files[0])
                      }}
                      showTextAllowFile
                      handleDeletedImage={() => {
                        setFileToUpload(undefined)

                        if (companySelected?.photoURL) {
                          setRemovePhotoStorage(true)
                          formik.setFieldValue('photoURL', '')
                          formik.setFieldValue('photoName', '')
                        }
                      }}
                    />
                  </Stack>

                  {!isConfigRouter && <FormikSwitch size='small' name='isActive' label='Enable company' />}

                  {companySelected && !isConfigRouter && (
                    <>
                      <Box mb={2} />
                      <LoadingButton
                        disabled
                        color='btnError'
                        variant='contained'
                        disableElevation
                        onClick={() => { setIsOpenConfirmDelete(true) }}
                      >
                        Delete
                      </LoadingButton>
                    </>
                  )}
                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack minHeight={BOX_HEIGHT} padding={3}>
                  <Stack display='flex' flex={1}>
                    <Box>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={12}>
                          <FormikTextField name='name' fullWidth label='Name' />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikTextField name='email' fullWidth label='E-mail' />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikTextField name='phoneNumber' fullWidth label='Cell phone number' />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextField name='address' fullWidth label='Address' />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextField name='websiteUrl' fullWidth label='Website URL' />
                        </Grid>
                      </Grid>

                      <Typography variant='subtitle2' mt={3} mb={2} ml={1}>
                        Bank information
                      </Typography>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={6}>
                          <FormikTextField name='bankName' label='Name' />
                        </Grid>
                        <Grid item xs={3}>
                          <FormikTextField name='bankSortCode' label='Sort code' />
                        </Grid>
                        <Grid item xs={3}>
                          <FormikTextField name='bankAccount' label='Account' />
                        </Grid>
                      </Grid>

                      <Typography variant='subtitle2' mt={3} mb={2} ml={1}>
                        Settings
                      </Typography>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={6}>
                          <FormikTextField name='lastSaleNumber' label='Last sale number' type='number' />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField name='lastInvoiceNumber' label='Last invoice number' type='number' />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextNumericFormat name='workerHourPrice' label='Worker hour price' />
                        </Grid>
                        <Grid item xs={6} />
                      </Grid>

                      <Typography variant='subtitle2' mt={3} mb={2} ml={1}>
                        Duty
                      </Typography>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={6}>
                          <FormikTextField name='taxPercent' fullWidth label='Tax percent'
                            type='number'
                            InputProps={{ startAdornment: <TbPercentage size={24} /> }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>

                  <Stack flexDirection='row' justifyContent='flex-end' mt={3} >
                    {!isConfigRouter && (
                      <>
                        <Button
                          color='btnSecondary'
                          variant='contained'
                          disableElevation
                          onClick={() => {
                            navigate(RoutesNames.COMPANY)
                          }}
                        >
                          Back
                        </Button>
                        <Box mr={2} />
                      </>
                    )}
                    <LoadingButton
                      variant='contained'
                      color='btnPrimaryFill'
                      disableElevation
                      type='submit'
                      loading={loadingSave}
                    >
                      {companySelected ? 'Save update' : 'Save company'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${companySelected?.name}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => { handleDeleteCompany() },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => { setIsOpenConfirmDelete(false) },
        }}
      />
    </Box>
  )
}
