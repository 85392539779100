import * as yup from 'yup'

export const validationSchema = yup.object({
  name: yup.string().required('Field is required'),

  email: yup.string().email().required('Field is required'),

  phoneNumber: yup.string().required('Field is required'),

  address: yup.string().required('Field is required'),
})
