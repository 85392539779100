import * as React from 'react'
import { TbPointFilled } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { Box, Stack } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault()
}

interface BreadcrumbProps extends React.PropsWithChildren {
  pageTitle: string
  menu?: Breadcrumb[]
  isCollapsed?: boolean
}

export const Breadcrumb = ({
  pageTitle,
  menu,
  isCollapsed,
  children,
}: BreadcrumbProps) => {
  const navigate = useNavigate()

  return (
    <Stack
      role='presentation'
      onClick={handleClick}
      marginBottom={5}
      flexDirection='row'
      alignItems='center'
    >
      <Stack display='flex' flex={1}>
        <Typography color='text.primary' variant='h5' sx={{ fontWeight: '500' }}>
          {pageTitle}
        </Typography>

        {menu && menu.length > 0 && (
          <>

            <Box mb={1} />

            <Breadcrumbs
              maxItems={isCollapsed ? 2 : 10}
              separator={
                <Box pl={0.6} pr={0.6}>
                  <TbPointFilled size={8} color='#000000061' />
                </Box>
              }
            >
              {menu.map(item => (
                <Link
                  key={item.id}
                  underline={item.isCurrentPage ? 'none' : 'hover'}
                  color={item.isCurrentPage ? 'text.disabled' : 'text.primary'}
                  href={item.isCurrentPage ? undefined : item.href}
                  variant='caption'
                  sx={{ fontWeight: 400 }}
                  onClick={!item.href ? () => null : () => { navigate(item.href || '/') }}
                >
                  {item.title}
                </Link>
              ))}
            </Breadcrumbs>
          </>
        )}

      </Stack>

      {children && children}
    </Stack>
  )
}
