import { TbUserCircle } from 'react-icons/tb'

import { RoutesNames } from '@/router/routes'

export const COMMOM_MENU: HeaderMenu[] = [
  {
    id: 'PROFILE',
    title: 'Perfil',
    redirectToRouteName: RoutesNames.PROFILE,
    icon: TbUserCircle,
  },
]

// export const ADMIN_MENU: HeaderMenu[] = [
//   {
//     id: 'PROFILE',
//     title: 'Perfil',
//     redirectToRouteName: RoutesNames.PROFILE,
//   },
// ]

// export const ADMIN_USER = [
//   {
//     id: 'PROFILE',
//     title: 'Perfil',
//   },
// ]
