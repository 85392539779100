import React, { useMemo } from 'react'
import { TbPointFilled } from 'react-icons/tb';

import { BoxShadow } from '@/components';
import { StatusServiceFlowColors } from '@/components/status-service-flow';
import { EnumServiceStatus } from '@/constants/service';
import { Box, Grid, LinearProgress, linearProgressClasses, Stack, Typography } from '@mui/material'
import { grey, orange, teal } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';

interface PieProps {
  countHidraulic: number,
  countBucket: number,
}
export const Pie = ({ countHidraulic, countBucket }: PieProps) => {
  const data = [
    { label: 'Hydraulic', value: countHidraulic },
    { label: 'Bucket', value: countBucket },
  ];

  return (
    <PieChart
      series={[
        {
          paddingAngle: 1,
          innerRadius: 64,
          outerRadius: 80,
          cornerRadius: 6,
          data,
        },
      ]}
      colors={[orange[500], teal[500]]}
      margin={{ right: 0 }}
      height={250}
      legend={{ hidden: true }}
    >
    </PieChart>
  )
}

interface LinearProgressProps {
  backgroundColor: string
  backgroundColorLight: string
}
const BorderLinearProgress = styled(LinearProgress)(({ backgroundColor, backgroundColorLight }: LinearProgressProps) => ({
  height: 4,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: backgroundColorLight,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor,
  },
}));

interface MonetaryProps {
  countToDo: number,
  countInProgress: number,
  countAwaitingApproval: number,
  countApproved: number,
  countStartWork: number,
  countWorkInProgress: number,
}
export const ServiceHydraulic = ({ countToDo, countInProgress, countAwaitingApproval, countApproved, countStartWork, countWorkInProgress }: MonetaryProps) => {
  const percentToDo = !countToDo ? 100 : countToDo === 1 ? 75 : 100 / countToDo
  const percentInProgress = !countInProgress ? 100 : countInProgress === 1 ? 75 : 100 / countInProgress
  const percentAwaitingApproval = !countAwaitingApproval ? 100 : countAwaitingApproval === 1 ? 75 : 100 / countAwaitingApproval
  const percentApproved = !countApproved ? 100 : countApproved === 1 ? 75 : 100 / countApproved
  const percentAStartWork = !countStartWork ? 100 : countStartWork === 1 ? 75 : 100 / countStartWork
  const percentAWorkInProgress = !countWorkInProgress ? 100 : countWorkInProgress === 1 ? 75 : 100 / countWorkInProgress

  return (
    <Stack>
      <Stack display='flex' flex={1}>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
          <Typography variant='caption'>{EnumServiceStatus.TO_DO}</Typography>
          <Typography variant='caption' fontWeight='500'>Count: {countToDo}</Typography>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={percentToDo}
          backgroundColor={StatusServiceFlowColors.TO_DO.color[500]}
          backgroundColorLight={StatusServiceFlowColors.TO_DO.color[50]}
        />
      </Stack>
      <Box mb={5} />

      <Stack display='flex' flex={1}>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
          <Typography variant='caption'>{EnumServiceStatus.IN_PROGRESS}</Typography>
          <Typography variant='caption' fontWeight='500'>Count: {countInProgress}</Typography>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={percentInProgress}
          backgroundColor={StatusServiceFlowColors.IN_PROGRESS.color[500]}
          backgroundColorLight={StatusServiceFlowColors.IN_PROGRESS.color[50]}
        />
      </Stack>
      <Box mb={5} />

      <Stack display='flex' flex={1}>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
          <Typography variant='caption'>{EnumServiceStatus.AWAITING_APPROVAL}</Typography>
          <Typography variant='caption' fontWeight='500'>Count: {countAwaitingApproval}</Typography>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={percentAwaitingApproval}
          backgroundColor={StatusServiceFlowColors.AWAITING_APPROVAL.color[500]}
          backgroundColorLight={StatusServiceFlowColors.AWAITING_APPROVAL.color[50]}
        />
      </Stack>
      <Box mb={5} />

      <Stack display='flex' flex={1}>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
          <Typography variant='caption'>{EnumServiceStatus.APPROVED}</Typography>
          <Typography variant='caption' fontWeight='500'>Count: {countApproved}</Typography>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={percentApproved}
          backgroundColor={StatusServiceFlowColors.APPROVED.color[500]}
          backgroundColorLight={StatusServiceFlowColors.APPROVED.color[50]}
        />
      </Stack>
      <Box mb={5} />

      <Stack display='flex' flex={1}>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
          <Typography variant='caption'>{EnumServiceStatus.START_WORK}</Typography>
          <Typography variant='caption' fontWeight='500'>Count: {countStartWork}</Typography>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={percentAStartWork}
          backgroundColor={StatusServiceFlowColors.START_WORK.color[500]}
          backgroundColorLight={StatusServiceFlowColors.START_WORK.color[50]}
        />
      </Stack>
      <Box mb={5} />

      <Stack display='flex' flex={1}>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
          <Typography variant='caption'>{EnumServiceStatus.WORK_FINISH}</Typography>
          <Typography variant='caption' fontWeight='500'>Count: {countWorkInProgress}</Typography>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={percentAWorkInProgress}
          backgroundColor={StatusServiceFlowColors.WORK_FINISH.color[500]}
          backgroundColorLight={StatusServiceFlowColors.WORK_FINISH.color[50]}
        />
      </Stack>
    </Stack>
  )
}

interface ServiceBucketProps {
  countApproved: number,
  countStartWork: number,
  countWorkInProgress: number
}
export const ServiceBucket = ({ countApproved, countStartWork, countWorkInProgress }: ServiceBucketProps) => {
  const percentApproved = !countApproved ? 100 : countApproved === 1 ? 75 : 100 / countApproved
  const percentStartWork = !countStartWork ? 100 : countStartWork === 1 ? 75 : 100 / countStartWork
  const percentWorkInProgress = !countWorkInProgress ? 100 : countWorkInProgress === 1 ? 75 : 100 / countWorkInProgress

  return (
    <Stack>
      <Stack display='flex' flex={1}>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
          <Typography variant='caption'>{EnumServiceStatus.APPROVED}</Typography>
          <Typography variant='caption' fontWeight='500'>Count: {countApproved}</Typography>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={percentApproved}
          backgroundColor={StatusServiceFlowColors.APPROVED.color[500]}
          backgroundColorLight={StatusServiceFlowColors.APPROVED.color[50]}
        />
      </Stack>
      <Box mb={5} />

      <Stack display='flex' flex={1}>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
          <Typography variant='caption'>{EnumServiceStatus.START_WORK}</Typography>
          <Typography variant='caption' fontWeight='500'>Count: {countStartWork}</Typography>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={percentStartWork}
          backgroundColor={StatusServiceFlowColors.START_WORK.color[500]}
          backgroundColorLight={StatusServiceFlowColors.START_WORK.color[50]}
        />
      </Stack>
      <Box mb={5} />

      <Stack display='flex' flex={1}>
        <Stack display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
          <Typography variant='caption'>{EnumServiceStatus.WORK_FINISH}</Typography>
          <Typography variant='caption' fontWeight='500'>Count: {countWorkInProgress}</Typography>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={percentWorkInProgress}
          backgroundColor={StatusServiceFlowColors.WORK_FINISH.color[500]}
          backgroundColorLight={StatusServiceFlowColors.WORK_FINISH.color[50]}
        />
      </Stack>

    </Stack>
  )
}

interface PiesProps {
  serviceList: Service[]
}
export const Pies = ({ serviceList }: PiesProps) => {
  const serviceListTypeBucket = useMemo(() => serviceList?.filter((service) => service.typeService === 'BUCKET'), [serviceList])
  const serviceListBucketApproved = useMemo(() => serviceList?.filter((service) => (service.status === 'APPROVED' && service.typeService === 'BUCKET')), [serviceList])
  const serviceListBucketStartWork = useMemo(() => serviceList?.filter((service) => (service.status === 'START_WORK' && service.typeService === 'BUCKET')), [serviceList])
  const serviceListBucketWorkInProgress = useMemo(() => serviceList?.filter((service) => (service.status === 'WORK_FINISH' && service.typeService === 'BUCKET')), [serviceList])

  const ServiceListTypeHidraulic = useMemo(() => serviceList?.filter((service) => service.typeService === 'HYDRAULIC'), [serviceList])
  const ServiceListToDo = useMemo(() => serviceList?.filter((service) => (service.status === 'TO_DO' && service.typeService !== 'BUCKET')), [serviceList])
  const ServiceListInProgress = useMemo(() => serviceList?.filter((service) => (service.status === 'IN_PROGRESS' && service.typeService !== 'BUCKET')), [serviceList])
  const ServiceListAwaitingApproval = useMemo(() => serviceList?.filter((service) => (service.status === 'AWAITING_APPROVAL' && service.typeService !== 'BUCKET')), [serviceList])
  const ServiceListApproved = useMemo(() => serviceList?.filter((service) => (service.status === 'APPROVED' && service.typeService !== 'BUCKET')), [serviceList])
  const ServiceListStartWork = useMemo(() => serviceList?.filter((service) => (service.status === 'START_WORK' && service.typeService !== 'BUCKET')), [serviceList])
  const ServiceListWorkInProgress = useMemo(() => serviceList?.filter((service) => (service.status === 'WORK_FINISH' && service.typeService !== 'BUCKET')), [serviceList])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <BoxShadow>
          <Stack
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignSelf='center'
            flex={1}
          >
            <Stack padding={2}>
              <Typography variant='subtitle2'>
                Type of services
              </Typography>
              <Pie countHidraulic={ServiceListTypeHidraulic.length} countBucket={serviceListTypeBucket.length} />
            </Stack>

            <Stack padding={2} sx={{ borderTop: `1px dashed ${grey[200]}` }} flexDirection='row' justifyContent='center'>
              <Stack flexDirection='row' alignItems='center' mr={2}>
                <TbPointFilled size={24} color={orange[500]} />
                <Typography variant='caption'>Hydraulic</Typography>
              </Stack>
              <Stack flexDirection='row' alignItems='center'>
                <TbPointFilled size={24} color={teal[500]} />
                <Typography variant='caption'>Bucket</Typography>
              </Stack>
            </Stack>
          </Stack>
        </BoxShadow>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <BoxShadow>
          <Stack
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignSelf='center'
            flex={1}
            padding={2}
          >
            <Typography variant='subtitle2' mb={4}>
              Status flow the services hydraulic
            </Typography>
            <ServiceHydraulic
              countToDo={ServiceListToDo.length}
              countInProgress={ServiceListInProgress.length}
              countAwaitingApproval={ServiceListAwaitingApproval.length}
              countApproved={ServiceListApproved.length}
              countStartWork={ServiceListStartWork.length}
              countWorkInProgress={ServiceListWorkInProgress.length}
            />
          </Stack>
        </BoxShadow>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <BoxShadow>
          <Stack
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignSelf='center'
            flex={1}
            padding={2}
          >
            <Typography variant='subtitle2' mb={4}>
              Status flow the services bucket
            </Typography>
            <ServiceBucket
              countApproved={serviceListBucketApproved.length}
              countStartWork={serviceListBucketStartWork.length}
              countWorkInProgress={serviceListBucketWorkInProgress.length}
            />
          </Stack>
        </BoxShadow>
      </Grid>
    </Grid>
  )
}
