import * as yup from 'yup'

export const validationSchema = yup.object({
  displayName: yup.string().required('Field is required'),
  companyId: yup.string().required('Field is required'),
  roles: yup.string().required('Field is required'),
  email: yup.string().email().required('Field is required'),
  password: yup.string().required('Field is required'),
  passwordConfirm: yup
    .string()
    .required('Field is required')
    .oneOf([yup.ref('password')], 'Passwords are not the same'),
})
